import { Injectable, inject } from '@angular/core';
import {filter, switchMap} from 'rxjs/operators';
import {StaticContentService} from './static-content.service';
import { CookieService } from 'ngx-unificator/services';
import {Observable, of} from 'rxjs';
import { isMobile } from '../helpers/device';

@Injectable({
  providedIn: 'root'
})
export class AuPaymentRestrictService {
  private _static = inject(StaticContentService);
  private _cookies = inject(CookieService);


  public text: any = null;
  public text$: Observable<any> = null;

  constructor() {
    this.text$ = this.handleAuPaymentText();
  }

  /**
   * Check and handle emp payment popup
   * @private
   */
  public handleAuPaymentText(): Observable<string> {
    return this._static.item({slug: 'au-payment'}).pipe(
      filter(res => !!res),
      switchMap((res) => {
        if (res.length && res[0].Content) {
          if (this._checkStagForAuPaymentText(res[0].Stags.stags) && isMobile()) {
            this.text = null;
          } else {
            this.text = res[0].Content;
          }
        } else {
          this.text = null;
        }
        return of(this.text);
      }),
    );
  }

  private _checkStagForAuPaymentText(data: any) {
    if (data) {
      return Object.values(data).some((item: string) => {
        return this._cookies.get('ptag').startsWith(item);
      });
    }

  }
}
