import { ApplicationRef, ComponentFactory, ComponentFactoryResolver, inject, Injectable } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { ModalComponent } from './modal.component';
import { ModalOptions } from './modal';
import { ModalRef } from './modal-ref';
import { filter, first, tap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { ModalTemplate } from './templates';
import { ModalRegular, ModalRegularType } from './components/regular';
import { ScrollService } from '../services/scroll.service';
import { GlobalEventsService } from '../services/global-events.service';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private _cfr = inject(ComponentFactoryResolver);
  private _appRef = inject(ApplicationRef);
  private _scroll = inject(ScrollService);
  private _events = inject(GlobalEventsService);
  private _document = inject<Document>(DOCUMENT);


  private _modalContainerFactoryReady: boolean;
  private _modalContainer: HTMLElement;
  private _modalContainerFactory: ComponentFactory<ModalComponent>;
  private _currentModal: ModalRef;

  /**
   * Open modal
   * @param component
   * @param options
   * @returns
   */
  public open(component: ModalRegularType, options: ModalOptions = null) {
    if (!this._modalContainerFactoryReady) {
      this._setupModalContainerFactory();
    }
    this._setupModalContainer();
    const modalRootRef = this._appRef.bootstrap(this._modalContainerFactory, this._modalContainer);
    const modalTemplateRef = modalRootRef.instance.createModal(ModalRegular[component], ModalTemplate[options?.template] || ModalTemplate?.DEFAULT);
    return this._resolveModalRef(options, modalRootRef, modalTemplateRef);
  }

  /**
   * Open modal async
   * @param component - the path must be realerive from place where you call method 'openLazy'
   * @param options
   * @returns
   */
  public async openLazy(component: any, options: ModalOptions = {}): Promise<ModalRef> {
    if (!this._modalContainerFactoryReady) {
      this._setupModalContainerFactory();
    }
    this._setupModalContainer();
    const modalRootRef = this._appRef.bootstrap(this._modalContainerFactory, this._modalContainer);
    const modalTemplateRef = modalRootRef.instance.createModal(component, ModalTemplate[options?.template] || ModalTemplate?.DEFAULT);
    return this._resolveModalRef(options, modalRootRef, modalTemplateRef);
  }

  /**
   * If the user clicks on the modal overlay, close the modal.
   * @param options - ModalOptions
   * @param modalRootRef - ViewContainerRef
   * @param modalTemplateRef - TemplateRef&lt;any&gt;
   * @returns The modalRef is being returned.
   */
  private _resolveModalRef(options, modalRootRef, modalTemplateRef) {
    modalTemplateRef.instance.options = options || {};
    if (options && !options.disableOverlayClosing) {
      fromEvent(modalRootRef?.instance?.modalOverlay()?.element?.nativeElement, 'click').pipe(
        first(),
        tap(() => modalTemplateRef.instance.instance.close(null))
      ).subscribe();
    }
    if (options?.closeAfterRouteChange) {
      const subscription: Subscription = this._events.routerNavigationEnd$.pipe(
        filter(() => modalTemplateRef.instance.instance),
        tap(() => {
          modalTemplateRef.instance.instance.close(null);
          subscription.unsubscribe();
        })
      ).subscribe();
    }
    this._currentModal = new ModalRef(modalRootRef, modalTemplateRef, this._scroll, this._document);
    return this._currentModal;
  }

  /**
   * This function creates a div element and appends it to the body of the document.
   */
  private _setupModalContainer(): void {
    this._scroll.blockScroll();
    this._modalContainer = this._document.createElement('div');
    this._document.getElementsByTagName('body')[0].appendChild(this._modalContainer);
  }

  /**
   * This function creates a factory for the modal component, and sets a flag to indicate that the
   * factory is ready.
   */
  private _setupModalContainerFactory(): void {
    this._modalContainerFactory = this._cfr.resolveComponentFactory(ModalComponent);
    this._modalContainerFactoryReady = true;
  }

}
