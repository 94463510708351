import { PlatformService } from '../../services/platform.service';
import {
  AfterContentInit,
  Component,
  contentChildren,
  ElementRef,
  inject,
  input,
  OnDestroy,
  output,
  viewChild,
} from '@angular/core';
import { TabItemComponent } from './tab-item.component';
import { GlobalEventsService } from '../../services/global-events.service';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import { NgClass, NgTemplateOutlet } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'app-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss'],
    imports: [
        NgClass,
        NgTemplateOutlet
    ]
})
export class TabsComponent implements AfterContentInit, OnDestroy {
  private _platform = inject(PlatformService);
  private _el = inject<ElementRef<HTMLElement>>(ElementRef);
  private _globalEvent = inject(GlobalEventsService);


  /**
   * Default active first tab
   */
  readonly defaultActive = input<boolean>(true);

  readonly selector = viewChild<ElementRef<HTMLElement>>('selector');

  readonly tabs = contentChildren(TabItemComponent);

  readonly select = output<TabItemComponent>();

  ngAfterContentInit() {
    // get all active tabs
    const activeTabs = this.tabs().filter((tab) => tab.active());
    // if there is no active tab set, activate the first
    if (activeTabs.length === 0) {
      if (this.defaultActive()) {
        this.selectTab(this.tabs().at(0)!);
      }
      this._globalEvent.resize$.pipe(
        untilDestroyed(this)
      ).subscribe(() => {
        this._resolveSelector();
      });
    }
  }

  ngOnDestroy() {
  }

  selectTab(currentTab: any) {
    // deactivate all tabs
    this.tabs().forEach((tab: TabItemComponent) => {
      tab.active.set(false);
    });
    // activate the tab the user has clicked on.
    currentTab.active.set(true);
    this.select.emit(currentTab);
    this._resolveSelector();
  }

  /**
   * Resolve selector position
   */
  private _resolveSelector() {
    if (this._platform.isBrowser) {
      requestAnimationFrame(() => {
        const selector: HTMLElement = this._el.nativeElement.querySelector('.tabs__header .selector');
        const active: HTMLElement = this._el.nativeElement.querySelector('.tabs__header .active');
        if (active) {
          const activeWidth = active?.offsetWidth;
          selector.style.left = active?.offsetLeft + 'px';
          selector.style.width = activeWidth + 'px';
        }
      });
    }
  }

}
