<div class="tabs">
  <div class="tabs__header">
    <div class="selector" #selector></div>
    @for (tab of tabs(); track tab) {
      <div class="tabs__header--title" [ngClass]="{'active': tab?.active(), 'disabled': tab?.disabled()}" [title]="tab?.alt()" (click)="!tab?.disabled() ? selectTab(tab) : null">
        @if (tab?.title) {
          {{ tab?.title }}
          @if (tab?.badge()) {
            <div class="tabs__badge">{{tab?.badge()}}</div>
          }
        } @else {
          <ng-template [ngTemplateOutlet]="tab?.titleTpl"></ng-template>
        }
      </div>
    }
  </div>
</div>
<ng-content></ng-content>
