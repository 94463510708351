import { Injectable, inject } from '@angular/core';
import {of, ReplaySubject} from 'rxjs';
import {catchError, map, shareReplay, tap} from 'rxjs/operators';
import {CmsApiService} from './api/cms-api.service';
import {CRYPTO_ACCOUNTS} from "./common-data.service";

export const NOT_AVAILABLE = 't.not-available-exchange';

@Injectable({
  providedIn: 'root'
})

export class CryptoCurrenciesConverterService {
  private _api = inject(CmsApiService);


  /**
   * Current rate RS
   * @private
   */
  private _rate$: ReplaySubject<any>;

  /**
   * Current rate value
   * @private
   */
  private _rate: number | string;


  /**
   * True if currencies FROM is crypto
   * @private
   */
  private _isCryptoFrom: boolean;

  /**
   * True if currencies TO is crypto
   * @private
   */
  private _isCryptoTo: boolean;

  /**
   * Access to rate RS from outside
   */
  get rate$(): ReplaySubject<any> {
    return this._rate$;
  }

  /**
   * Access to rate value from outside
   */
  get rate(): number | string {
    return this._rate;
  }

  /**
   * get NOT_AVAILABLE string (uses for check available exchange and translate key)
   */
  get notAvailable() {
    return NOT_AVAILABLE;
  }

  get enabledExchange() {
    return this._rate && this._rate !== NOT_AVAILABLE;
  }

  /**
   * Refresh rates currencies
   * @param params
   */
  public refreshRate(params?: any) {
    params.from = this._resolveCurrencyBeforeRequest(params.from);
    params.to = this._resolveCurrencyBeforeRequest(params.to);
    this._isCryptoFrom = CRYPTO_ACCOUNTS.includes(params.from);
    this._isCryptoTo = CRYPTO_ACCOUNTS.includes(params.to);


    this._rate$ = !params ?
      of(NOT_AVAILABLE).pipe(tap(rate => this._rate = rate)) as ReplaySubject<any> :
      params.from === params.to ? of(1).pipe(tap(rate => this._rate = rate)) as ReplaySubject<any> :
        this._api.getExchangeRate(params).pipe(
          map(response => {

            if (response && response.data && response.data.rate) {
              return Number(response.data.rate);
            } else {
              return NOT_AVAILABLE;
            }
          }),
          shareReplay(1),
          catchError(() => of(NOT_AVAILABLE)),
          tap(rate => this._rate = rate)
        ) as ReplaySubject<any>;
  }


  /**
   * Calculate and get CurrencyTo
   * @param value
   */
  public getValueCurrencyTo(value: number): ReplaySubject<number> {
    return this._rate$?.pipe(
      map(rate => value * rate),
    map(rate => this._roundedCalculatedValue(rate, this._isCryptoTo ? 6 : 2))
    ) as ReplaySubject<number>;
  }

  /**
   * Calculate and get CurrencyFrom
   * @param value
   */
  public getValueCurrencyFrom(value: number): ReplaySubject<number> {
    return this._rate$?.pipe(
      map(rate => value / rate),
      map(rate => this._roundedCalculatedValue(rate, this._isCryptoFrom ? 6 : 2))
    ) as ReplaySubject<number>;
  }

  /**
   * Resolve currency before request
   * @param currency
   * @private
   */
  private _resolveCurrencyBeforeRequest(currency: string): string {
    switch (currency) {
      case 'DOG':
        return 'DOGE';
      default:
        return currency;
    }
  }

  /**
   * Rounded calculated value
   * @param value
   * @param rounded
   * @private
   */
  private _roundedCalculatedValue(value: number, rounded: number = 2): number {
    return Number(value.toFixed(rounded));
  }
}
