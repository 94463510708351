import { Component, OnInit, inject } from '@angular/core';
import {of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ToastMessageService} from '../../../core/modules/toast-message/toast-message.service';
import {UserBonusesService} from '../../../core/services/user/user-bonuses.service';
import {FormsErrorHandlerService} from '../../../core/services/forms-error-handler.service';
import {LanguageService} from '../../../core/services/language/language.service';
import {ArabicService} from '../../../core/services/arabic.service';
import { TranslatePipe } from '../../../core/shared/translation/translate.pipe';
import { FormControlsModule } from '../../../core/modules/form-controls/form-controls.module';

import { SetImgAltDirective } from '../../../core/shared/directives/set-img-alt.directive';

@Component({
    selector: 'app-no-deposit-bonus',
    templateUrl: './no-deposit-bonus.component.html',
    styleUrls: ['./no-deposit-bonus.component.scss'],
    imports: [FormsModule, ReactiveFormsModule, SetImgAltDirective, FormControlsModule, TranslatePipe]
})
export class NoDepositBonusComponent implements OnInit {
  private _toastMessage = inject(ToastMessageService);
  private _userBonus = inject(UserBonusesService);
  private _formErrors = inject(FormsErrorHandlerService);
  private _fb = inject(UntypedFormBuilder);
  lang = inject(LanguageService);
  arabic = inject(ArabicService);


  /**
   * Form for send no deposit bonus code
   */
  public bonusCodeForm: UntypedFormGroup = this._fb.group({
    code: ['']
  });

  ngOnInit() {
  }

  /**
   * Send deposit bonus code
   */
  onBonusCodeFormSubmit() {
    this._userBonus.activateCoupon(this.bonusCodeForm.get('code').value).pipe(
      catchError(error => {
        this._toastMessage.error(this._formErrors.ssBackendErrorsToArray(error.error)[0] || 't.went-wrong');
        return of(error);
      }),
      tap(response => {
        this._userBonus.resolveCouponResponseMessage(response);
      }),
    ).subscribe();
  }

}
