import { Injectable, inject } from '@angular/core';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {StaticContentService} from '../../core/services/static-content.service';
import {combineLatest, Observable, ReplaySubject} from 'rxjs';
import {StaticPageService} from '../static-page/static-page.service';
import {CmsApiService} from '../../core/services/api/cms-api.service';
import {ArrayService} from '../../core/services/array.service';
import {GamesService} from '../../core/services/games/games.service';
import {IJackpotCMS, IJackpotSS, IJackpotWinner, JackpotLevels} from './base-jackpot.interface';
import {SsApiService} from '../../core/services/api/ss-api.service';
import {CommonDataService} from '../../core/services/common-data.service';
import {WebSocketService} from '../../core/services/web-socket.service';
import {GameAttribute} from '../../core/services/games/game-attribute';
import {UserService} from '../../core/services/user/user.service';
import {ArabicService} from '../../core/services/arabic.service';
import { DecimalPipe } from '@angular/common';
import { isCryptoAcc } from '../../core/services/common-data.service';
import { EnvironmentService } from 'src/app/core/services/environment.service';

export enum JackpotHeaderPlace {
  JACKPOT = 'jackpot',
  HOME = 'game',
}

export enum JackpotLevelsPlace {
  JACKPOT = 'jackpot',
  GAME = 'game',
  GAME_LIST = 'game-list'
}

@Injectable({
  providedIn: 'root'
})
export class JackpotService {
  private _static = inject(StaticContentService);
  private _pages = inject(StaticPageService);
  private _cmsApi = inject(CmsApiService);
  private _array = inject(ArrayService);
  private _games = inject(GamesService);
  private _ssApi = inject(SsApiService);
  private _common = inject(CommonDataService);
  private _user = inject(UserService);
  private _ws = inject(WebSocketService);
  private _rtl = inject(ArabicService);
  private _number = inject(DecimalPipe);
  private _env = inject(EnvironmentService);


  /**
   * Show/hide sidebar menu link
   */
  public jackpotSlug: string;

  /**
   * Is jackpot enable
   */
  public isJackpotEnable: boolean;

  /**
   * Is total jackpot fetched
   */
  public totalJackpot$ = new ReplaySubject();

  /**
   * Games list by category
   */
  public gameList$: Observable<any> = this.getGameListByCategory();

  /**
   * Jackpot page from CMS
   */
  public cmsJackpot$: ReplaySubject<IJackpotCMS> = new ReplaySubject<IJackpotCMS>(1);

  /**
   * Jackpot from SS
   */
  public ssJackpot$: ReplaySubject<IJackpotSS> = new ReplaySubject<IJackpotSS>(1);

  /**
   * Winner jackpot from ws
   */
  public wsWinnerJackpot$: ReplaySubject<IJackpotSS> = new ReplaySubject<IJackpotSS>(1);

  /**
   * Ws Jackpots
   * @private
   */
  private _wsJackpots$: Observable<IJackpotSS> = this._ws.jackpots$;

  /**
   * Ws bonuses for winner
   * @private
   */
  private _jackpotsWinBonusChange$: Observable<any> = this._ws.bonuses$;

  /**
   * Winner SS list
   */
  public winnersSSList$: Observable<IJackpotWinner[]>;

  /**
   * Jackpot Hunter CMS list
   */
  public jackpotHunterCMSList$: Observable<IJackpotWinner[]>;

  /**
   * Jackpot header place
   */
  public jackpotHeaderPlace = JackpotHeaderPlace;

  /**
   * Jackpot levels place
   */
  public jackpotLevelsPlace = JackpotLevelsPlace;

  /**
   * Get and serialize promo
   */
  public initJackpot(slug: string) {
    let jackpotSlug: string;

    this.getAvailableJackpotSlug(slug).pipe(
      filter(response => !!(response && response.length)),
      switchMap(response => {
        jackpotSlug = response[0] && response[0].Content;
        return this.getJackpotData(jackpotSlug);
      })
    ).subscribe();
  }

  public getJackpotData(jackpotSlug: string) {
    return combineLatest([
      this.getCmsJackpot(jackpotSlug),
      this.getSSJackpot()
    ]).pipe(
      tap(([cmsJackpot, ssJackpot]) => {
        if (cmsJackpot && ssJackpot) {
          this.jackpotSlug = jackpotSlug;
          this.isJackpotEnable = true;
          this.cmsJackpot$.next(cmsJackpot);
          this.ssJackpot$.next(ssJackpot);
          this._listenWsJackpots();
          this._listenWsWinnerJackpots();
        }
      })
    );
  }

  private _listenWsJackpots() {
    this._wsJackpots$.pipe(
      map(jackpot => this._updateLevelsAndTotal(jackpot)),
      tap(this.ssJackpot$)
    ).subscribe();
  }

  private _listenWsWinnerJackpots() {
    this._jackpotsWinBonusChange$.pipe(
      filter(bonus => bonus.strategy === 'jackpot_award' && (bonus.stage === 'issued' || bonus.stage === 'handle_bets')),
      map(jackpot => ({
        ...jackpot,
        currencySymbol: this._common.currencySymbol(jackpot.currency),
        amount: this._common.subunitsToUnits(jackpot.amount_cents, jackpot.currency)
      })),
      tap(this.wsWinnerJackpot$)
    ).subscribe();
  }

  /**
   * Get games list by category for bottom slider
   */
  public getGameListByCategory(): Observable<any> {
    return this._cmsApi.gameList({category: GameAttribute.JACKPOT, limit: 20})
      .pipe(
        filter(list => list && list.data && list.data.gameList),
        map(list => this._array.toChunks(this._games.gameListMapper(
            this._array.checkAndCloneArray(list.data.gameList, 4)), 4
        )),
      );
  }

  /**
   * Get total jackpot
   */
  public getTotalJackpot() {
    combineLatest([
      this._user.currency$,
      this._ssApi.getGamesTotalJackpots(),
    ]).pipe(
      map(([authCurrency, totalJackpot]) => {
        const currency = authCurrency
          ? this._user.currentCurrency.currency
          : this._env.env.currency.short ? this._env.env.currency.short : 'EUR';

        const amount = authCurrency
          ? this._common.subunitsToUnits(totalJackpot[currency], currency)
          : this._common.subunitsToUnits((totalJackpot[this._env.env.currency.short] || totalJackpot['EUR']), currency);

        const currencySymbol = this._common.currencySymbol(currency);

        const totalJackpotAmount = `${currencySymbol} ${this._number.transform(
          amount,
          isCryptoAcc(currency) ? '0.8' : '0.2'
        )}`;

        return totalJackpotAmount;
      }),
    ).subscribe((totalJackpot) => {
      this.totalJackpot$.next(totalJackpot);
    });
  }

  /**
   * Mapped grouplist
   * @param data
   */
  public mappedGroupList(data: any = {}): any {
    return Object.values(data);
  }

  public getAvailableJackpotSlug(slug: string) {
    return this._static.item({slug});
  }

  public getCmsJackpot(slug: string) {
    return this._pages.item({slug}).pipe(
      filter(jackpot => !!jackpot),
      map(jackpot => {
        jackpot[0].Levels = this.mappedGroupList(jackpot[0].Levels).sort(
          (prev, next) => Object.values(JackpotLevels).indexOf(prev.type) - Object.values(JackpotLevels).indexOf(next.type)
        );

        jackpot[0].Levels = jackpot[0].Levels?.reverse();

        return jackpot;
      }),
      map((jackpot) => jackpot.map(item => {
        return {
          ...item,
          Benefits: item.Benefits.split(',').map(s => s.trim()),
          Works: this.mappedGroupList(item.Works),
          WinnersList: (this.mappedGroupList(item.WinnersList) || []).map((item, index) => ({
            place: index + 1,
            name: item.name,
            prize: item.prize
          })),
        };
      })[0]),
    );
  }

  public getSSJackpot() {
    return this._ssApi.jackpots().pipe(
      map(jackpots => jackpots.filter(j => j.state === 'active')[0]),
      filter(jackpot => Boolean(jackpot)),
      map(jackpot => this._updateLevelsAndTotal(jackpot)),
      tap(data => this.winnersSSList$ = this._getWinnersSSList(data?.name, data?.currencySymbol)),
      tap(data => this.jackpotHunterCMSList$ = this._getJackpotHunterCMSList(data?.currencySymbol)),
    );
  }

  private _getWinnersSSList(jackpotName: string = '', currencySymbol?: string) {
    return this._ssApi.jackpotWins(jackpotName ? {jackpot_name: jackpotName, sort_by: 'amount_cents'} : {sort_by: 'amount_cents'}).pipe(
      filter(data => !!data),
      map(data => {
        return (data || []).slice(0, 10).map((item, index) => {
            return {
              place: index + 1,
              name: !this._rtl.isAr ? item.user_name.slice(-0, item.user_name.length > 3 ? -3 : -2) + (item.user_name.length > 3 ? '***' : '**') :
                (item.user_name.length > 3 ? '***' : '**') + item.user_name.slice(-0, item.user_name.length > 3 ? -3 : -2)?.split('')?.reverse()?.join(''),
              prize: `${currencySymbol} ` + this._common.subunitsToUnits(item.amount_cents, item.jackpot_original_currency)
            };
        });
      })
    );
  }
  private _getJackpotHunterCMSList(currencySymbol: string) {
    return this._cmsApi.getLeaderBoard({category_id: GameAttribute.JACKPOT}).pipe(
      filter(data => !!data),
      map(data  => data?.data?.data?.list),
      map(data => {
        const currentPlayer = data?.find((e) => e?.nickname === this._user?.info?.nickname);
        const array = (data || [])?.slice(0, 10);
        currentPlayer ? array.push(currentPlayer) : null;
        const result = array.map((item) => {
          return {
            isCurrentPlayer: this._user?.info?.nickname === item?.nickname,
            place: item?.place,
            name: !this._rtl.isAr ? item?.nickname?.slice(-0, item?.nickname?.length > 3 ? -3 : -2) + (item?.nickname?.length > 3 ? '***' : '**') :
              (item?.nickname?.length > 3 ? '***' : '**') + item?.nickname?.slice(-0, item?.nickname?.length > 3 ? -3 : -2)?.split('')?.reverse()?.join(''),
            prize: `${currencySymbol} ` + item?.bets_volume,
          };
        });
        return result;
      }),
    );
  }

  private _updateLevelsAndTotal(wsJackpotMessage: IJackpotSS) {
    wsJackpotMessage = JSON.parse(JSON.stringify(wsJackpotMessage));
    wsJackpotMessage['total'] = 0;
    wsJackpotMessage['currencySymbol'] = this._common.currencySymbol(wsJackpotMessage.currency);
    wsJackpotMessage.levels.sort((prev: any, next: any) => Object.values(JackpotLevels).indexOf(prev?.name) - Object.values(JackpotLevels).indexOf(next.name));
    wsJackpotMessage.levels.forEach(level => {
      level['total'] = 0;
      const sumFilds = ['amount_cents'];
      Object.entries(level).forEach(f => sumFilds.includes(f[0]) ? level[f[0]] = this._common.subunitsToUnits(f[1], wsJackpotMessage.currency) : null);
      level['total'] = Number((Object.entries(level).reduce((acc, curr) =>   acc + (sumFilds.includes(curr[0]) ? curr[1] : 0) , 0)).toFixed(2));
      wsJackpotMessage['total'] += Number((Object.entries(level).reduce((acc, curr) =>   acc + (sumFilds.includes(curr[0]) ? curr[1] : 0) , 0)).toFixed(2));
    });

    return wsJackpotMessage;
  }
}
