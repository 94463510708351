import { ChangeDetectorRef, Component, inject, input, OnDestroy, OnInit, output } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import { merge } from 'rxjs';
import { NgClass } from '@angular/common';
import { FormControlsModule } from '../../../../../../../core/modules/form-controls/form-controls.module';
import { InputNumberDirective } from 'ngx-unificator/directives';
import {
  CurrencySelectorStandaloneComponent,
} from '../../../../../../../core/shared/standalone/currency-selector-standalone/currency-selector-standalone.component';
import { TranslatePipe } from '../../../../../../../core/shared/translation/translate.pipe';
import { FormsErrorHandlerService } from '../../../../../../../core/services/forms-error-handler.service';
import { UserService } from '../../../../../../../core/services/user/user.service';
import { UserPaymentSsV2Service } from '../../../../../../../core/services/user/user-payment-ss-v2.service';
import { LotteryService } from '../../../../../../../core/services/lottery.service';

@UntilDestroy()
@Component({
    selector: 'app-amount-selector',
    templateUrl: './amount-selector.component.html',
    styleUrls: ['./amount-selector.component.scss'],
    imports: [NgClass, FormControlsModule, FormsModule, InputNumberDirective, ReactiveFormsModule, CurrencySelectorStandaloneComponent, TranslatePipe]
})
export class AmountSelectorComponent implements OnInit, OnDestroy {
  private _formErrors = inject(FormsErrorHandlerService);
  user = inject(UserService);
  private _payment = inject(UserPaymentSsV2Service);
  private _cd = inject(ChangeDetectorRef);
  private _lottery = inject(LotteryService);


  readonly amountValue = output<number | string>();

  /**
   * Amount form control
   */
  readonly control = input<AbstractControl>(new UntypedFormControl());

  /**
   * Min value
   */
  readonly min = input<number>(undefined);

  /**
   * Max value
   */
  readonly max = input<number>(undefined);

  /**
   * Action Type
   */
  readonly action = input<string>(undefined);

  /**
   * Action Type
   */
  readonly valueList = input<any>(undefined);

  /**
   * List of errors for current control
   */
  errors: Array<string> = [];

  /**
   * For correct select working
   */
  currencyControl: UntypedFormControl = new UntypedFormControl(null);

  ngOnInit() {
    this._resolveValidators();
    this._inputChangeHandle();
    const control = this.control();
    if (control?.value) {
      this._lottery.setDepositAmount(control?.value)
    }
  }

  ngOnDestroy(): void {
  }

  /**
   * Set min/max validators
   *
   */
  private _resolveValidators() {
    const validators = [Validators.required];
    const min = this.min();
    if (min) {
      validators.push(Validators.min(min));
    }
    const max = this.max();
    if (max) {
      validators.push(Validators.max(max));
    }
    this.control().setValidators(validators);
  }

  /**
   * Select amount by clicking on button
   *
   * @param value
   */
  selectAmount(value: number) {
    this.control().markAsTouched();
    this.control().setValue(value);
    this._cd.detectChanges();
  }

  /**
   * Handle form control change
   *
   * @private
   */
  private _inputChangeHandle() {
    this.errors = this._formErrors.errors(this.control());
    merge(
      this.control().statusChanges,
      this.control().valueChanges
    ).pipe(
      untilDestroyed(this),
      tap((value) => {

        if (isFinite(value)) {
          this._lottery.setDepositAmount(Number(value));
        }
        const control = this.control();
        this.amountValue.emit(control.value);
        this.errors = this._formErrors.errors(control);
        this._cd.detectChanges();
      })
    ).subscribe();
  }

  public onBlur(e) {
    e.target.blur();
  }

  /**
   * Change account currency
   * @param $event
   */
  public changeAccountCurrency($event) {
    if (!$event || $event === this.user.currentCurrency.currency) {
      return;
    }
    this.user.changeCurrencyAcc($event);
  }
}
