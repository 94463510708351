export const INSTALL_APP_MISSION_BONUS_RECEIVED = 'ID837'
export const INSTALL_APP_GROUP = 'ID836'

export const CRYPTO_GROUP = 'btcplayer';
export const FIRST_TIME_DEP = 'ID402';

export const FIRST_DEP = 'ID402';
export const SECOND_DEP = 'ID572';

export const FIRST_CASHOUT = 'ID638';

export const EXCLUDE_SEON_TEST_GROUP = 'ID139';

