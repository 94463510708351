import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, inject, input } from '@angular/core';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import { UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputCurrenciesSelectorComponent } from '../input-currencies-selector/input-currencies-selector.component';
import { AsyncPipe } from '@angular/common';
import { Payment, PaymentsMethod } from '../../../../../../../core/vendor/ss-payments-v2/ss-payment-types';
import { PreloaderComponent } from '../../../../../../../core/shared/components/preloader/preloader.component';
import { TranslatePipe } from '../../../../../../../core/shared/translation/translate.pipe';
import { CurrencyFormatPipe } from '../../../../../../../core/shared/pipes/currency-format.pipe';
import { LotteryService } from '../../../../../../../core/services/lottery.service';
import { UserService } from '../../../../../../../core/services/user/user.service';
import {
  CryptoCurrenciesConverterService
} from '../../../../../../../core/services/crypto-currencies-converter.service';
import { switchMap, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'app-exchange-rates',
    templateUrl: './exchange-rates.component.html',
    styleUrls: ['./exchange-rates.component.scss'],
    imports: [FormsModule, ReactiveFormsModule, InputCurrenciesSelectorComponent, PreloaderComponent, AsyncPipe, TranslatePipe, CurrencyFormatPipe]
})
export class ExchangeRatesComponent implements OnInit, OnChanges, OnDestroy {
  private _fb = inject(UntypedFormBuilder);
  private _lottery = inject(LotteryService);
  user = inject(UserService);
  converter = inject(CryptoCurrenciesConverterService);


  readonly field = input<any>(undefined);
  /**
   * Payment fields
   */
  @Input() method: PaymentsMethod;

  /**
   * Current payment action
   */
  readonly action = input<Payment.Action>(undefined);

  /**
   * Form currencies
   */
  form: UntypedFormGroup = this._fb.group({
    give: [],
    get: []
  });

  public min: number;

  ngOnInit() { }

  /**
   * Set initial value for give control and add subs for value changes
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (this.method && this.action()) {
      this._changeGiveControl();
      this._changeGetControl();
      this.form.get('give').setValue(this._getMinValue());
    }
  }

  ngOnDestroy() {
  }

  /**
   * Change account currency
   * @param $event
   */
  public changeAccountCurrency($event) {
    if (!$event || $event === this.user.currentCurrency.currency) {
      return;
    }
    this.user.changeCurrencyAcc($event);
  }

  /**
   * Change Give control
   * @private
   */
  private _changeGiveControl() {
    this.form.get('give').valueChanges.pipe(
      untilDestroyed(this),
      switchMap(currencyFromValue => this.converter.getValueCurrencyTo(Number(currencyFromValue))),
      tap(count => this.form.get('get').setValue(count, {emitEvent: false})),
      tap(count => this._lottery.setDepositAmount(count))
    ).subscribe();
  }

  /**
   * Change Get control
   * @private
   */
  private _changeGetControl() {
    this.form.get('get').valueChanges.pipe(
      untilDestroyed(this),
      tap(currencyFromValue => this._lottery.setDepositAmount(currencyFromValue)),
      switchMap(currencyFromValue => this.converter.getValueCurrencyFrom(Number(currencyFromValue))),
      tap(count => this.form.get('give').setValue(count, {emitEvent: false}))
    ).subscribe();
  }

  /**
   * If the method is crypto, return the min value of the currency, otherwise return the min value of the
   * method.
   * @returns The min value of the payment method.
   */
  private _getMinValue() {
    this.min = this.method?.termsOfService?.restrictions?.minAmountValue;
    return this.method?.termsOfService?.restrictions?.minAmountValue;
  }


}
