<div class="bonuses">
  @if (title) {
    <div class="bonuses__info" [innerHTML]="title | safe: 'html'"></div>
  }

  <div class="bonuses__list"
    [ngClass]="{four: bonuses()?.length === 4, five: bonuses()?.length === 5}"
    >
    @for (bonus of bonuses(); track bonus) {
      <div class="bonuses__item" [ngClass]="{active: bonus?.active, used: bonus?.used}">
        <app-bonus-preview [bonus]="bonus"></app-bonus-preview>
      </div>
    }
  </div>
</div>
