import { Component, inject } from '@angular/core';
import { InstallAppService } from '../../../services/install-app.service';
import { ArabicService } from '../../../services/arabic.service';
import { TranslatePipe } from '../../translation/translate.pipe';
import { RouterLinkDirective } from '../../directives/router-link.directive';
import { RouterLink } from '@angular/router';

import { SetImgAltDirective } from '../../directives/set-img-alt.directive';

@Component({
    selector: 'app-install-app-banner',
    templateUrl: './install-app-banner.component.html',
    styleUrls: ['./install-app-banner.component.scss'],
    imports: [SetImgAltDirective, RouterLink, RouterLinkDirective, TranslatePipe]
})
export class InstallAppBannerComponent {  installApp = inject(InstallAppService);
  rtl = inject(ArabicService);


}
