import { environment } from 'src/environments/environment';
import { Arguments, CustomPaymentType, RedirectRoutes } from './ss-payment-types';
import { Payment } from 'payments-lib-types';

export const SS_LIB_CONFIG: Arguments.ConfigArgs = {
  serverUrl: environment.ss_host,
  cdnList: ['https://cdn2.softswiss.net'],
};

export const redirectRoutes = (action: Payment.Action, paymentType: CustomPaymentType): RedirectRoutes => {
  return {
    successUrl: (location?.origin as string) + `/payment-status/${action}/success`,
    failureUrl: (location?.origin as string) + `/payment-status/${action}/failure`,
    pendingUrl: (location?.origin as string) + '/profile/history',
    supportUrl: (location?.origin as string) + '/faq',
  };
};

export const CUSTOM_ROUTES: RedirectRoutes = {};
export const BONUS_CODE_QUERY_PARAM_NAME = 'bonusCode';
export const SUCCESS_QUERY_PARAM_NAME = 'successUrl';

/**
 * Methods which have own image on our side
 */
export const methodsWithOwnImage = ['trustly', 'sofort', 'brite', 'interac_finteqhub'];

/**
 * Methods whith bigger image
 */
export const methodsWithBiggerImage = ['interac_finteqhub'];
