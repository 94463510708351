/**
 * Available categories
 */
export enum GameCategory {
  SLOTS = 48,
  OKTOBERFEST = 121,
  BLACKJACK = 50,
  ROULETTE = 49,
  VIDEO_POKER = 63,
  TABLE_GAMES = 64,
  LIVE_CASINO = 61,
  FAVORITE_ID = 67,
  LAST_PLAYED_ID = 65,
  BACCARAT = 76,
  OTHER_GAMES = 69,
  EASTER_GAMES = 78,
  SUMMER_GAMES = 138,
  FAVORITE = 'favorite',
  LAST_PLAYED = 'last-played',
  FREE_SPINS = 81,
  HALLOWEEN_GAMES = 103,
  BUY_BONUS = 80,
  WINTER_FAVORITES = 84,
  LOVE_CARNIVAL = 86,
  PATRICK_GAMES = 94,
  SPINFINITY_QUEST = 101,
  JACKPOT_MATSURI = 102,
  JACKPOT = 98,
  VALENTINE_DAY = 86,
  TROPICAL_FIESTA = 107,
  NEW_COMER_CATEGORY = 123,
  DUPLICATED_JACKPOT = 29,
}
