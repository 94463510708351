import { Component, inject } from '@angular/core';
import { LanguageService } from '../../../services/language/language.service';
import { TranslatePipe } from '../../translation/translate.pipe';
import { RouterLinkDirective } from '../../directives/router-link.directive';
import { RouterLink } from '@angular/router';
import { InstallAppService } from '../../../services/install-app.service';


@Component({
    selector: 'app-bottom-navigation',
    templateUrl: './bottom-navigation.component.html',
    styleUrls: ['./bottom-navigation.component.scss'],
    imports: [RouterLink, RouterLinkDirective, TranslatePipe]
})
export class BottomNavigationComponent {
  lang = inject(LanguageService);
  installApp = inject(InstallAppService);


}
