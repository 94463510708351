<div class="phone">
  <div (click)="onOpen()" class="country">
    <img class="country__img" [src]="'/assets/svg/flags/' + currentCountry + '.svg'" alt="">
    <div class="country__label">{{ currentCountry }}</div>
  </div>
  <div class="countries" [ngClass]="{open: isOpen}" ClickOutside (clickOutside)="onClose()">
    <input class="countries__search" [formControl]="search" type="text" [placeholder]="'t.search' | translate">
    @if (filteredCountryList$ | async; as filteredCountryList) {
      <div class="countries__list">
        @for (country of filteredCountryList; track country) {
          <div
            (click)="onSelect(country)"
            [ngClass]="{'active': currentCountry === country.short}"
            class="countries__list--item"
            >
            <img [src]="'/assets/svg/flags/' + country?.short + '.svg'" alt="">
            <span>{{ country?.name }}</span>
          </div>
        }
      </div>
    }
  </div>
  <input
    [prefix]="userPhone?.state().phonePrefix"
    [mask]="userPhone?.state().dynamicPhoneMask"
    [showMaskTyped]="userPhone.state().isDynamicShowMaskTyped"
    [dropSpecialCharacters]="true"
    [validation]="false"
    [(ngModel)]="value"
    (ngModelChange)="userPhone.updateMask($event)"
    (paste)="userPhone.handlePaste($event, inputModel)"
    #inputModel="ngModel"
    inputmode="numeric"
    type="text"
    class="input input--simple"
    >
  </div>
