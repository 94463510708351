import { AfterContentInit, Component, ElementRef, forwardRef, HostListener, OnInit, Output, inject, contentChild } from '@angular/core';
import { SelectService } from './select.service';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { SelectDropdownComponent } from './select-dropdown/select-dropdown.component';
import { SelectHeaderComponent } from './select-header/select-header.component';

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    providers: [
        SelectService,
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => SelectService)
        }
    ],

})
export class SelectComponent implements OnInit, AfterContentInit {
  select = inject(SelectService);
  private _el = inject(ElementRef);


  /**
   * Value changes emitter
   */
  @Output() change: ReplaySubject<any> = this.select.value$;

  /**
   * Link to SelectDropdownComponent
   */
  readonly dropdown = contentChild(SelectDropdownComponent);

  /**
   * Link to SelectHeaderComponent
   */
  readonly header = contentChild(SelectHeaderComponent);

  /**
   * Click handler
   *
   * @param target
   */
  @HostListener('document:click', ['$event.target'])
  @HostListener('document:touchend', ['$event.target'])
  onClick(target: HTMLElement) {
    if (this._el.nativeElement.contains(target)) {
      this.select.open();
    } else if (!target.classList.contains('keen-slider')){
      this.select.close();
    }
  }

  ngOnInit() {
  }

  /**
   * Init Select service after inner components content init
   */
  ngAfterContentInit() {
    this.select.init(this.header(), this.dropdown());
  }

  /**
   * Filter select options by provided query
   *
   * @param query
   */
  filterOptions(query: string) {
    this.select.filterOptions(query);
  }

}
