import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslationService } from '../translation/translation.service';

@Pipe({
    name: 'processingTime',

})
export class ProcessingTimePipe implements PipeTransform {
  private _translation = inject(TranslationService);



  transform(value: any): string {
    if (value?.type === 'instant') {
      return this._translation.translate('t.instant');
    }
    if (value && typeof value === 'object') {
      return `${value.minDays}-${value.maxDays} ` + this._translation.translate('labl.days');
    }

    return value;
  }

}
