import { Injectable, inject } from '@angular/core';
import {ModalsFromUrlList, ModalsString} from './modals-from-url-list';
import { first, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TabPanelController, TabsTypes } from '../helpers/tab-panel-controller';
import { UserService } from '../services/user/user.service';
import {AccountPage} from '../../page/account/account.service';

@Injectable({
  providedIn: 'root'
})
export class ModalsFromUrlService {
  private _user = inject(UserService);
  private _router = inject(Router);


  /**
   * TabPanelController instance for managing cashier panel
   */
  public controller: TabPanelController = new TabPanelController({
    defaultTab: ModalsFromUrlList.LOGIN,
    availableTabs: ModalsFromUrlList,
    modalsString: ModalsString,
    queryParam: 'modal',
    tabType: TabsTypes.MODAL,
    resolver: this._user.auth$.pipe(
      first(),
      map(auth => !auth)
    ),
    rejectFn: () => !this._router.url.includes('modal') ? this._router.navigateByUrl('/profile/' + AccountPage.DEPOSIT) : null
  });

  /**
   * Available tabs
   */
  get tab() {
    return ModalsFromUrlList;
  }
}
