import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { WindowService } from './window.service';
import { fromEvent, ReplaySubject } from 'rxjs';
import { PlatformService } from './platform.service';
import { Injectable, NgZone, inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { isDesktop, isMobile, isTablet } from '../helpers/device';
import { DeviceDetectorService } from 'ngx-unificator/services';

export interface WindowSize {
  width: number | any;
  height: number | any;
}

export enum VisibilityState {
  HIDDEN = 'hidden',
  VISIBLE = 'visible'
}

export const IdLinkAsSLugForCms: string[] = ['currency-exchange'];

@Injectable({
  providedIn: 'root'
})
export class GlobalEventsService {
  private _platform = inject(PlatformService);
  private _zone = inject(NgZone);
  private _window = inject(WindowService);
  private _router = inject(Router);
  private _device = inject(DeviceDetectorService);
  private _document = inject<Document>(DOCUMENT);

  private _isLeaveBrowserTab = null;
  public resize$: ReplaySubject<any> = new ReplaySubject(1);
  public scroll$: ReplaySubject<any> = new ReplaySubject(1);
  public beforeinstallprompt$: ReplaySubject<any> = new ReplaySubject(1);
  public routerNavigationStart$: ReplaySubject<any> = new ReplaySubject(1);
  public routerNavigationEnd$: ReplaySubject<any> = new ReplaySubject(1);
  public globalRouterEvent$: ReplaySubject<any> = new ReplaySubject(1);
  public visibilityChangeEvent$: ReplaySubject<VisibilityState> = new ReplaySubject(1);
  public fullscreenChangeEvent$: ReplaySubject<any> = new ReplaySubject(1);
  public isVisibleTab$: ReplaySubject<boolean> = new ReplaySubject(1);
  public isLeaveBrowserTab$: ReplaySubject<any> = new ReplaySubject(1);
  public isExchangeRateLinkClick$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public initGlobalEvent() {
    this._runRouterNavigationListener();
    if (this._platform.isBrowser) {
      isMobile.set(this._device.isMobile());
      isDesktop.set(this._device.isDesktop());
      isTablet.set(this._device.isTablet());
      this._zone.run(() => {
        this._runScrollListener();
        this._runResizeListener();
        this._runBeforeInstallPromptListener();
        this._runVisibilityChangeListener();
        this._runFullscreenChangeEventListener();
        this._handlerLeaveBrowserTab();
        this._handlerVisibilityChange();
        this._runClickListener();
      });
    }
  }

  /**
   * Run global scroll listener
   */
  private _runScrollListener() {
    fromEvent(this._window.nativeWindow, 'scroll', {passive: true, capture: false})
    .subscribe((e) => this.scroll$.next(e));
  }

  /**
   * Run global resize listener
   */
  private _runResizeListener() {
    fromEvent(this._window.nativeWindow, 'resize', {passive: true}).pipe(
      map(event => <WindowSize>{
        width: (<any>window).innerWidth,
        height: (<any>window).innerHeight
      })
    ).subscribe((e) => this.resize$.next(e));
  }

  /**
   * Run global before install prompt listener
   */
  private _runBeforeInstallPromptListener() {
    fromEvent(this._window.nativeWindow, 'beforeinstallprompt')
    .subscribe((e) => this.beforeinstallprompt$.next(e));
  }

  /**
   * Run global router event listener
   */
  private _runRouterNavigationListener() {
    this._router.events.subscribe((e) => {
      if(e instanceof NavigationStart) {
        this.routerNavigationStart$.next(e);
      }
      if (e instanceof NavigationEnd) {
        this.routerNavigationEnd$.next(e);
      }
      this.globalRouterEvent$.next(e);
    });
  }

  /**
   * Run global visibilitychange listener
   */
  private _runVisibilityChangeListener() {
    fromEvent(this._window.nativeWindow, 'visibilitychange')
      .subscribe((e) => this.visibilityChangeEvent$.next(document.visibilityState as VisibilityState));
  }

  /**
   * Run global fullscreenchangeEvent listener
   */
  private _runFullscreenChangeEventListener() {
    fromEvent(this._window.nativeWindow, 'fullscreenchange')
        .subscribe((e) => this.fullscreenChangeEvent$.next(e));
  }

  /**
   * It's include open\close browser console, click on iframes, change active tab
   */
  private _handlerLeaveBrowserTab() {
    if (this._platform.isBrowser) {
      fromEvent(this._window.nativeWindow, 'blur').subscribe((e: any) => {
        if (e.eventPhase && !this._isLeaveBrowserTab) {
          this._isLeaveBrowserTab = true;
          this.isLeaveBrowserTab$.next(true);
        }
      });
      fromEvent(this._window.nativeWindow, 'focus').subscribe((e: any) => {
        if (e.eventPhase && this._isLeaveBrowserTab) {
          this._isLeaveBrowserTab = false;
          this.isLeaveBrowserTab$.next(false);
        }
      });
    }
  }

  /**
   * This function listens for changes in the visibility state of the document and updates a
   * BehaviorSubject accordingly.
   */
  private _handlerVisibilityChange() {
    fromEvent(this._document, 'visibilitychange').subscribe(() => {
      if (this._document.visibilityState === 'visible') {
        this.isVisibleTab$.next(true);
      } else {
        this.isVisibleTab$.next(false);
      }
    });
  }

  private _runClickListener() {
    fromEvent(this._document, 'click').subscribe(e => {
      const target = e?.target as HTMLElement;
      switch (true) {
        case target?.nodeName === 'A' && IdLinkAsSLugForCms.includes(target?.id?.trim()):
          this.isExchangeRateLinkClick$.next(target?.id?.trim());
          break;
      }
    });
  }
}
