import { Injectable, NgZone, inject } from '@angular/core';
import { delay, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { GlobalEventsService } from './global-events.service';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root'
})
export class AnimatedFaviconService {
  private _events = inject(GlobalEventsService);
  private _platform = inject(PlatformService);
  private _zone = inject(NgZone);


  private _interval;
  private _toggler: boolean = true;
  public init() {
    if (this._platform.isBrowser) {
      this._events.isLeaveBrowserTab$.pipe(
        tap(() => this.stopAnimation()),
        filter((e) => !!e),
        tap(() => {
          this.startAnimation();
        })
      ).subscribe()
    }

    this._stopOnActiveTab();
  }
  public startAnimation() {
    if (this._interval) {
      return;
    }
    this._zone.runOutsideAngular(() => {
      this._interval = setInterval(() => {
        this._faviconAnimation();
      }, 1000)
    })
  }

  public stopAnimation() {
    if (this._platform.isBrowser) {
      if (this._interval) {
        clearInterval(this._interval);  // Stop the interval when the animation stops
        this._interval = undefined;
      }
      const favicons = this._getFavicons();
      const defaultFaviconUrl = '/assets/img/icons/192.png';
      favicons.forEach((favicon: HTMLLinkElement) => {
        this._updateFavicon(favicon, defaultFaviconUrl);
      })
    }
  }

  private _stopOnActiveTab() {
    if (this._platform.isBrowser) {
      this._events.isVisibleTab$.pipe(
        filter((e) => !!e),
        distinctUntilChanged(),
        delay(100),
        tap(() => {
          this.stopAnimation()
        })
      ).subscribe()
    }
  }

  private _getFavicons(): NodeListOf<HTMLLinkElement> {
    return document.querySelectorAll('link[rel*="icon"]');
  }

  private _updateFavicon(favicon: HTMLLinkElement, newFaviconUrl: string) {
    favicon.href = newFaviconUrl;
  }

  private _faviconAnimation() {
    const serviceWorker = navigator.serviceWorker.controller;
    if (serviceWorker) {
      serviceWorker.postMessage({ type: 'BROWSER_LEAVE_ANIMATION' });
    }
    const favicons = this._getFavicons();
    const faviconUrl1 = '/assets/img/icons/anim-1.png';
    const faviconUrl2 = '/assets/img/icons/anim-2.png';

    const newFaviconUrl = this._toggler ? faviconUrl1 : faviconUrl2;
    this._toggler = !this._toggler;

    favicons.forEach(favicon => {
      this._updateFavicon(favicon, newFaviconUrl);
    });
  }
}
