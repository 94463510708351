import { CookieService, DeviceDetectorService } from 'ngx-unificator/services';
import { delay, distinctUntilChanged, filter, switchMap, takeWhile } from 'rxjs/operators';
import { PlatformService } from '../../core/services/platform.service';
import { StaticContentService } from '../../core/services/static-content.service';
import { UserService } from '../../core/services/user/user.service';
import { GroupsService } from '../../core/services/groups.service';
import { LocalHistoryService } from '../../core/services/local-history.service';
import { ActivatedRoute, Router } from '@angular/router';
import { inject } from '@angular/core';
import { ModalsFromUrlService } from '../../core/modal-v2/modals-from-url.service';
import { ModalService } from '../../core/modal-v2/modal.service';
import { UserBonusesService } from '../../core/services/user/user-bonuses.service';
import { StaticPageService } from '../static-page/static-page.service';
import { LootboxService } from '../../core/services/lootbox/lootbox.service';
import { TimeService } from '../../core/services/time.service';
import { ConfettiService } from '../../core/services/confetti.service';
import { GlobalEventsService } from '../../core/services/global-events.service';
import { WindowService } from '../../core/services/window.service';
import { TranslationService } from '../../core/shared/translation/translation.service';
import { ResponseHandlerService } from '../../core/services/response-handler.service';
import { CmsApiService } from '../../core/services/api/cms-api.service';
import { ArrayService } from '../../core/services/array.service';
import { GamesService } from '../../core/services/games/games.service';
import { BreakpointsDetectorService } from '../../core/services/breakpoints-detector.service';
import { LanguageService } from '../../core/services/language/language.service';
import { TournamentsService } from '../tournaments/tournaments.service';
import { CopyTextService } from '../../core/services/copy-text.service';
import { ArabicService } from '../../core/services/arabic.service';
import { Observable } from 'rxjs';

export enum EventType {
  First = 'first',
  Second = 'second',
  Third = 'third'
}

export interface IBasePromo {
  HuntModal: {
    BonusGroupClaimed: string;
    BonusGroupCatch: string;
    Title: string;
    Desc: string;
    Rule: string;
    AboutPromo: string;
    CongratsTitle: string;
    Prize: string;
    FinalDesc: string;
    Code: string;
    DepositRule: string;
    Terms: string;
  };
  WelcomePromoModal: {
    Title: string;
    Desc: string;
    Prize: string;
    Rule: string;
  };
}

export abstract class BasePromo {

  /**
   * Abstract event name for hunt(halloween, easter-eggs ...)
   */
  public abstract eventName: string;

  /**
   * Event type elements
   */
  public eventType = EventType;

  /**
   * Event collected list
   */
  public eventList: unknown[] = null;

  /**
   * Event Loaded
   */
  private _eventLoaded = null;

  /**
   * Show/hide sidebar menu link
   */
  public linkMenuEnabled: boolean;

  /**
   * Event group for hunt
   */
  public eventGroup: string;

  /**
   * Data for hunt (text, userGroup, etc)
   */
  public huntModalData: any = {};
  public welcomeModalData: any = {};

  private _static: StaticContentService = inject(StaticContentService);
  private _modalsFromUrl: ModalsFromUrlService = inject(ModalsFromUrlService);
  private _events: GlobalEventsService = inject(GlobalEventsService);
  private _arabic: ArabicService = inject(ArabicService);
  public array: ArrayService = inject(ArrayService);
  public breakpoint: BreakpointsDetectorService = inject(BreakpointsDetectorService);
  public platform: PlatformService = inject(PlatformService);
  public history: LocalHistoryService = inject(LocalHistoryService);
  public group: GroupsService = inject(GroupsService);
  public window: WindowService = inject(WindowService);
  public bonuses: UserBonusesService = inject(UserBonusesService);
  public user: UserService = inject(UserService);
  public page: StaticPageService = inject(StaticPageService);
  public lootboxService: LootboxService = inject(LootboxService);
  public time: TimeService = inject(TimeService);
  public tournaments: TournamentsService = inject(TournamentsService);
  public device: DeviceDetectorService = inject(DeviceDetectorService);
  public confetti: ConfettiService = inject(ConfettiService);
  public router: Router = inject(Router);
  public translate: TranslationService = inject(TranslationService);
  public responseHandler: ResponseHandlerService = inject(ResponseHandlerService);
  public cmsApi: CmsApiService = inject(CmsApiService);
  public games: GamesService = inject(GamesService);
  public lang: LanguageService = inject(LanguageService);
  public local: LocalHistoryService = inject(LocalHistoryService);
  public modals: ModalService = inject(ModalService);
  public copy: CopyTextService = inject(CopyTextService);
  public cookie: CookieService = inject(CookieService);
  public route: ActivatedRoute = inject(ActivatedRoute);


  public get eventEnable(): boolean {
    return this._eventLoaded;
  }

  public set eventEnable(enable) {
    this._eventLoaded = enable;
  }

  public get isFirstExist(): boolean {
    return this.user.auth && this._eventLoaded && !this.eventList.includes(this.eventType.First);
  }

  public get isSecondExist(): boolean {
    return this.user.auth && this._eventLoaded && !this.eventList.includes(this.eventType.Second);
  }

  public get isThirdExist(): boolean {
    return this.user.auth && this._eventLoaded && !this.eventList.includes(this.eventType.Third);
  }

  public addEventToCollection(type: EventType) {
    if (this.platform.isBrowser) {
      this.eventList.push(type);
      this.cookie.set(this.eventName, JSON.stringify(this.eventList), 999, '/');
      if (this.eventList.length === 3) {
        this.group.addToGroup(this.huntModalData.BonusGroupCatch)?.subscribe();
      }
      this.openHuntModal(type);
    }
  }

  public initHuntEvent(HuntModal: any) {
    this.huntModalData = HuntModal;
    if (!this.group.isExistGroup(this.huntModalData?.BonusGroupClaimed)) {
      this.cookie.check(this.eventName) ?
        this.eventList = JSON.parse(this.cookie.get(this.eventName)) :
        this.eventList = [];
      this._eventLoaded = true;
    } else {
      this._eventLoaded = false;
    }
  }

  public initWelcomePromoModal() {
    if (this.platform.isBrowser) {
      this._checkShouldOpenWelcomeModal$().subscribe();
    }
  }


  private _checkShouldOpenWelcomeModal$(): Observable<boolean> {
    const routesIgnore = ['403', '404', 'register', 'login', 'deposit', 'cashout', 'games', 'promo', 'play'];
    return this._events.routerNavigationEnd$
      .pipe(
        takeWhile(() => !this.cookie.check('welcome-modal-promo'), true),
        distinctUntilChanged(),
        delay(120000),
        filter(() => {
          const hasWelcomeModalCookie = this.cookie.check('welcome-modal-promo');
          const shouldOpenModal = this.user.auth && !hasWelcomeModalCookie;
          return shouldOpenModal && !routesIgnore.some(route => this.router.url.includes(route));
        }),
        switchMap(() => this.checkActivatedBonuses()),
      );
  }


  public abstract openHuntModal?(type: EventType): void;

  public abstract checkActivatedBonuses?(): Observable<any> | Promise<any>;

}
