import { Component } from '@angular/core';

@Component({
    selector: 'app-toast-message-item',
    templateUrl: './toast-message-item.component.html',
    styleUrls: ['./toast-message-item.component.scss'],

})
export class ToastMessageItemComponent {

  constructor() { }

  ngOnInit() {
  }

}
