import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  inject,
  Input,
  input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  output,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  viewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import KeenSlider, { KeenSliderInstance, SliderOptions } from 'keen-slider';
import { IntersectionObserverService } from '@ng-web-apis/intersection-observer';
import { PlatformService } from '../../services/platform.service';
import { VisibilityService } from '../../services/visibility.service';
import { LanguageService } from '../../services/language/language.service';
import { filter, tap } from 'rxjs/operators';
import { ArabicService } from '../../services/arabic.service';
import { NgClass } from '@angular/common';

export type CustomConfig = { autoplay?: boolean, delay: number, imgLoaded: boolean, centered: boolean };
export type SliderConfig = Partial<SliderOptions & CustomConfig>;

@Component({
    selector: 'app-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        IntersectionObserverService,
    ],
    imports: [
        NgClass
    ]
})
export class SliderComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  private _platform = inject(PlatformService);
  private _zone = inject(NgZone);
  private _render = inject(Renderer2);
  private _visibility = inject(VisibilityService);
  private _cd = inject(ChangeDetectorRef);
  private _lang = inject(LanguageService);
  arabic = inject(ArabicService);
  private _entries$ = inject<IntersectionObserverService>(IntersectionObserverService);


  private _slider: KeenSliderInstance = null;
  private _interval: any = 0;
  private _pause = false;
  private _currentSlide = 0;
  private _startPosition = null;
  private _endPosition = null;

  private _defaultConfig = {
    root: null,
    rootMargin: '0px',
    threshold: 0.0,
    loop: true,
    slides: {
      perView: 1,
    },
    autoplay: false,
    delay: 5000,
    breakpoints: {
      '(max-width: 720px)': {
        slides: {
          perView: 1,
        },
      },
    },
    imgLoaded: true,
    renderMode: performance,
    rtl: this.arabic.isAr
  };

  /**
   * If current browser tab active subscription
   */
  private _pageIsVisible$: Subscription = null;

  private _langDirectionChangeListener$: Subscription = null;

  private _isSliderInited = false;

  readonly track = viewChild<ElementRef<HTMLElement>>('track');
  readonly pagination = viewChild<TemplateRef<HTMLElement>>('pagination');

  /**
   * Custom slider class
   */
  readonly class = input<string | string[]>('');

  /**
   * Slider list data
   */
  readonly slideList = input<any[]>([]);

  /**
   * Slider config
   */
  @Input('config') config: SliderConfig = {};

  /**
   * Use intersection Observer
   */
  readonly intersectionObserver = input<boolean>(false);

  /**
   * Slider event emitters
   */
  readonly mounted$ = output<any>();
  readonly slideChanged$ = output<any>();
  readonly afterChange$ = output<any>();

  readonly created$ = output<any>();
  readonly updated$ = output<any>();
  readonly animationEnded$ = output<any>();

  @HostListener('touchend', ['$event'])
  onTouch(event: Event): void {
    if (event) {
      this._runDetectChanges();
    }
  }

  public get slider(): KeenSliderInstance {
    return this._slider;
  }

  public get paginationElement() {
    return this.pagination();
  }

  public get currentSlide() {
    return this._currentSlide;
  }
  public get isSliderInited() {
    return this._isSliderInited;
  }

  ngOnInit() {
    if (this._platform.isBrowser) {
      this.config = {...this._defaultConfig, ...this.config};
      this._langDirectionChangeListener();
    }
  }

  ngAfterViewInit() {
    if (this._platform.isBrowser) {
      this._zone.run(() => {
        try {
          if (this.intersectionObserver()) {
            this._entries$.subscribe(e => {
              if (e[0].isIntersecting) {
                setTimeout(() => {
                  this._addSlideItemClass();
                  this._initSlider();
                  this._runSliderEmitters();
                }, 100);
              } else {
                this._destroySlider();
              }
            });
          } else {
            setTimeout(() => {
              this._addSlideItemClass();
              this._initSlider();
              this._runSliderEmitters();
            }, 100);
          }
        } catch (error) {
          setTimeout(() => {
            this._addSlideItemClass();
            this._initSlider();
            this._runSliderEmitters();
          });
        }
      });
    }
  }

  /**
   * Initialize slider
   */
  private _initSlider() {
    this._slider = new KeenSlider(this.track().nativeElement, {
      ...this.config,
      created: slider => {
        this.mounted$.emit(slider);
        this._currentSlide = slider.track?.details?.rel;
        this.slideChanged$.emit(this._currentSlide);
        this.created$.emit(slider);
        this._runDetectChanges();
        this._isSliderInited = true;
      },
      slideChanged: slider => {
        this._currentSlide = slider.track?.details?.rel;
        this.slideChanged$.emit(this._currentSlide);
        this.updated$.emit(slider);
        this._endPosition = slider.track?.details?.position;
        this._runDetectChanges();
      },
      animationEnded: (slider) => {
        this.animationEnded$.emit(slider);
      },
      dragStarted: (slider) => {
        this._startPosition = slider.track?.details?.position;
        this.setPause(true);
      },
      dragEnded: () => {
        this.setPause(false);
      }
    });
    this._runDetectChanges();
  }

  /**
   * Run slider emitters
   */
  private _runSliderEmitters() {
    if (this._slider) {
      this._addImgClassLoaded();
      this._visibilityEmiter();
    }
  }

  /**
   * Add class on slide element before slider init
   */
  private _addSlideItemClass() {
    this.track().nativeElement.childNodes.forEach((slide: any) => {
      if (slide.nodeType !== Node.COMMENT_NODE) {
        this._render.addClass(slide, 'keen-slider__slide');
      }
    });
  }

  /**
   * Add image class loaded
   */
  private _addImgClassLoaded() {
    if (this.config.imgLoaded) {
      this.track().nativeElement.querySelectorAll('img')
        .forEach((img: HTMLImageElement) => this._render.addClass(img, 'loaded'));
    }
  }

  /**
   * Run slider autoplay and mouse events
   */
  private _setAutoPlay() {
    this._resetInterval();
    this._interval = setInterval(() => {
      if (!this._pause) {
        this.slider.next();
      }
    }, this.config.delay);
  }

  /**
   * Set slider autoplay state
   * @param active
   */
  public setPause(active: boolean) {
    if (this.config.autoplay) {
      this._pause = active;
      this._setAutoPlay();
    }
  }

  /**
   * Reset slider interval
   */
  private _resetInterval() {
    clearInterval(this._interval);
  }

  /**
   * If current tab in browser not active, it set pause slider autoplay
   */
  private _visibilityEmiter() {
    if (this.config.autoplay) {
      this._pageIsVisible$ = this._visibility.pageIsVisible$.subscribe(e => {
        e ? this.setPause(false) : this.setPause(true);
      });
    }
  }

  /**
   * Destroy slider
   */
  private _destroySlider() {
    if (this._platform.isBrowser) {
      if (this._pageIsVisible$) {
        this._pageIsVisible$.unsubscribe();
      }
      if (this._slider) {
        this._slider.destroy();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'slideList': {
            setTimeout(() => {
              if (this._slider) {
                this._destroySlider();
                this._addSlideItemClass();
                this._initSlider();
                this._runSliderEmitters();
              }
            }, 100);
          }
        }
      }
    }
  }

  /**
   * Run manual detect changes
   */
  private _runDetectChanges() {
    try {
      const track = this.track();
      if (this._slider || track) {
        this._cd.detectChanges();
        track.nativeElement.click();
      }
    } catch (error) {

    }
  }

  /**
   * The private `_langDirectionChangeListener` function listens for language changes and updates a
   * slider based on the language direction.
   */
  private _langDirectionChangeListener() {
    this._langDirectionChangeListener$ = this._lang.langChange$.pipe(
      filter((data) => !!data),
      tap((data) => {
        if (this.arabic.arLocales.includes(this._lang?.previous)) {
          this._updateSlider(100);
        } else if (this.arabic.isAr) {
          this._updateSlider(100);
        }
      })
    ).subscribe();
  }

  private _updateSlider(timeout) {
    setTimeout(() => {
      this._destroySlider();
      this.config = {...this._defaultConfig, ...this.config, rtl: this.arabic.isAr};
      this._initSlider();
    }, timeout);
  }

  ngOnDestroy() {
    this._destroySlider();
    if (this._langDirectionChangeListener$) {
      this._langDirectionChangeListener$.unsubscribe();
    }
  }

}
