import { Component, OnInit, inject, output } from '@angular/core';
import {LoginFormController} from '../../helpers/user/login-form-controller';
import {ModalService} from '../../modal-v2/modal.service';
import {ModalsFromUrlService} from '../../modal-v2/modals-from-url.service';
import {GoogleTagManagerService} from '../../services/google-tag-manager.service';
import {LocalHistoryService} from '../../services/local-history.service';
import {BodyService} from '../../services/body.service';
import {InstallAppService} from '../../services/install-app.service';
import {ModalsFromUrlList} from '../../modal-v2/modals-from-url-list';
import {LanguageService} from '../../services/language/language.service';
import { Router, RouterLink } from "@angular/router";
import { TranslatePipe } from '../../shared/translation/translate.pipe';
import { RouterLinkDirective } from '../../shared/directives/router-link.directive';
import { SetImgAltDirective } from '../../shared/directives/set-img-alt.directive';
import { SocialAuthComponent } from '../../shared/components/social-auth/social-auth.component';
import { BtnComponent } from '../../shared/components/btn/btn.component';
import { PasswordShowDirective } from '../../shared/directives/password-show.directive';
import { NgClass } from '@angular/common';
import { SetValueAutofillDirective } from '../../shared/directives/set-input-value-ios.directive';
import { FormControlsModule } from '../form-controls/form-controls.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss'],
    imports: [FormsModule, ReactiveFormsModule, FormControlsModule, SetValueAutofillDirective, NgClass, PasswordShowDirective, BtnComponent, SocialAuthComponent, SetImgAltDirective, RouterLinkDirective, RouterLink, TranslatePipe]
})
export class LoginFormComponent implements OnInit {
  private _modal = inject(ModalService);
  private _modalsFromUrl = inject(ModalsFromUrlService);
  private _gmt = inject(GoogleTagManagerService);
  private _history = inject(LocalHistoryService);
  private _router = inject(Router);
  body = inject(BodyService);
  installApp = inject(InstallAppService);
  lang = inject(LanguageService);


  public readonly close$ = output<boolean>();

  /**
   * Controller for current form
   */
  public controller: LoginFormController = new LoginFormController();

  ngOnInit() {
  }

 async openRestorePasswordModal() {
    this.close$.emit(true);
    const component = await import('../../modal-v2/components/lazy/restoration-instruction-form/restoration-instruction-form.component');
    await this._modal.openLazy(component?.RestorationInstructionFormComponent, {
      template: 'BORDER_IMAGE'
    });
  }

  openRegistrationPopop() {
    this._gmt.registerLoginFormrClick('register_login_form', 'login_form');
    this.close$.emit(true);
    this._router.navigateByUrl('/?modal=' + ModalsFromUrlList.REGISTRATION);
  }

  onClose() {
    this._history.removeUrl('before-login');
    this.close$.emit(true);
  }
}
