import { Injectable, inject } from '@angular/core';
import { SsApiService } from '../api/ss-api.service';
import { CommonDataService } from '../common-data.service';
import { forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, filter, first, map, switchMap, tap } from 'rxjs/operators';
import { UserService } from './user.service';
import { GroupsService, UserGroup } from '../groups.service';
import { LoyaltyStatusesCommon, LoyaltyStatusesNinja, LoyaltyStatusesSamurai, SubStatus } from '../../helpers/loyalty-statuses';
import { ModalService } from '../../modal-v2/modal.service';

@Injectable({
  providedIn: 'root'
})
export class UserCompPointsService {
  private _api = inject(SsApiService);
  private _data = inject(CommonDataService);
  private _user = inject(UserService);
  private _groups = inject(GroupsService);
  private _modals = inject(ModalService);


  /**
   *  User comp points data
   */
  private _compPointsData: any = {};

  /**
   * User status data
   */
  private _userStatusData: any = {};

  /**
   * Emits when status resolved and ready to use
   */
  private _statusResolved$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor() {
    this.updateCompPointsData();
  }

  /**
   * Access to private properties
   */
  get status() { return this._userStatusData; }
  get points() { return this._compPointsData; }
  get statusResolved$() { return this._statusResolved$; }

  /**
   * Open exchange points modal
   */
  public async openExchangePointsModal() {
    const component = await import('../../../core/modal-v2/components/lazy/exchange-points/exchange-points.component');
    await this._modals.openLazy(component?.ExchangePointsComponent, {
      template: 'CLEAR'
    });
  }
  /**
   * Returns user comp points data
   */
  getCompPoints(): Observable<any> {
    return this._api.playerCompPoints().pipe(
      map(compPoints => ({
        ...compPoints,
        chargeable: compPoints.chargeable && compPoints.chargeable.points || 0,
        persistent: compPoints.persistent && compPoints.persistent.points || 0
      })),
      catchError(error => of({}))
    );
  }

  /**
   * Returns statuses list
   */
  getStatuses(): Observable<any> {
    return this._api.playerStatusGroups().pipe(
      catchError(error => of([]))
    );
  }

  /**
   * Returns money exchange rate for comp points
   */
  getMoneyExchangeRate(): Observable<any> {
    return this._api.compPointsRatesMoney().pipe(
      map(rateList => rateList[0]),
      catchError(error => of(null))
    );
  }

  /**
   * Exchange comp points to money
   *
   * @param data
   */
  exchangeToMoney(data: object): Observable<any> {
    return this._api.compPointsExchangeMoney({
      exchange: data
    });
  }

  /**
   * Update users comp points and statuses
   */
  updateCompPointsData() {
    this._user.auth$.pipe(
      filter(auth => auth),
      first(),
      switchMap(() => forkJoin([
        this.getCompPoints(),
        this.getStatuses()
      ])),
      tap(([points, statusList]) => {
        const ninjaStatuses = [...LoyaltyStatusesCommon, ...LoyaltyStatusesNinja];
        const samuraiStatuses = [...LoyaltyStatusesCommon, ...LoyaltyStatusesSamurai];

        const statusListAccordingToUserGroup = statusList.filter(status => {
          return this._groups.loyaltyGroup === UserGroup.NINJA
            ? ninjaStatuses.some(ninjaStatus => ninjaStatus.id === status.id)
            : samuraiStatuses.some(samuraiStatus => samuraiStatus.id === status.id);
        });

        const currentStatus = (this._user.info.statuses || []).find(status => !!statusListAccordingToUserGroup.find(statusFromList => statusFromList.id === status.id));

        this._compPointsData = points;
        this._userStatusData = this._getCurrentStatusData(currentStatus, statusListAccordingToUserGroup);
        this._statusResolved$.next(this._userStatusData);
      })
    ).subscribe();
  }

  /**
   * Returns data for current user status
   *
   * @param currentStatus
   * @param statusList
   * @private
   */
  private _getCurrentStatusData(currentStatus: any, statusList: Array<any>): any {
    if (currentStatus) {
      const allStatusesList = [...LoyaltyStatusesCommon, ...LoyaltyStatusesSamurai, ...LoyaltyStatusesNinja];
      const currentStatusExtraData = allStatusesList.find(status => status.id === currentStatus.id);
      const level = statusList.find(status => status.id === currentStatus.id);
      const pcp = level.conditions[0].persistent_comp_points;
      const total = pcp.max - pcp.min;
      const current = this._compPointsData.persistent - pcp.min;

      const currentStatusFullData = {
        ...currentStatusExtraData,
        level,
        total,
        current,
        subStatusNum: this._resolveSubStatus(currentStatusExtraData.subStatuses, this.points.persistent),
        current_percent: Math.floor(current / (total / 100)),
        left_percent: 100 - Math.floor(current / (total / 100)),
        left: total - current,
      };

      return currentStatusFullData;
    } else {
      return {
        name: LoyaltyStatusesCommon[0].name,
        image_active: LoyaltyStatusesCommon[0].image_active,
      };
    }
  }

  /**
   * Returns sub status
   *
   * @param subStatusList
   * @param points
   * @private
   */
  private _resolveSubStatus(subStatusList: SubStatus[], points: number): number {
    if (!subStatusList.length) { return -1; }
    if (subStatusList[subStatusList.length - 1].points <= points) { return subStatusList.length - 1; }
    if (subStatusList[0].points > points) { return -1; }

    return subStatusList.findIndex(status => status.points > points) - 1;
  }
}
