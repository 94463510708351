import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { combineLatest, EMPTY, forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { first, map, share, shareReplay, switchMap } from 'rxjs/operators';
import { ITournamentPlayersInTeams } from './group-tournaments/base-group-tournament.interface';
import { CmsApiService } from '../../core/services/api/cms-api.service';
import { CmsContentMapperService } from '../../core/services/cms-content-mapper.service';
import { TimelineStatus, TimeService, TimeStatus, TypeTimeStatus } from '../../core/services/time.service';
import { SsApiService } from '../../core/services/api/ss-api.service';
import { GamesService } from '../../core/services/games/games.service';
import { ArrayService } from '../../core/services/array.service';
import { CommonDataService } from '../../core/services/common-data.service';
import { UserService, UserSides } from '../../core/services/user/user.service';
import { TranslationService } from '../../core/shared/translation/translation.service';
import { BreakpointsDetectorService } from '../../core/services/breakpoints-detector.service';
import { ResponseHandlerService } from '../../core/services/response-handler.service';
import { UpdateOnLangChange } from '../../core/shared/decorators/update-on-lang-change';
import { isArray, isNullOrUndefined } from '../../core/helpers/utils';
import { ApiProvider } from '../../core/services/api/helpers/api-provider';
import { ITournament } from './tournaments.interface';

export interface ProviderLeaders {
  place: number;
  name: string;
  prize: number;
  currency: string;
  score?: number;
  type?: number;
}

export enum TournamentStrategies {
  BET = 'bet',
  WIN = 'win'
}

@Injectable({
  providedIn: 'root'
})
export class TournamentsService {
  private _api = inject(CmsApiService);
  private _mapper = inject(CmsContentMapperService);
  private _time = inject(TimeService);
  private _ssApi = inject(SsApiService);
  private _games = inject(GamesService);
  private _array = inject(ArrayService);
  private _data = inject(CommonDataService);
  private _http = inject(HttpClient);
  private _user = inject(UserService);
  private _translate = inject(TranslationService);
  private _breakpoint = inject(BreakpointsDetectorService);
  private _responseHandler = inject(ResponseHandlerService);


  /**
   * Get promotions list from CMS and prepare for using in frontend
   *
   * @param params
   */
  @UpdateOnLangChange('tournament/list')
  list(params: object = {}): ReplaySubject<any> {
    return this._api.tournamentList(params).pipe(
      map(response => this._mapper.mapCmsData(response.data.list, {
        id: 'id',
        slug: 'slug',
        startAt: 'startAt.date',
        endAt: 'endAt.date',
        publishAt: 'publishAt',
        unpublishAt: 'unpublishAt',
        gameList: 'gameList',
        isMultiTournament: 'isMultiTournament',
        identifier: 'identifier',
      })),
      map((list: ITournament[]) => list.filter(item => !isNullOrUndefined(item.gameList) && item?.gameList?.length)),
      map((list: ITournament[]) => this._mapTournamentTopPlayers(list)),
      switchMap(list => this._resolveMultiTournamentList(list)),
      map(list => list.sort((a, b) => a.endAt - b.endAt)),
      map(list => {
        return {
          now: list.filter(item => item.tournamentTimeStatus === TimelineStatus.NOW),
          future: list.filter(item => item.tournamentTimeStatus === TimelineStatus.FUTURE),
          past: list.filter(item => item.tournamentTimeStatus === TimelineStatus.PAST),
        };
      }),
    ) as ReplaySubject<any>;
  }

  /**
   * Get promotions item from CMS and prepare for using in frontend
   *
   * @param params
   */
  @UpdateOnLangChange('tournament/item')
  item(params: object = {}): ReplaySubject<any> {
    return this._api.tournamentItem(params).pipe(
      map(response => {
        if (response.statusCode === 200) {
          return this._mapper.mapCmsData(response.data ? [response.data] : [], {
            id: 'id',
            slug: 'slug',
            startAt: 'startAt.date',
            endAt: 'endAt.date',
            identifier: 'identifier',
            gameList: 'gameList',
            isMultiTournament: 'isMultiTournament',
            prevIdentifier: 'prevIdentifier',
            unpublishAt: 'unpublishAt',
            publishAt: 'publishAt'
          })[0];
        } else {
          this._responseHandler.handleResponseStatus(response.statusCode, '/tournaments');
          return null;
        }
      }),
      switchMap((tournament: any) => {
        return tournament.isMultiTournament ? this._resolveMultiTournament(tournament) : of(this._mapTournamentList([tournament])[0]);
      })
    ) as ReplaySubject<any>;
  }

  /**
   * Map additional data for multi tournaments list
   * @returns
   * @param tournamentList
   */
  private _resolveMultiTournamentList(tournamentList: any[]): Observable<any> {
    return this._ssApi.tournamentsAll().pipe(
      map((tournamentSSList: any[]) => {
        return tournamentList.map(tournament => {
          tournament.activeMultiTournament = tournamentSSList.filter(e => e.in_progress).find(e => Number(e.frontend_identifier) === tournament.id);
          if (tournament.isMultiTournament && tournament.activeMultiTournament) {
            this._resolveTimeForMultiTournament(tournament);
            const groupGameSlideCount = !this._breakpoint.isMobile ? 5 : 4;
            tournament.isGroupTournament = tournament?.slug?.includes('group-tournament');
            tournament.gameListPreview = this._array.toChunks(this._games.gameListMapper(this._array.checkAndCloneArray(tournament.gameList ? tournament.gameList : [], tournament?.gameList.length > 10 ? groupGameSlideCount : 1)), groupGameSlideCount);
            tournament.userStatus$ = this._user.auth && (tournament.identifier || tournament?.activeMultiTournament?.id) ? this._ssApi.tournamentsStatus(tournament?.activeMultiTournament?.id || tournament?.identifier).pipe(
              map(data => Object.keys(data)?.length ? data : null)
            ) : of(null);
            return tournament;
          } else {
            return this._mapTournamentList([tournament])[0];
          }
        });
      })
    );
  }

  private _resolveTimeForMultiTournament(tournament) {
    tournament.startAt = this._time.toLocalDate(tournament.activeMultiTournament ? tournament.activeMultiTournament.start_at : tournament.startAt);
    tournament.endAt = this._time.toLocalDate(tournament.activeMultiTournament ? tournament.activeMultiTournament.end_at : tournament.endAt);
    tournament.tournamentTimeStatus = this._time.resolveTimeStatus(tournament.startAt, tournament.endAt, TypeTimeStatus.TOURNAMENT);
  }

  /**
   * Map additional data for multi tournaments
   * @param tournament
   * @returns
   */
  private _resolveMultiTournament(tournament): Observable<any> {
    return this._ssApi.tournamentsAll().pipe(
      map((list: any[]) => {

        tournament.activeMultiTournament = [list
          .filter(e => e.in_progress)
          .find(e => e.frontend_identifier === String(tournament.id))]
          .map(e => this._mapSsTournament(e))[0];

        tournament.prevMultiTournament$ = (tournament.activeMultiTournament && tournament.activeMultiTournament.id) ?
          this._ssApi.tournaments(String(Number(tournament.activeMultiTournament.id) - 1)).pipe(
            map(res => this._mapSsTournament(res))
          ) : EMPTY;

        const startAt = this._time.toLocalDate(tournament.activeMultiTournament ?
          tournament.activeMultiTournament.start_at :
          tournament.startAt);
        const endAt = this._time.toLocalDate(tournament.activeMultiTournament ?
          tournament.activeMultiTournament.end_at :
          tournament.endAt);
        tournament.startAt = this._time.timeDiff(startAt);
        tournament.endAt = this._time.timeDiff(endAt);

        tournament.status = this._resolveTournamentStatus(tournament.startAt, tournament.endAt);
        const gameList = tournament.gameList;
        tournament.gameListAll = this._games.gameListMapper(gameList);

        let gameListToChunk = [];
        if (tournament.gameList && tournament.gameList.length > 5) {
          gameListToChunk = this._games.gameListMapper(
            this._array.checkAndCloneArray(
              tournament.gameList
                ? tournament.gameList
                : [],
              !this._breakpoint.isMobile ? 5 : 4)
            );
        } else if (tournament.gameList && tournament.gameList.length <= 5) {
          gameListToChunk = this._games.gameListMapper(tournament.gameList);
        }

        tournament.gameList = this._array.toChunks(gameListToChunk, !this._breakpoint.isMobile ? 5 : 4) ;

        // Group tournament logic start
        const isGroupTournament = tournament.slug.includes('group-tournament');
        const groupGameSlideCount = !this._breakpoint.isMobile ? 5 : 4;
        tournament.isGroupTournament = isGroupTournament;
        tournament.tournamentTeams$ = this._getTournamentTeam(isGroupTournament, tournament, startAt, endAt);
        tournament.userSide$ = isGroupTournament && this._resolveUserSide(tournament?.activeMultiTournament?.id || tournament?.identifier);
        tournament.userStatus$ = this._user.auth && (tournament.identifier || tournament?.activeMultiTournament?.id) ? this._ssApi.tournamentsStatus(tournament?.activeMultiTournament?.id || tournament?.identifier) : of(null);
        tournament.gameListGroup =  this._array.toChunks(this._games.gameListMapper(this._array.checkAndCloneArray(gameList ? gameList : [], groupGameSlideCount)), groupGameSlideCount);
        // Group tournament logic end

        return tournament;
      })
    );
  }

  /**
   * Get url with data from cms for leaders by provider tournament
   * @param {string} url
   * @returns {Observable<Object>}
   * @private
   */
  getLeaderBoardData(url: string): Observable<any> {
    return this._http.get(`${url}&currency=${this._user.info.currency}`);
  }

  /**
   * Map additional data for tournaments
   *
   * @param list
   * @private
   */
  private _mapTournamentList(list: Array<any>): Array<any> {
    if (!isArray(list)) {
      return null;
    }

    return list.map(tournament => {
      const startAt = this._time.toLocalDate(tournament.startAt);
      const endAt = this._time.toLocalDate(tournament.endAt);
      const isGroupTournament = tournament.slug.includes('group-tournament');
      const groupGameSlideCount = !this._breakpoint.isMobile && !this._breakpoint.isTablet ? 6 : 4;

      return {
        ...tournament,
        startAt: this._time.timeDiff(startAt),
        endAt: this._time.timeDiff(endAt),
        originalEndDate: this._time.toLocalDate(tournament.endAt),
        status: this._time.resolveTimeStatus(startAt, endAt),
        isGroupTournament,
        tournamentTeams$: this._getTournamentTeam(isGroupTournament, tournament, startAt, endAt),
        userSide$: isGroupTournament && this._resolveUserSide(tournament.identifier),
        data$: this._user.auth && tournament.identifier ? this._ssApi.tournaments(tournament.identifier).pipe(
          map(response => this._mapSsTournament(response)), share()) : of(null),
        userStatus$: this._user.auth && tournament.identifier ? combineLatest([
          this._ssApi.tournamentsStatus(tournament.identifier),
          this._ssApi.tournaments(tournament.identifier).pipe(
            map(response => this._mapSsTournament(response)), share())
        ]).pipe(
          map(([status, tournamentData]) => this._resolveUserStatus(status, tournamentData, this._data.currencySymbol(tournament?.currency))),
          share()
        ) : of(null),
        strategy$: this._user.auth && tournament.identifier ? this._ssApi.tournaments(tournament.identifier).pipe(
          map(response => response?.strategy), share()
        ) : of(tournament?.Strategy),
        gameListAll: this._games.gameListMapper(tournament?.gameList),
        gameList: this._array.toChunks(this._games.gameListMapper(this._array.checkAndCloneArray(tournament.gameList ? tournament.gameList : [],  tournament.gameList.length <= 5 ? 1 : 5)), this._breakpoint.belowSmallDesktop ? 4 : 5),
        gameListPreview: this._array.toChunks(this._games.gameListMapper(this._array.checkAndCloneArray(tournament.gameList ? tournament.gameList : [], tournament?.gameList.length > 10 ? groupGameSlideCount : 1)), groupGameSlideCount),
        tournamentTimeStatus: this._time.resolveTimeStatus(startAt, endAt, TypeTimeStatus.TOURNAMENT),
        Vip: tournament?.Vip === '1' || tournament?.slug?.includes('-vip') || tournament?.slug?.includes('vip-')
      };
    });
  }

  /**
   * Returns tournament status from provided dates
   * @param start
   * @param end
   * @private
   */
  private _resolveTournamentStatus(start: Date, end: Date): TimeStatus {
    const currentDate = new Date();

    if (currentDate < start) {
      return TimeStatus.UPCOMING;
    } else if (currentDate > end) {
      return TimeStatus.CLOSED;
    } else {
      return TimeStatus.ACTIVE;
    }
  }


  /**
   * Prepare tournament response from SS for using in frontend
   *
   * @param tournament
   * @private
   */
  private _mapSsTournament(tournament: any): any {
    return {
      ...tournament,
      currency_symbol: this._data.currencySymbol(tournament.currency),
      mappedPrizes: this._mapPrizesList(tournament.prizes || [], tournament.top_players || [], tournament?.currency_symbol || this._data.currencySymbol(tournament?.currency))
    };
  }

  /**
   * Combine places with same prizes
   *
   * @param list
   * @private
   */
  private _mapPrizesList(prizes: any[], topPlayers: any[], currencySymbol: string): any[] {
    const result = [];

    prizes.forEach((prize, i) => {
      const topPlayer = topPlayers[i];
      const resolvedPrize = {
        place: topPlayer?.award_place,
        points: topPlayer?.bet_cents,
        username: topPlayer?.nickname,
        prize: prize?.freespins_count ? `${prize?.freespins_count} FS` : prize?.money_award ? `${currencySymbol}${prize?.money_award}` : null
      }

      result.push(resolvedPrize);
    });

    return result;
  }

  /**
   * Mapped winners and set current user position
   * @param data
   * @param status
   * @private
   */
  mapTopPlayers(data: any, status: any) {
    if (!data && !status) {
      return;
    }
    const slicedData = data.top_players.slice(0, 10);

    if (!status.award_place) {
      return slicedData;
    }

    let players = [];

    if (!slicedData.some(player => player.award_place === status.award_place)) {
      status.isCurrentUser = true;
      players = [...slicedData, status];
    } else {
      slicedData.forEach((player => {
        if (player.award_place === status.award_place) {
          player.isCurrentUser = true;
        }
      }));
      players = [...slicedData];
    }
    return players.sort((a, b) => a.award_place - b.award_place);
  }

  /**
   *
   * @param leaderboardUrl
   * @param tournamentId
   * @param data
   * @param params
   */
  public getTopPlayersBooongo(leaderboardUrl: string, tournamentId: string, data: object = { grouped_prizes: true, size: -1 }, params: object = {}): ReplaySubject<any> {
    const leaderboardApi = this._createLeaderboardApi(leaderboardUrl);
    return leaderboardApi.post(`/${tournamentId}/api/public/tournament/get/`, data, { params }).pipe(
      map(response => {
        return response.leaderboard.map((player, index) => {
          return {
            award_place: player.place,
            nickname: `${this._translate.translate('t.player')} ${player.nick}`,
            score: Number(player.score).toFixed(2)
          };
        });
      }),
      shareReplay(1)
    ) as ReplaySubject<any>;
  }

  /**
   * Get prize for Booongo leaderboard
   * @param prizes
   * @param playerPlace
   * @private
   */
  private _getPrizesForBooongoLeaderboard(prizes: any[], playerPlace: number): number {
    const prize = prizes.find(prize => {
      if (prize.place.includes('-')) {
        const places = prize.place.split('-');

        return playerPlace >= Number(places[0]) && playerPlace <= Number(places[1]);
      } else {
        return prize.place == playerPlace;
      }
    })

    return prize ? Number(prize.prize.value) / 100 : 100;
  }

  /**
   * Create Api for get leaderboard
   * @param leaderboardUrl
   * @private
   */
  private _createLeaderboardApi(leaderboardUrl: string): ApiProvider {
    return new ApiProvider(
      {
        defaultOptions: {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
        },
        host: leaderboardUrl
      }
    );
  }

  private _getTournamentTeam(isGroupTournament, tournament, startAt, endAt) {
    return this._ssApi.tournamentsTeams(isGroupTournament && (tournament?.isMultiTournament ? tournament?.activeMultiTournament?.id : tournament.identifier)).pipe(
      map((response) => this._calcDifferenceBetweenTeams(response)),
      shareReplay(1)
    );
  }

  private _calcDifferenceBetweenTeams(arr): ITournamentPlayersInTeams {
    const teamA = arr[0];
    const teamB = arr[1];
    const teamAPoints = arr[0].bet_cents || 1;
    const teamBPoints = arr[1].bet_cents || 1;
    const allPoints = teamAPoints + teamBPoints;
    let winnerTeam = UserSides.NINJA;

    if (teamAPoints > teamBPoints) {
      winnerTeam = UserSides.SAMURAI;
    }

    let percentageA = (teamAPoints / allPoints) * 100;
    let percentageB = (teamBPoints / allPoints) * 100;


    // to cover different hard cases for example: when every team have zero points, we can`t divide by zero.
    // if (percentage >= 95) {
    //   percentage = 90;
    // } else if (!percentage || teamAPoints === teamBPoints) {
    //   percentage = 50;
    // } else if (percentage <= 5) {
    //   percentage = 10;
    // }

    return {
      teamDiffA: percentageA,
      teamDiffB: percentageB,
      winnerTeam,
      samuraiTeam: teamA,
      ninjaTeam: teamB,
    };
  }

  private _resolveUserSide(id) {
    if (this._user.auth) {
      return forkJoin([this._ssApi.tournamentsTeams(id), this._ssApi.tournamentsStatus(id)]).pipe(
        first(),
        map(([teams, status]) => {
          if (status.tournament_team_id === teams[0].id) {
            return UserSides.SAMURAI;
          } else {
            return UserSides.NINJA;
          }
        }),
      );
    } else {
      return of(UserSides.NOT_CHOOSEN);
    }
  }

  /**
   * Map top players stream
   * @param list
   * @private
   */
  private _mapTournamentTopPlayers(list: ITournament[]) {
    return list.map(item => {
      return {
        ...item,
        topPlayers$: this._resolveTopPlayers$(item)
      };
    });
  }

  /**
   * Resolve top players stream
   * @param tournament
   * @private
   */
  private _resolveTopPlayers$(tournament: ITournament) {
    return combineLatest([tournament?.prizesData$, tournament?.tournamentUserStatus$]).pipe(
      map(([data, status]) => this.mapTopPlayers(data, status)?.length ?
        this.mapTopPlayers(data, status) : of([])), share());
  }

  /**
   * Resolve user status
   * @param status
   * @param tournamentData
   * @private
   */
  private _resolveUserStatus(status, tournamentData, currencySymbol) {
    const userWinnerIndex = tournamentData?.top_players?.findIndex(item => item?.award_place === status?.award_place);
    const nextWinnerUser = tournamentData?.top_players?.[userWinnerIndex - 1];
    const pointsToPrize = nextWinnerUser?.points - status?.points;
    return {
      award_place: status?.award_place,
      points: status?.points,
      prize: status?.wins ? `${currencySymbol} ${status?.wins}` : null,
      pointsToPrize
    };
  }
}

