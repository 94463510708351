import { ErrorHandler, inject, Injectable } from '@angular/core';
import { Hooks, register } from 'register-service-worker';
import { PlatformService } from './platform.service';
import { GlobalEventsService } from './global-events.service';
import { AnimatedFaviconService } from './animated-favicon.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceWorkerService {
  private _platform = inject(PlatformService);
  private _events = inject(GlobalEventsService);
  private _favIcon = inject(AnimatedFaviconService);
  private _errorHander = inject(ErrorHandler);


  private _deferredPrompt = null;
  private _isEnabled = null;

  constructor() {
    this._beforeInstallPrompt();
  }

  get pwaEnabled() {
    return this._isEnabled;
  }

  public registerServiceWorker(): void {
    if (!this._platform.isBrowser) { return; }
    const serviceWorkerPath = `/sw.js`;
    const options: Hooks = {
      registrationOptions: { scope: './' },
      ready: (registration) => {
        console.log('Service worker is active.');
      },
      registered: (registration) => {
      },
      cached: (registration) => {
      },
      updatefound: (registration) => {
      },
      updated: (registration) => {},
      offline: () => {
        this._errorHander.handleError('No internet connection found. App is running in offline mode.');
      },
      error: (error) => {
        this._errorHander.handleError(error);
      }
    };
    register(serviceWorkerPath, options);
  }

  private _beforeInstallPrompt() {
    if (this._platform.isBrowser) {
      this._events.beforeinstallprompt$.subscribe((e: any) => {
          e.preventDefault();
          this._deferredPrompt = e;
          this._isEnabled = true;
        }
      );
    }
  }

  /**
   * Install PWA app
   */
  public addAppToHomeScreen() {
    this._deferredPrompt.prompt();
    this._deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
        this._isEnabled = false;
      } else {
        console.log('User dismissed the A2HS prompt');
        this._isEnabled = true;
      }
    });
  }

  public stopGifAnimation() {
    if (this._platform.isBrowser) {
      const serviceWorker = navigator.serviceWorker.controller;
      if (serviceWorker) {
        serviceWorker.postMessage({ type: 'STOP_FAVICON' });
        this._favIcon.stopAnimation();
      }
    }
  }
}
