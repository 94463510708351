import { AfterViewInit, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-unificator/services';
import { AuPaymentRestrictService } from '../../../../services/au-payment-restrict.service';
import { EnvironmentService } from '../../../../services/environment.service';
import { ToastMessageService, ToastMessageType } from '../../toast-message.service';
import { TranslatePipe } from '../../../../shared/translation/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { LanguageService } from '../../../../services/language/language.service';

@Component({
    selector: 'app-cookie-message-content',
    templateUrl: './cookie-message-content.component.html',
    styleUrls: ['./cookie-message-content.component.scss'],
    imports: [AsyncPipe, TranslatePipe]
})
export class CookieMessageContentComponent implements AfterViewInit {
  private _router = inject(Router);
  private _cookies = inject(CookieService);
  private _env = inject(EnvironmentService);
  private _toasts = inject(ToastMessageService);
  private _lang = inject(LanguageService);
  au = inject(AuPaymentRestrictService);


  ngAfterViewInit() {
  }

  async goToPolicy() {
    if (this._env.env.country.isRestricted) {
      return;
    }
    await this._router.navigate(['/', this._lang.current, 'cookie-policy']);
  }

  cookiesAccepted() {
    this._cookies.set('isCookiesAccepted', 'true', 999, '/');
    const message = this._toasts.messagesList?.find(m => m.type === ToastMessageType.COOKIE);
    if (message) {
      this._toasts.removeMessage(message);
    }
  }
}
