import { Injectable, inject } from '@angular/core';
import {Observable, of, timer} from 'rxjs';
import {map} from 'rxjs/operators';
import {PlatformService} from './platform.service';

/**
 * Available tournament statuses
 */
export enum TimeStatus {
  UPCOMING = 'upcoming',
  ACTIVE = 'active',
  CLOSED = 'closed'
}

/**
 * Available tournament statuses
 */
export enum TimelineStatus {
  FUTURE = 'future',
  NOW = 'now',
  PAST = 'past'
}

export enum TypeTimeStatus {
  TOURNAMENT = 'tournament'
}

@Injectable({
  providedIn: 'root'
})
export class TimeService {
  private _platform = inject(PlatformService);


  /**
   * Convert provided date string to local date object
   *
   * @param date
   */
  public toLocalDate(date?: string): Date {
    if (date) {
      const dateNumbers = date.split(/\D/);
      return new Date(Date.UTC(
        parseInt(dateNumbers[0], 10) || 0,
        parseInt(dateNumbers[1], 10) - 1 || 0,
        parseInt(dateNumbers[2], 10) || 0,
        parseInt(dateNumbers[3], 10) || 0,
        parseInt(dateNumbers[4], 10) || 0,
        parseInt(dateNumbers[5], 10) || 0,
        parseInt(dateNumbers[6], 10) || 0
      ));
    } else {
      return new Date();
    }
  }

  public parseDate(date) {
    const values = date.split(/[^0-9]/);
    const year = parseInt(values[0], 10);
    const month = parseInt(values[1], 10) - 1;
    const day = parseInt(values[2], 10);
    const hours = parseInt(values[3], 10);
    const minutes = parseInt(values[4], 10);
    const seconds = parseInt(values[5], 10);
    return new Date(year, month, day, hours, minutes, seconds);
  }

  /**
   * Returns diff object between current and provided time
   *
   * @param targetTime
   */
  public timeDiff(targetTime: Date) {
    const currentDate = new Date();
    const dateDiff = Math.abs(targetTime.getTime() - currentDate.getTime());
    const days = Math.floor(dateDiff / (1000 * 3600 * 24));
    const hours = Math.floor(dateDiff / (1000 * 3600) - days * 24);
    const minutes = Math.floor(((dateDiff % (1000 * 3600 * 24)) % (1000 * 3600)) / (1000 * 60));
    const seconds = Math.floor(((dateDiff % 60000) / 1000));
    return {
      days,
      hours,
      minutes,
      seconds,
      daysLabel: this.pluralizeDateLabel(days, 'labl.day'),
      hoursLabel: this.pluralizeDateLabel(hours, 'labl.hr'),
      minLabel: this.pluralizeDateLabel(minutes, 'labl.min'),
      secLabel: this.pluralizeDateLabel(minutes, 'labl.sec'),
    };
  }

  /**
   * Pluralise label string depend on provided number
   *
   * @param num
   * @param label
   */
  public pluralizeDateLabel(num: number, label: string) {
    return num > 1 ? label + 's' : label;
  }

  public currentDate$(): Observable<any> {
    if (this._platform.isBrowser) {
      const interval$ = timer(0, 1000);
      return interval$.pipe(
        map(() => {
          const now = new Date();
          const min = (now.getMinutes() < 10 ? '0' : '') + now.getMinutes(); // minutes
          const h = (now.getHours() < 10 ? '0' : '') + now.getHours(); // hours
          return {min, h};
        })
      );
    } else {
      return of({min: '', h: ''});
    }
  }

  /**
   * Returns tournament status from provided dates
   *
   * @param start
   * @param end
   * @param type
   * @private
   */
  public resolveTimeStatus(start: Date, end: Date, type?: TypeTimeStatus): TimeStatus | TimelineStatus {

    const currentDate = new Date();

    if (type === TypeTimeStatus.TOURNAMENT) {
      if (currentDate < start) {
        return TimelineStatus.FUTURE;
      } else if (currentDate > end) {
        return TimelineStatus.PAST;
      } else {
        return TimelineStatus.NOW;
      }
    }

    if (currentDate < start) {
      return TimeStatus.UPCOMING;
    } else if (currentDate > end) {
      return TimeStatus.CLOSED;
    } else {
      return TimeStatus.ACTIVE;
    }
  }

  /**
   * Detect end of day
   */
  public detectEndOfDay(): string {
    const end = new Date();
    end.setHours(23, 59, 59, 999);
    return end.toISOString();
  }


  /**
   * Check if is it today date
   *
   * @param date
   */
  public isTodayDate(date: Date): boolean {
    const currentDate = new Date();
    const currentDataUTC = new Date(currentDate.getTime() + currentDate.getTimezoneOffset() * 60000);
    const dateToCompareUTC  = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

    return currentDataUTC.toDateString() === dateToCompareUTC.toDateString();
  }

  /**
   * Convert date to timestamp
   * @param strDate
   * @private
   */
  public dateToTimestamp(strDate) {
    return Date.parse(strDate) / 1000;
  }

  /**
   * Check UTC bonus format dates
   * @param createdAt
   * @private
   */
  public areSameUTCDates(createdAt) {
    return new Date().getUTCDay() === new Date(createdAt).getUTCDay() &&
      new Date().getUTCMonth() === new Date(createdAt).getUTCMonth() &&
      new Date().getUTCFullYear() === new Date(createdAt).getUTCFullYear();
  }
}
