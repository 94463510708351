import { Directive, TemplateRef, ViewContainerRef, OnInit, inject } from '@angular/core';
import {PlatformService} from '../../../services/platform.service';

@Directive({
    selector: '[appShellNoSSR]',

})
export class AppShellNoSSRDirective implements OnInit {
  private _viewContainer = inject(ViewContainerRef);
  private _templateRef = inject<TemplateRef<any>>(TemplateRef);
  private _platform = inject(PlatformService);


  ngOnInit() {
      if (this._platform.isBrowser) {
          this._viewContainer.createEmbeddedView(this._templateRef);
      } else {
          this._viewContainer.clear();
      }
  }
}
