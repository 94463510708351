import { Injectable, inject } from '@angular/core';
import { CmsApiService } from './api/cms-api.service';
import { ReplaySubject } from 'rxjs';
import { UpdateOnLangChange } from '../shared/decorators/update-on-lang-change';
import { CmsContentMapperService } from './cms-content-mapper.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OffersService {
  private _api = inject(CmsApiService);
  private _mapper = inject(CmsContentMapperService);


  /**
   * Get promotions list from CMS and prepare for using in frontend
   *
   * @param params
   * @param extraParams
   */
  @UpdateOnLangChange('promo/list')
  list(params: object = {}, extraParams = {}): ReplaySubject<any> {
    return this._api.promotionList(params).pipe(
      map(response => this._mapper.mapCmsData(response.data, {
        id: 'id',
        slug: 'slug',
        publishAt: 'publishAt',
        cmsItemName: 'name',
        ...extraParams
      }))
    ) as ReplaySubject<any>;
  }

  /**
   * Get promotions item from CMS and prepare for using in frontend
   *
   * @param params
   */
  @UpdateOnLangChange('promo/item')
  item(params: object = {}): ReplaySubject<any> {
    return this._api.promotionItem(params).pipe(
      map(response => {
        if (response.statusCode === 200) {
          return this._mapper.mapCmsData(response.data, {
            slug: 'slug',
            publishAt: 'publishAt.date',
            unpublishAt: 'unpublishAt.date',
            name: 'name'
          });
        } else {
          return [{
            statusCode: response.statusCode
          }];
        }
      })
    ) as ReplaySubject<any>;
  }
}
