<div class="toast-message-container">
  @for (message of messagesList; track message) {
    <app-toast-message-item [@showHide] class="{{ message.type }}" (click)="onMessageClick(message)">
      @if (message.hasComponent) {
        <ng-container *ngComponentOutlet="message.content"></ng-container>
      }
      @if (!message.hasComponent) {
        {{ message.content }}
      }
    </app-toast-message-item>
  }
</div>
