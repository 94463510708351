import { AfterViewInit, Directive, ElementRef, inject } from '@angular/core';
import {ValidationPatterns} from '../../helpers/validation-patterns';

@Directive({
    selector: '[InputLettersSymbols]',

})
export class InputLettersSymbolsDirective implements AfterViewInit {
  private _el = inject(ElementRef);


  ngAfterViewInit(): void {
    const regEx = ValidationPatterns.lettersAndSpecialSymbols;
    this._el.nativeElement.addEventListener('keypress', (event) => {
      const char = event.key !== undefined ? event.key : String.fromCharCode(event.keyCode);
      if (!regEx.test(char)) {
        event.preventDefault();
      }
    });
  }
}
