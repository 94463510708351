import { AfterViewInit, Component, inject, input, viewChild } from '@angular/core';
import {AccordionDirective} from '../../directives/accordion.directive';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import {filter, tap} from 'rxjs/operators';
import {LanguageService} from '../../../services/language/language.service';

import { TranslatePipe } from '../../translation/translate.pipe';
import { SafePipe } from '../../pipes/safe.pipe';

@UntilDestroy()
@Component({
    selector: 'app-bottom-terms',
    templateUrl: './bottom-terms.component.html',
    styleUrls: ['./bottom-terms.component.scss'],
    imports: [
        TranslatePipe,
        AccordionDirective,
        SafePipe
    ]
})
export class BottomTermsComponent implements AfterViewInit {
  private _lang = inject(LanguageService);

  readonly _accordion = viewChild(AccordionDirective);

  readonly terms = input<any>(undefined);
  readonly accordionTitle = input('labl.t&c');
  readonly closeAfterChangeLang = input<boolean>(undefined);

  ngAfterViewInit() {
    this._lang.langChange$.pipe(
      untilDestroyed(this),
      filter(() => Boolean(this._accordion())),
      tap(() => {
      const _accordion = this._accordion();
      return _accordion.isOpen ? _accordion.close() : null;
    })
    ).subscribe();
  }

  public open() {
    this._accordion().open();
  }
}
