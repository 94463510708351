<span [ngSwitch]="Action()">
  <div class="deposit">
    <div class="container container--redesign" [class.second-step-container]="payment.isSecondDepositStep">
      <div class="deposit__form">
        <ng-container *ngSwitchCase="'deposit'">
          <ng-container *ngTemplateOutlet="deposit"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'cashout'">
          <ng-container *ngTemplateOutlet="cashout"></ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</span>

<ng-template #deposit>
  @if (!payment.isSecondDepositStep) {
    <ng-container *ngTemplateOutlet="buyCrypto"></ng-container>
    <ng-container *ngTemplateOutlet="cancelCashoutProcess"></ng-container>
  } @else {
    <ng-container *ngTemplateOutlet="secondStepDesc"></ng-container>
  }
  <ng-container *ngTemplateOutlet="depositPaymentList"></ng-container>
  <ng-container *ngTemplateOutlet="depositPaymentMethodForm"></ng-container>
  <ng-container *ngTemplateOutlet="submitDepositForm"></ng-container>
  <ng-container *ngTemplateOutlet="mascot"></ng-container>

  @if (!payment.isSecondDepositStep) {
    <ng-container *ngTemplateOutlet="promoEasterBonuses"></ng-container>
    <ng-container *ngTemplateOutlet="depositBonus"></ng-container>
  }
</ng-template>

<ng-template #cashout>
  <ng-container *ngTemplateOutlet="cashoutPaymentList"></ng-container>
  <ng-container *ngTemplateOutlet="cashoutPaymentMethodForm"></ng-container>
  <ng-container *ngTemplateOutlet="submitCashoutForm"></ng-container>
</ng-template>

<ng-template #depositPaymentList>
  @if (!cancelingProcess && paymentList$ | async; as paymentList) {
    @if (!paymentList || !paymentList.length) {
      <p class="no-payments">{{ 't.no-payments-text' | translate }}</p>
    } @else {
      <ng-container *ngTemplateOutlet="payments; context: { paymentList: paymentList }"></ng-container>
    }
  }
</ng-template>

<ng-template #depositPaymentMethodForm>
  @if (!cancelingProcess) {
    <div
      id="payment-form"
      class="payment-form"
      [ngStyle]="{ 'margin-top': '60px' }"
      [ngClass]="{ 'payment-form--crypto': isSelectedPaymentMethodCrypto }"
    >
      <app-payment-form
        [method]="selectedMethod"
        [action]="Action()"
        [isModal]="isModal"
        (submit$)="submitDeposit($event)"
        (switchView$)="switchView($event)"
      ></app-payment-form>
    </div>
  }
</ng-template>

<ng-template #depositBonus>
  @if (!isModal && ActionTypes.Deposit && selectedMethod) {
    <div class="bonus-container">
      <app-deposit-bonus [bonusCode]="bonusCode"></app-deposit-bonus>
    </div>
  }

  @if (!isModal && lottery?.currentLottery$ | async) {
    <app-lottery-deposit></app-lottery-deposit>
  }
</ng-template>

<ng-template #promoEasterBonuses>
  @if (easter.store().isRouteFromPromo && easter.store().isAllWelcomePackClaimed) {
    <app-easter-bonuses [place]="'deposit'"></app-easter-bonuses>
  }
</ng-template>


<ng-template #cashoutPaymentMethodForm>
  @if (!cancelingProcess) {
    <div
      id="payment-form"
      class="payment-form"
      [ngStyle]="{ 'margin-top': '60px' }"
      [ngClass]="{ 'payment-form--crypto': isSelectedPaymentMethodCrypto }"
    >
      <app-payment-form
        [method]="selectedMethod"
        [action]="Action()"
        [isModal]="isModal"
        (submit$)="submitCashout($event)"
        (switchView$)="switchView($event)"
      ></app-payment-form>
    </div>
  }
</ng-template>

<ng-template #submitCashoutForm>
  @if (!cancelingProcess) {
    <div class="submit-container">
      @if (!paymentInProgress) {
        <button
          type="submit"
          (click)="paymentForm()?.submitPayment()"
          id="payment_submit"
          [disabled]="!selectedMethod"
          class="btn btn--primary submit-btn"
        >
          {{ 'btn.cashout' | translate }}
        </button>
      } @else {
        <app-preloader></app-preloader>
      }
    </div>
  }
</ng-template>

<ng-template #submitDepositForm>
  @if (!cancelingProcess && selectedMethod?.type !== Payment.MethodType.Crypto) {
    @if (!transactions.isTransactionTemporaryDisabled) {
      <div class="submit-container">
        @if (!paymentInProgress) {
          <button
            type="submit"
            (click)="paymentForm()?.onNextDepositStep()"
            id="payment_submit"
            [disabled]="!selectedMethod"
            class="btn btn--primary submit-btn"
          >
            {{ (!isModal ? 'btn.deposit' : 't.deposit-and-play') | translate }}
          </button>
        } @else {
          <app-preloader></app-preloader>
        }
      </div>
    } @else {
      <ng-container *ngTemplateOutlet="temporaryDisabled"></ng-container>
    }
  }
</ng-template>

<ng-template #cashoutPaymentList>
  @if (!cancelingProcess && paymentList$ | async; as paymentList) {
    @if (!paymentList || !paymentList.length) {
      <p class="no-payments">{{ 't.no-payments-text' | translate }}</p>
    } @else {
      <ng-container *ngTemplateOutlet="payments; context: { paymentList: paymentList }"></ng-container>
    }
  }
</ng-template>

<ng-template #buyCrypto>
  @if (!isModal) {
    <app-buy-crypto></app-buy-crypto>
  }
</ng-template>

<ng-template #cancelCashoutProcess>
  @if (!cancelingProcess && latestCashout$ | async; as latestCashout) {
    <div class="container container--redesign">
      @if (latestCashout.length) {
        <div class="latest-cashout">
          <button type="button" class="btn btn--primary big" (click)="cancelClick(latestCashout[0]?.id)">
            {{ 't.recall-cashout' | translate }} {{ latestCashout[0].currency_symbol }} {{ latestCashout[0].amount }}
          </button>
        </div>
      }
    </div>
  }
</ng-template>

<ng-template #payments let-paymentList="paymentList">
  <div class="other-methods" [class.hidden]="payment.isSecondDepositStep">
      @if (breakpoints.isMobile ? paymentList.length > 2 : paymentList.length > 3) {
        <div id="view-more-payment" #accordion="accordionDirective" Accordion class="show-more">
          <span class="link link--primary --more">{{ 't.show-more-methods' | translate }}</span>
          <span class="link link--primary --less">{{ 't.show-less-methods' | translate }}</span>
        </div>
      }

      <div class="methods-list" [ngClass]="{ 'less-three': paymentList.length < 3 }">
        @for (item of paymentList; track item; let i = $index) {
          <div
            class="payment-method"
            [ngClass]="{
            active: item === selectedMethod,
            top: (item === selectedMethod && i > (isMobile() ? 1 : 2)) || lastSelectedMethodId === item?.id,
          }"
            (click)="selectPayment(item, i)"
          >
            <ng-container [ngSwitch]="true">
              <ng-container *ngSwitchCase="item.brand === 'fastasia' && user.currentCurrency.currency === 'PHP'">
                <img
                  class="payment-method__img"
                  [src]="FastasiaLogoPhpB"
                  [ngClass]="{ bigger: item['isBiggerLogo'], smallerMob: item['isSmallerLogoMob'] }"
                />
              </ng-container>
              <ng-container *ngSwitchCase="item.brand === 'fastasia' && user.currentCurrency.currency === 'THB'">
                <img
                  class="payment-method__img"
                  [src]="FastasiaLogoThb"
                  [ngClass]="{ bigger: item['isBiggerLogo'], smallerMob: item['isSmallerLogoMob'] }"
                />
              </ng-container>
              <ng-container *ngSwitchDefault>
                <img
                  defaultImage
                  [alternative]="item?.iconSrc"
                  [attr.data-src]="item?.ourImg"
                  [src]="item?.ourImg"
                  class="payment-method__img"
                  [ngClass]="{ bigger: item['isBiggerLogo'], smallerMob: item['isSmallerLogoMob'] }"
                />

                @for (badge of abTest.popularPaymentsDEVSS5667; track badge) {
                  @if (badge === (item?.provider + '-' + item?.originBrand)) {
                    <img ClassLoaded src="/assets/svg/badges/popular.svg" class="popular" />
                  }
                }
              </ng-container>
            </ng-container>
          </div>
        }
      </div>
    </div>
</ng-template>

<ng-template #secondStepDesc>
  <div class="second-step">
    <div class="second-step__title">{{'t.deposit' | translate}}</div>
    <div class="second-step__desc">{{'t.second-step-info' | translate}}</div>
  </div>
</ng-template>

<ng-template #temporaryDisabled>
  <div class="transaction-disabled">
    <button class="btn btn--primary big">
      <div class="transaction-disabled__inner">
        <div class="transaction-disabled__text">
          {{ 't.transactions-disabled' | translate }}
        </div>
        <div class="transaction-disabled__image">
          <svg width="34" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9 9V15M3 12V12.01M21 12V12.01M12 21V21.01M7.5 4.2V4.21M16.5 19.8V19.81M7.5
                          19.8V19.81M4.2 16.5V16.51M19.8 16.5V16.51M4.2 7.5V7.51M19.81 7.527C19.0226 6.15107 17.8856 5.00769
                          16.5141 4.2127C15.1426 3.41771 13.5853 2.99934 12 3M12 11V13C12 13.5304 12.2107 14.0391 12.5858 14.4142C12.9609
                           14.7893 13.4696 15 14 15C14.5304 15 15.0391 14.7893 15.4142 14.4142C15.7893 14.0391 16 13.5304 16 13V11C16 10.4696
                           15.7893 9.96086 15.4142 9.58579C15.0391 9.21072 14.5304 9 14 9C13.4696 9 12.9609 9.21072 12.5858 9.58579C12.2107 9.96086
                            12 10.4696 12 11Z"
              stroke="url(#paint0_linear_22585_694362)"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <defs>
              <linearGradient id="paint0_linear_22585_694362" x1="21" y1="3" x2="0.0920565" y2="16.5095" gradientUnits="userSpaceOnUse">
                <stop class="stop-1" />
                <stop offset="0.505208" class="stop-2" />
                <stop offset="1" class="stop-3" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </button>
  </div>
</ng-template>


<ng-template #mascot>
  @if (mascotService.state().isAbTestMascotV2) {
    <div class="mascot-row">
      @for (item of mascotService.state().filteredMascots; track $index) {
        <div class="mascot item-{{$index + 1}}">
          <div class="mascot__item">
            <div class="mascot__item--image">
              <img [src]="item?.image" alt="">
            </div>
            <div class="mascot__item--content">
              <div class="mascot__item--title">
                {{ item?.conditionTitle | translate }}
              </div>
              <div class="mascot__item--desc">
                {{ item?.conditionDesc | translate }}
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  }
</ng-template>
