import { Component, inject } from '@angular/core';
import { UserService } from '../../../services/user/user.service';
import { WindowService } from '../../../services/window.service';
import { TranslatePipe } from '../../translation/translate.pipe';

@Component({
    selector: 'app-ndfs-card-bonus-preview',
    imports: [
        TranslatePipe,
    ],
    templateUrl: './ndfs-card-bonus-preview.component.html',
    styleUrl: './ndfs-card-bonus-preview.component.scss'
})
export class NdfsCardBonusPreviewComponent {

  private _user: UserService = inject(UserService);
  private _window: WindowService = inject(WindowService);

  public translateKeys = {
    title: 't.free-spins',
    desc: 't.ndfs-description',
    confirm: 't.ndfs-confirm',
    confirm_email: 't.ndfs-confirm-email',
    status: 't.ndfs-status',
  };

  public onConfirm() {
    this._window.nativeWindow.open(
      `https://${this._user.info.email.split('@')[1]}`, '_blank');
  }
}
