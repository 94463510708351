import { Component, inject } from '@angular/core';
import {ToastMessage, ToastMessageService, ToastMessageType} from './toast-message.service';
import { showHide } from './animations';
import { ToastMessageItemComponent } from './toast-message-item/toast-message-item.component';
import { NgComponentOutlet } from '@angular/common';

@Component({
    selector: 'app-toast-message',
    templateUrl: './toast-message.component.html',
    styleUrls: ['./toast-message.component.scss'],
    animations: [showHide],
    imports: [ToastMessageItemComponent, NgComponentOutlet]
})
export class ToastMessageComponent {
  private _toastMessage = inject(ToastMessageService);


  get messagesList(): Array<ToastMessage> {
    return this._toastMessage.messagesList;
  }

  onMessageClick(message) {
    message.isClicked = true;
    if (message.type !== ToastMessageType?.COOKIE) {
      this._toastMessage.removeMessage(message);
    }
  }

}
