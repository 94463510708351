import { Component, ElementRef, HostListener, OnInit, inject } from '@angular/core';
import { NotificationCenterService } from '../notification-center.service';
import { WebSocketService } from '../../../services/web-socket.service';
import { UserService } from '../../../services/user/user.service';
import { InstallAppService } from '../../../services/install-app.service';
import { NotificationCenterComponent } from '../notification-center/notification-center.component';
import { SetImgAltDirective } from '../../../shared/directives/set-img-alt.directive';
import { ClickOutsideDirective } from '../../../shared/directives/click-outside.directive';
import { AsyncPipe, NgClass } from '@angular/common';
import { filter, first, tap } from 'rxjs/operators';

@Component({
    selector: 'app-notification-center-drop-down',
    templateUrl: './notification-center-drop-down.component.html',
    styleUrls: ['./notification-center-drop-down.component.scss'],
    imports: [ClickOutsideDirective, SetImgAltDirective, NgClass, NotificationCenterComponent, AsyncPipe]
})
export class NotificationCenterDropDownComponent implements OnInit {
  notify = inject(NotificationCenterService);
  ws = inject(WebSocketService);
  user = inject(UserService);
  private _installApp = inject(InstallAppService);
  private _el = inject(ElementRef);

  @HostListener('window:popstate') onPopstate() {
    this.notify.close();
  }

  ngOnInit() {
    this.notify.dropdownElement = this._el.nativeElement;
    this._installApp.nativeAppValueResolved$
      .pipe(
        filter(data => !!data),
        first(),
        tap(() => {
          if (this._installApp.showInstallAppNotificationMessage) {
            this.notify.installAppChannel$.next(null);
          }
        }),
      )
      .subscribe();
  }
}
