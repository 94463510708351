import { Component, inject, OnInit } from '@angular/core';
import { UserService } from '../core/services/user/user.service';
import { debounceTime, delay, filter, first, map, shareReplay, switchMap, takeWhile, tap } from 'rxjs/operators';
import { WindowService } from '../core/services/window.service';
import { PlatformService } from '../core/services/platform.service';
import { combineLatest, fromEvent, merge } from 'rxjs';
import { ToastMessageService } from '../core/modules/toast-message/toast-message.service';
import {
  CookieMessageContentComponent,
} from '../core/modules/toast-message/content/cookie-message-content/cookie-message-content.component';
import { BadgeService } from '../core/services/badge.service';
import { TranslationService } from '../core/shared/translation/translation.service';
import { AuPaymentRestrictService } from '../core/services/au-payment-restrict.service';
import { GameIframeService } from '../core/services/games/game-iframe.service';
import { JackpotService } from './jackpot/jackpot.service';
import { LocalstorageService } from '../core/services/localstorage.service';
import { WrSocketService } from '../core/services/wr-socket.service';
import { GlobalEventsService } from '../core/services/global-events.service';
import { InstallAppService } from '../core/services/install-app.service';
import { ModalService } from '../core/modal-v2/modal.service';
import { ScrollService } from '../core/services/scroll.service';
import { GroupsService } from '../core/services/groups.service';
import { StaticContentService } from '../core/services/static-content.service';
import { StaticPageService } from './static-page/static-page.service';
import { Router, RouterOutlet } from '@angular/router';
import { ArabicService } from '../core/services/arabic.service';
import { LOCAL_STORAGE_UBIDEX_KEY_DEPOSIT, UbidexService } from '../core/services/ubidex.service';
import { FraudCheckService, LOCAL_STORAGE_FRAUD_KEY_DEPOSIT } from '../core/services/fraud-check.service';
import { GameIframeComponent } from '../core/shared/standalone/game-iframe/game-iframe.component';
import { SetImgAltDirective } from '../core/shared/directives/set-img-alt.directive';
import { InstallAppBannerComponent } from '../core/shared/components/install-app-banner/install-app-banner.component';
import { AsyncPipe, NgClass } from '@angular/common';
import { StickyBonusComponent } from '../core/shared/components/sticky-bonus/sticky-bonus.component';
import { ToastMessageComponent } from '../core/modules/toast-message/toast-message.component';
import { AppShellNoSSRDirective } from '../core/shared/directives/app-shell/app-shell-no-ssr.directive';
import { LazyLoadImagesDirective } from '../core/shared/directives/ngx-lazy-load-images.directive';
import { UserInfoService } from '../core/services/user/user-info.service';
import { UserSubscriptionsService } from '../core/services/user/user-subscriptions.service';
import { CookieService } from 'ngx-unificator/services';
import { AB_TEST_LIST } from '../core/ab-test/ab-test.data';
import { SsPaymentsV2Service } from '../core/vendor/ss-payments-v2/ss-payment.service';
import { AbTestNewService } from '../core/ab-test/ab-test.service';
import { WebSocketService } from '../core/services/web-socket.service';
import { CommonDataService } from '../core/services/common-data.service';
import { INSTALL_APP_GROUP } from '../core/services/user/data/user-group.data';
import { BONUS_SHOP_TYPE, BonusStoreService } from '../core/services/bonus-store.service';
import { UserBonusesService } from '../core/services/user/user-bonuses.service';
import { MascotService } from '../core/services/mascot.service';
import { UserVipService } from '../core/services/user/user-vip.service';
import { EasterService } from './promo/easter/easter.service';

export const regAbTestGroupAfter15December = 'ID648'; // exist if user reg after 21.11.2023

export const regAbTestGroupThird = 'ID648'; // exist if user reg after 25.01.2024

export const INSTALL_APP_COOKIE = 'mobile-app';
@Component({
    selector: 'app-page',
    templateUrl: './page.component.html',
    styleUrls: ['./page.component.scss'],
    imports: [LazyLoadImagesDirective, RouterOutlet, AppShellNoSSRDirective, ToastMessageComponent, StickyBonusComponent, InstallAppBannerComponent, SetImgAltDirective, NgClass, GameIframeComponent, AsyncPipe]
})
export class PageComponent implements OnInit {
  private _user = inject(UserService);
  private _scroll = inject(ScrollService);
  private _window = inject(WindowService);
  private _platform = inject(PlatformService);
  private _toastMessage = inject(ToastMessageService);
  private _cookies = inject(CookieService);
  private _badge = inject(BadgeService);
  private _modals = inject(ModalService);
  private _abTestNew = inject(AbTestNewService);
  private _auRestrict = inject(AuPaymentRestrictService);
  private _jackpot = inject(JackpotService);
  private _storage = inject(LocalstorageService);
  private _groups = inject(GroupsService);
  translations = inject(TranslationService);
  private _wrSocket = inject(WrSocketService);
  private _events = inject(GlobalEventsService);
  gameIframe = inject(GameIframeService);
  installApp = inject(InstallAppService);
  private _easter = inject(EasterService);
  private _static = inject(StaticContentService);
  private _page = inject(StaticPageService);
  private _router = inject(Router);
  private _arabic = inject(ArabicService);
  private _ubidex = inject(UbidexService);
  private _fraud = inject(FraudCheckService);
  private _userInfo = inject(UserInfoService);
  private _subscriptions = inject(UserSubscriptionsService);
  private _ssPayment = inject(SsPaymentsV2Service);
  private _ws = inject(WebSocketService);
  private _commonData = inject(CommonDataService);
  private _bonusShop = inject(BonusStoreService);
  private _bonuses = inject(UserBonusesService);
  private _mascot = inject(MascotService);
  private _vip = inject(UserVipService);


  /**
   * Is to top button visible
   */
  public toTopVisible: boolean;

  public AB_TEST_LIST = AB_TEST_LIST;

  ngOnInit() {
    if (!this._arabic.isAr) {
      this._initEasterPromo();
    }

    if (this._platform.isBrowser) {
      this._loadUserVipStore();
      this._handleAuthActions();
      this._handleScroll();
      this._initJackpot();
      this._showCookiesMessage();
      this._listenJackpotWinnerAndOpenModal();
      this._jackpot.getTotalJackpot();
      this._listenLinkClickAndOpenExchangeModal();
      this._sendUbidexVisit();
      this._initAbTests();
      this._initSlotModal()
      this.installApp.checkIsNativeAppEnabled();
      this._initBonusShop();

      if (!this._user.auth) {
        this._subscriptions.resolveReceiveSmsPromosByGeo();
        this._subscriptions.resolveAcceptedTermsByGeo();
      }
    }
  }

  private _initEasterPromo() {
    this._user.auth$.pipe(
      switchMap(() =>
        this._easter.initPromo$())).subscribe();
  }

  /**
   * Init jackpot by ab test
   * @private
   */
  private _initJackpot() {
    this._jackpot.initJackpot('available-jackpot');
  }


  private _loadUserVipStore() {
    merge(
      this._vip.loadVipPage$().pipe(
        tap((page) => {
          this._vip.vipStore.storeSignal.update(store => ({
            ...store,
            page,
          }));
        }),
      ),
      this._vip.vipLevels$,
    ).subscribe();
    this._vip.reloadVipLevels();
  }


  /**
   * Load badges info and
   * add user to test group
   */
  private _handleAuthActions() {
    this._user.auth$.pipe(
      filter(auth => !!auth),
      first(),
    ).subscribe(() => {
      this._wrSocket.initWrSocket();
      this._badge.loadBadgeInfo();
      this._events.isLeaveBrowserTab$.subscribe((isLeave: boolean) => {
        isLeave ? this._wrSocket.sendEventMouseout() : this._wrSocket.sendEventMousein();
      });
      this._storage.set('user_id', this._user.info.id);
      this._abTestNew.syncUserABTestGroupOnAuth();
      this._ssPayment.loadApiLibrary();
      this._ubidex.onVisit(this._user.info);
      this._listenUserBalance();
      this._addUserToMobileAppGroup();
      this._listenDepositSuccess();
      this._mascot.resolveUserMascots();
    });
  }

  private _listenDepositSuccess() {
    this._wrSocket.depositSuccessNotify$.pipe(
      tap(() => {
          if (this._ubidex.isStorageLength(LOCAL_STORAGE_UBIDEX_KEY_DEPOSIT) && !this._ubidex.requestInProgress) {
            this._ubidex.sendDepositEvents();
          }
      }),
      filter(() => this._fraud.isBodyFraudStorageLength(LOCAL_STORAGE_FRAUD_KEY_DEPOSIT)),
      switchMap(({ uid }) => this._fraud.postFraudData(this._storage.get(LOCAL_STORAGE_FRAUD_KEY_DEPOSIT), uid)),
      tap(() => this._storage.clearItem(LOCAL_STORAGE_FRAUD_KEY_DEPOSIT)),
    ).subscribe();
  }

  /**
   * To top button click handler
   */
  onToTopClick() {
    this._scroll.scrollToElement(this._scroll.documentElement);
  }

  /**
   * Window scroll handler
   *
   * @private
   */
  private _handleScroll() {
    if (this._platform.isBrowser) {
      fromEvent(this._window.nativeWindow, 'scroll').pipe(
        debounceTime(150),
      ).subscribe(() => {
        this.toTopVisible = this._window.nativeWindow.pageYOffset > 300;
      });
    }
  }


  /**
   * Show cookie message after AU payment restrict check
   */
  private _showCookiesMessage() {
    if (!this._cookies.check('isCookiesAccepted')) {
      if (this._platform.isBrowser) {
        this._auRestrict.text$.pipe(
          first(),
          tap(() => {
            const message = this._toastMessage.cookie(CookieMessageContentComponent, null, true, false);

            message.callback = () => {
              this._showCookiesMessage();
            };
          }),
        ).subscribe();
      }
    }
  }

  /**
   * Listen jackpot winner and open modal
   * @private
   */
  private _listenJackpotWinnerAndOpenModal() {
    this._jackpot.wsWinnerJackpot$.pipe(
      tap(async (jackpot) => {
        const component = await import('../core/modal-v2/components/lazy/jackpot-winning/jackpot-winning.component');
        await this._modals.openLazy(component?.JackpotWinningComponent, {
          template: 'CLEAR',
          data: { jackpot },
        });
      }),
    ).subscribe();
  }

  /**
   * Open currency exchange modal
   */
  private _listenLinkClickAndOpenExchangeModal() {
    this._events.isExchangeRateLinkClick$.pipe(
      filter(slug => !!slug),
      tap(async slug => {
        const component = await import('../core/modal-v2/components/lazy/title-and-content-modal/title-and-content-modal.component');
        await this._modals.openLazy(component?.TitleAndContentModalComponent, {
          template: 'CLEAR',
          data: {
            content$: this._page.item({ slug }),
          },
        });
      }),
    ).subscribe();
  }
  /**
   * The function `_sendUbidexVisit` checks if the user is authenticated and calls the `onVisit` method
   * of the `_ubidex` object if not.
   */
  private _sendUbidexVisit() {
    if (!this._user.auth) {
      this._ubidex.onVisit();
    }
  }

  private _addUserToMobileAppGroup() {
    if (this._cookies.check(INSTALL_APP_COOKIE)) {
      this._groups.addToGroup(INSTALL_APP_GROUP).pipe(
        first(),
      ).subscribe()
    }
  }

  private _initAbTests() {
    this._initPopularPaymentAbTest();
  }

  private _listenUserBalance() {
    this._ws.userBalance$.pipe(
      map(balance => {
        return {
          ...balance,
          amount: this._commonData.subunitsToUnits(balance.amount_cents, balance.currency),
        };
      }),
      tap((balance) => this._user.currentCurrency.amount = balance.amount),
    ).subscribe();
  }


  private _initPopularPaymentAbTest() {
    if (this._userInfo.isCA) {
      this._static.item({ slug: 'popular-payments-ids' }).pipe(
        filter((data) => !!data && data[0]),
        map((data) => {
          this._abTestNew.popularPaymentsDEVSS5667 = Object.keys(data[0].Grouplist);
          return this._abTestNew.popularPaymentsDEVSS5667;
        }),
      ).subscribe();
    }
  }

  /**
   * Private init slot modal
   * @private
   */
  private _initSlotModal() {
    if (this._platform.isBrowser) {
      const routesIgnore = ['registration', 'login', '403', '404', 'maintenance'];

      this._events.routerNavigationEnd$.pipe(
        delay(10000),
        takeWhile(() => !this._user.auth),
        filter(() => !this._cookies.check('--slots-modal-display')),
        switchMap(() => combineLatest([this._static.item({slug: 'ss-slot-welcome'}), this._bonuses.allCmsBonusList$])),
        filter(() => !this._cookies.check('--slots-modal-display')),
        filter(([modalData, welcomeBonusList]) => modalData?.[0] && !!welcomeBonusList?.length),
        tap(async ([modalData, welcomeBonus]) => {
          if (!this._user.auth && !routesIgnore.some(route => this._router.url.includes(route))) {
            const component = await import('../core/modal-v2/components/lazy/slots-welcome-modal/slots-welcome-modal.component');
            await this._modals.openLazy(component.SlotsWelcomeModalComponent, {
              template: 'CLEAR',
              data: {welcomeBonus: welcomeBonus[0], modalData: modalData[0]},
              disableOverlayClosing: true,
            });
          }
        })
      ).subscribe();
    }
  }

  private _initBonusShop() {
    this._bonusShop.bonusStorePage$ = combineLatest([
        this._bonusShop.getAndMappedPage$(BONUS_SHOP_TYPE.CASH),
        this._bonusShop.getAndMappedPage$(BONUS_SHOP_TYPE.COINS),
      ],
    ).pipe(
      shareReplay(1)
    );
  }
}
