import { Component, ElementRef, HostListener, inject, input } from '@angular/core';
import { SelectService } from '../select.service';

@Component({
    selector: 'app-select-option',
    templateUrl: './select-option.component.html',
    styleUrls: ['./select-option.component.scss'],

})
export class SelectOptionComponent {
  el = inject(ElementRef);
  private _select = inject(SelectService);


  /**
   * Value for current option
   */
  readonly value = input<any>(undefined);

  /**
   * Click handler
   */
  @HostListener('click') onClick() {
    this._select.writeValue(this.value(), true);
  }

}
