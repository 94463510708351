<div class="install-app-banner">
  <div class="install-app-banner__wrapper">
    <img class="install-app-banner__logo" src="/assets/img/icons/512.png" alt="Install app logo">
    <div class="install-app-banner__text">
      <span>{{ 't.all-games-bonuses-app' | translate }}</span>
      @if (!rtl.isAr) {
        <a (click)="installApp.closeDepositBlock()" [routerLink]="['/', 'mobile-app']">{{'t.how-install' | translate}}</a>
      }
    </div>
    @if (rtl.isAr) {
      <button class="btn btn--primary big">{{'t.how-install' | translate}}</button>
    }
    <i class="close icon-close" (click)="installApp.closeDepositBlock()"></i>
  </div>
</div>
