import { computed, inject, Injectable } from '@angular/core';
import { GameCategory } from '../../../core/services/games/game-category';
import { combineLatest, Observable, of, switchMap } from 'rxjs';
import { ITournament } from '../../tournaments/tournaments.interface';
import { filter, map, tap } from 'rxjs/operators';
import { TournamentsService } from '../../tournaments/tournaments.service';
import { BasePromo, EventType } from '../base-promo';
import { useStore } from 'ngx-unificator/store';
import { GlobalStoreName } from '../../../core/store';
import { EasterStoreEntity, IEasterPage } from '../../../core/store/easter.store';
import { BonusStage } from '../../../core/services/user/data/user-bonuses.data';
import { GameListConfig } from '../../../core/shared/interfaces/game-list-config-interface';
import { BonusesStatuses } from '../../../core/services/groups.service';

export enum EasterAction {
  GO_TO_DEPOSIT = 'deposit',
  TOURNAMENTS = 'tournaments',
  PROMO_AUTH = 'promo-auth',
  GET_BONUS = 'get-bonus',
  GO_TO_BONUSES = 'bonuses',
}

@Injectable({
  providedIn: 'root',
})
export class EasterService extends BasePromo {

  public eventName = 'easter';

  public tournaments: TournamentsService = inject(TournamentsService);

  public gamesListConfig: GameListConfig = {
    filter: {
      category: GameCategory.EASTER_GAMES,
      limit: 5,
    },
    hideTitle: true,
    alignWithOutsideContainer: true,
  };

  public tournaments$: Observable<ITournament[]> = this._getTournaments$();

  public easterStore =
    useStore<EasterStoreEntity>(GlobalStoreName.EASTER_STORE);

  public store = computed(() =>
    this.easterStore.storeSignal());

  public hunt = computed(() =>
    this.easterStore.storeSignal().page?.HuntModal);

  private _actionRoutesConfig = {
    [EasterAction.GET_BONUS]: {
      key: null,
      url: '/profile/account',
      authUrl: ['profile', 'account'],
    },
    [EasterAction.GO_TO_BONUSES]: {
      key: null,
      url: '/bonuses',
      authUrl: ['bonuses'],
    },
    [EasterAction.TOURNAMENTS]: {
      key: 'promo-tournaments',
      url: '/tournaments',
      authUrl: ['tournaments'],
    },
    [EasterAction.GO_TO_DEPOSIT]: {
      key: 'promo-deposit',
      url: '/profile/deposit',
      authUrl: ['profile', 'deposit'],
    },
    [EasterAction.PROMO_AUTH]: {
      key: 'promo-auth',
      url: '/promo/easter',
      authUrl: null,
    },
  };

  constructor() {
    super();
    this._handleQueryPromoParams();
  }

  /**
   * Init promotion and reduce store items
   */
  public initPromo$() {
    this._updateLoadingPromo(true);
    return this._fetchPromoData$().pipe(
      filter(data => !!data[0]),
      tap(([promo, lootboxes, freespins, bonuses]) => {
        this._updatePageStore(promo, lootboxes);
        this._updateCurrentBonus(lootboxes, freespins, bonuses);
        this._updateAlreadyTakenBonuses();
        this._updateLoadingPromo(false);
        this.initHuntEvent(this.store().page.HuntModal);
        this.initWelcomePromoModal();
        this.linkMenuEnabled = true;
      }),
    );
  }

  /**
   * On action function
   * @param action
   * @param bonusCode
   * @param successUrl
   */
  public async onAction(
    action: EasterAction,
    bonusCode: string = '',
    successUrl: string = this.window.nativeWindow.location.origin + '/profile/account' + '?promo_deposit=true',
  ) {
    const routeConfig = this._actionRoutesConfig[action];
    const { key, url, authUrl } = routeConfig;

    if (action === EasterAction.GO_TO_DEPOSIT) {
      this._handleQueryPromoParams();
      await this._handleActionRoute(
        key,
        url,
        authUrl,
        false,
        { successUrl, bonusCode },
        { queryParams: { promo_deposit: true, successUrl, bonusCode } },
      );
    } else {
      const isPromoAuth = action === EasterAction.PROMO_AUTH;
      await this._handleActionRoute(key, url, authUrl, isPromoAuth );
    }
  }

  /**
   * On catch bonus
   */
  public onCatchBonus$(): Observable<any> {
    const {
      promoLootbox,
      welcomeLootbox,
      fs,
      cash,
    } = this.store().currentBonus || {};

    const cashPrize = cash ? `${cash.amount} ${cash.currency}` : undefined;

    if (this.store().isUserCanPartiallyParticipateInPromo) {
      if (welcomeLootbox) {
        return this._activateLootbox$(welcomeLootbox.id).pipe(
          switchMap((response) => {
            const fs_count = this.bonuses.resolveBonusAttributes(
              response?.bonuses[0].attributes)?.freespins_count;
            this._updateCurrentPrize(fs_count, cashPrize ?? undefined);
            const currentFsBonus = this.bonuses.mapBonusTitle(response?.bonuses[0]?.title);
            return this.bonuses.freeSpinsList().pipe(
              switchMap((list) => {
                const foundFs = list.find((e) => e.title === currentFsBonus);
                return this.bonuses.activateFreeSpins(foundFs?.id);
              }),
            );
          }),
        );
      } else if (fs) {
        return this.bonuses.activateFreeSpins(fs?.id).pipe(
          tap(() => {
            this._updateCurrentPrize(fs?.freespins_total, cashPrize ??
              undefined);
          }),
        );
      }
    } else {
      if (promoLootbox) {
        return this._activateLootbox$(promoLootbox?.id).pipe(
          switchMap((response) => {
            const fs_count = this.bonuses.resolveBonusAttributes(
              response?.bonuses[0].attributes)?.freespins_count;
            this._updateCurrentPrize(fs_count);
            const currentFsBonus = this.bonuses.mapBonusTitle(response?.bonuses[0]?.title);
            return this.bonuses.freeSpinsList().pipe(
              switchMap((list) => {
                const foundFs = list.find((e) => e.title === currentFsBonus);
                return this.bonuses.activateFreeSpins(foundFs?.id);
              }),
            );
          }),
        );
      }
    }
  }

  private _handleQueryPromoParams() {
    this.route.queryParams.pipe(
      tap(() => this._updateRouteFromPromo(false)),
      filter(({ promo_deposit }) => promo_deposit),
      tap(() => {
        this._updateRouteFromPromo(true);
      }),
    ).subscribe();
  }

  /**
   * Handle action routes
   * @param key
   * @param url
   * @param authUrl
   * @param urlData
   * @param extras
   * @private
   */
  private async _handleActionRoute(
    key: string, url: string, authUrl: string[], isPromoAuth?: boolean, urlData?: any, extras?: any) {
    const urlParams = { key, url, authUrl, urlData, extras };
    if (!key) {
      await this.router.navigateByUrl(url);
      return;
    }
    if (!this.user.auth) {
      this.local.addUrl(urlParams.key, urlParams.url, urlParams.urlData);
      const isRegistered = this.cookie.check('registered');
      const url = this.router.url + (isRegistered ? '?modal=login' : 'modal?=registration');
      await this.router.navigateByUrl(url);
    } else {
      await this.router.navigate(urlParams.authUrl, urlParams.extras);
    }
  }

  /**
   * Fetch necessary data for promo
   * @param mode
   * @private
   */
  private _fetchPromoData$(): Observable<[
    IEasterPage[], any[], any[], any[]]> {
    return combineLatest([
      this.page.item({
        slug: this.eventName
      }),
      this.user.auth ? this.lootboxService.loadUserLootBox() : of([]),
      this.user.auth ? this.bonuses.freeSpinsList() : of([]),
      this.user.auth ? this.bonuses.bonusList() : of([]),
    ]);
  }

  /**
   * Update page store
   * @param promo
   * @param lootbox
   * @private
   */
  private _updatePageStore(
    promo: any[],
    lootbox: any[]): any {

    this.easterStore.storeSignal.update(store => {
      return {
        ...store,
        page: {
          ...promo?.map(promoItem => ({
            ...promoItem,
            HowItWorksSteps: [...Object.values(promoItem?.HowItWorksSteps)],
            Bonuses: this._mappedBonuses(
              [...Object.values(promoItem?.Bonuses)],
              lootbox,
            ),
            FilteredBonuses: this._mappedBonuses(
              [...Object.values(promoItem?.Bonuses)],
              lootbox,
            ).filter((e) => !e.isTaken),
            activeImg: this._resolveActiveImage(this._mappedBonuses(
              [...Object.values(promoItem?.Bonuses)],
              lootbox,
            )),
            HuntModal: promoItem?.HuntModal?.HuntModal,
            WelcomePromoModal: promoItem?.WelcomePromoModal?.WelcomePromoModal,
            unpublishAt: this.time.toLocalDate(promoItem?.unpublishAt),
            publishAt:this.time.toLocalDate(promoItem?.publishAt),
          }))[0],
        },
      };
    });
  };

  private _updateRouteFromPromo(isRouteFromPromo: boolean) {
    this.easterStore.storeSignal.update(store => {
      return {
        ...store,
        isRouteFromPromo,
      };
    });
  }

  /**
   * Update current bonus store
   * @param lootboxes
   * @param freespins
   * @param bonuses
   * @private
   */
  private _updateCurrentBonus(lootboxes: any, freespins: any, bonuses: any) {

    const cash = this._getFilteredCashWelcomeBonuses(bonuses);
    const fs = this._getFilteredFreespinsWelcomeBonuses(freespins);
    const welcomeLootbox = this._getFilteredWelcomeLootbox(lootboxes);
    const promoIssuedLootbox = this._getFilteredPromoLootbox(lootboxes, BonusStage.ISSUED);
    const promoActivatedLootbox = this._getFilteredPromoLootbox(lootboxes, BonusStage.ACTIVATED);

    this._updateParticipateInPromo(cash, fs, welcomeLootbox, promoIssuedLootbox, promoActivatedLootbox);

    this.easterStore.storeSignal.update(store => {
      return {
        ...store,
        currentBonus:
          fs && cash || welcomeLootbox && cash || fs || promoIssuedLootbox ? {
            cash,
            fs,
            welcomeLootbox,
            promoLootbox: promoIssuedLootbox,
          } : null,
      };
    });
  }

  /**
   * Update already taken store
   * @private
   */
  private _updateAlreadyTakenBonuses() {
    this.easterStore.storeSignal.update(store => {
      return {
        ...store,
        isUserAlreadyTakenLootboxes: store.page.Bonuses.every(item => item.isTaken),
      };
    });
  }

  /**
   * Update participating in promo store items
   * @param cash
   * @param fs
   * @param welcomeLootbox
   * @param promoIssuedLootbox
   * @param promoActivatedLootbox
   * @private
   */
  private _updateParticipateInPromo(
    cash: any, fs: any, welcomeLootbox: any, promoIssuedLootbox: any, promoActivatedLootbox: any) {

    const isAllWelcomePackClaimed = this.group.isExistGroup(
      BonusesStatuses.ALL_WELCOME_OFFERS_USED) || this.group.isExistGroup(
      BonusesStatuses.ALL_SPECIAL_WELCOME_USED);

    const isUserCanParticipateInPromo =
      Boolean(isAllWelcomePackClaimed && promoIssuedLootbox);
    const isUserCanPartiallyParticipateInPromo =
      Boolean((cash || fs || welcomeLootbox) && !promoIssuedLootbox);
    const isShowWelcomeModalPromo = !promoActivatedLootbox && isAllWelcomePackClaimed;

    this.easterStore.storeSignal.update(store => {
      return {
        ...store,
        isUserCanParticipateInPromo,
        isUserCanPartiallyParticipateInPromo,
        isAllWelcomePackClaimed,
        isShowWelcomeModalPromo
      };
    });
  }

  /**
   * Update loading promo state
   * @param isLoading
   * @private
   */
  private _updateLoadingPromo(isLoading: boolean) {
    this.easterStore.storeSignal.update(store => {
      return {
        ...store,
        isLoadedPromo: !isLoading,
      };
    });
  }

  /**
   * Update current prize state
   * @param fs_count
   * @param cash
   * @private
   */
  private _updateCurrentPrize(fs_count: number, cash?: string) {
    this.easterStore.storeSignal.update(store => ({
      ...store,
      currentPrize: {
        cash,
        fs_count,
      },
    }));
  }

  /**
   * Map 3 daily bonuses
   * @param cmsBonuses
   * @param lootboxes
   * @private
   */
  private _mappedBonuses(cmsBonuses: any[], lootboxes: any[]): any[] {
    return cmsBonuses.map(promo => {
      const matchingLootbox = lootboxes.find(lootbox =>
        lootbox?.title === promo?.id,
      );
      return {
        ...promo,
        bonusLevel: promo?.id?.match(/\d+/) ? parseInt(promo.id.match(/\d+/)[0], 10) : null,
        isTaken: [BonusStage.ISSUED, BonusStage.ACTIVATED].includes(matchingLootbox?.stage) &&
          this.time.isTodayDate(matchingLootbox?.created_at),
      };
    });
  }


  /**
   * Activate lootbox by id
   * @param id
   * @private
   */
  private _activateLootbox$(id: number): Observable<any> {
    return this.lootboxService.activateLootBox(id);
  }

  /**
   * Get filtered welcome lootbox
   * @param arrayToCheck
   * @private
   */
  private _getFilteredWelcomeLootbox(arrayToCheck: any[]): any {
    return arrayToCheck?.filter(lootbox => lootbox.isWelcome &&
      lootbox.stage === BonusStage.ISSUED)[0];
  }

  /**
   * Get filtered welcome freespins
   * @param arrayToCheck
   * @private
   */
  private _getFilteredFreespinsWelcomeBonuses(arrayToCheck: any[]): any {
    return arrayToCheck?.filter(bonus => bonus.stage === BonusStage.ISSUED &&
      bonus.isWelcome)?.sort((a, b) =>
      b.created_at - a.created_at)[0];
  }

  /**
   * Get filtered cash bonuses
   * @param arrayToCheck
   * @private
   */
  private _getFilteredCashWelcomeBonuses(arrayToCheck: any[]): any {
    return arrayToCheck?.filter(bonus =>
      bonus.stage === BonusStage.HANDLE_BETS && bonus.isWelcome)?.sort((a, b) =>
      b.created_at - a.created_at)[0];
  }

  /**
   * Get filtered last promo lootbox
   * @param arrayToCheck
   * @private
   */
  private _getFilteredPromoLootbox(arrayToCheck: any[], stage: BonusStage): any {
    return arrayToCheck?.filter(lootbox =>
      lootbox.title.trim().toLowerCase().includes('landing') &&
      lootbox.stage === stage)?.sort((a, b) =>
      b.created_at - a.created_at)[0];
  }


  /**
   * Get promo tournaments
   * @private
   */
  private _getTournaments$(): Observable<any> {
    return this.tournaments.list({ with_game_list: 1 }).pipe(
      filter((tournaments) => !!tournaments),
      map(tournaments => tournaments.now.filter(tournament => {
        return tournament.slug.includes('easter');
      })),
    );
  }

  private _resolveActiveImage(bonuses: any[]) {
    const imagePaths = {
      "000": "assets/img/promo/easter/main/0.png",  // No bonuses taken
      "100": "assets/img/promo/easter/main/1.png",  // Only first bonus taken
      "010": "assets/img/promo/easter/main/2.png",  // Only second bonus taken
      "110": "assets/img/promo/easter/main/3.png",  // First and second bonuses taken
      "001": "assets/img/promo/easter/main/4.png",  // Only third bonus taken
      "101": "assets/img/promo/easter/main/5.png",  // First and third bonuses taken
      "011": "assets/img/promo/easter/main/6.png",  // Second and third bonuses taken
      "111": "assets/img/promo/easter/main/7.png"   // All bonuses taken
    };

    const key = bonuses.map(b => b.isTaken ? "1" : "0").join("");
    return imagePaths[key] || imagePaths[0];
  }

  async checkActivatedBonuses() {
    const component = await import('../easter/easter-welcome-promo/easter-welcome-promo.component');
    if (this.store().isShowWelcomeModalPromo) {
      await this.modals.openLazy(component?.EasterWelcomePromoComponent, {
        template: 'CLEAR',
        disableOverlayClosing: true,
      });
      this.cookie.set('welcome-modal-promo', 'true', 1, '/');
    }
    return of(true);
  }

  async openHuntModal(type: EventType) {
    const component = await import('../easter/easter-hunt-modal/easter-hunt-modal.component');
    await this.modals.openLazy(component?.EasterHuntModalComponent, {
      template: 'CLEAR', data: {
        size: this.eventList.length,
        data: this.huntModalData,
      },
    });
  }
}
