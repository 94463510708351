<app-header></app-header>

@if (page$ | async; as page) {
  <div class="bonuses">
    @if (!bonuses.isAllBonusUsed) {
      <ng-container *ngTemplateOutlet="headerTitle"></ng-container>
      <ng-container *ngTemplateOutlet="starterPack"></ng-container>
    } @else {
      <div class="bonuses__header-title">
        <h1 class="title">{{ 'h.bonuses' | translate }}</h1>
        <div class="subtitle subtitle--used">{{ 't.welcome-bonus-program' | translate }}</div>
      </div>
    }
    <div class="container container--redesign">
      @if (user.auth) {
        <ng-container *ngTemplateOutlet="specialBonuses"></ng-container>
        <ng-container *ngTemplateOutlet="bonusCode"></ng-container>
      }
      <ng-container *ngTemplateOutlet="reloadBonusesTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="dailyBonuses"></ng-container>
      <ng-container *ngTemplateOutlet="social"></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="termsTemplate"></ng-container>
    <ng-template #headerTitle>
      <div class="bonuses__header-title">
        <h1 class="title">{{ 'h.bonuses' | translate }}</h1>
        <div class="subtitle">{{ 't.choose-strategy' | translate }}</div>
      </div>
    </ng-template>
    <ng-template #starterPack>
      @if (welcomeBonusList$ | async; as welcomeBonusList) {
        @if (welcomeBonusList?.[bonuses.bonusPacks.STARTER].length || welcomeBonusList?.[bonuses.bonusPacks.HIGHROLLER].length) {
          <div class="bonuses__tabs"
               [ngClass]="{auth: user?.auth, four: welcomeBonusList?.[bonuses.bonusPacks.STARTER]?.length === 4, five: welcomeBonusList?.[bonuses.bonusPacks.STARTER]?.length === 5}"
          >
            <div class="container container--redesign"
                 [ngClass]="{four: welcomeBonusList?.[bonuses.bonusPacks.STARTER]?.length === 4, five: welcomeBonusList?.[bonuses.bonusPacks.STARTER]?.length === 5}"
            >
              <app-welcome-bonuses-list [bonuses]="welcomeBonusList?.[bonuses.bonusPacks.STARTER]"
                                        [title]="page?.TitleStarter"></app-welcome-bonuses-list>
            </div>
          </div>
        }
      }
    </ng-template>
    <ng-template #specialBonuses>
      @if (bonuses$ | async; as bonusTypes) {
        <div class="bonuses__special">
          @if (bonusTypes[bonuses.Stage.ISSUED].length || bonusTypes[bonuses.Stage.ACTIVATED].length) {
            <div class="block-title">{{ 't.your-special-bonuses' | translate }}</div>
            <div class="bonuses__special_list">
              <ng-container *ngTemplateOutlet="ndfs"></ng-container>
              @for (bonus of bonusTypes[bonuses.Stage.ACTIVATED]; track bonus) {
                <div class="bonuses__special_item">
                  <app-bonus-preview [bonus]="bonus" [type]="bonus?.type"
                                     (onCancel)="cancel($event)"></app-bonus-preview>
                </div>
              }
              @for (bonus of bonusTypes[bonuses.Stage.ISSUED]; track bonus) {
                <div class="bonuses__special_item">
                  <app-bonus-preview [bonus]="bonus"
                                     [type]="bonus?.type"
                                     (onActivate)="activate($event)"
                                     (onCancel)="cancel($event)"
                                     (onCancelLootbox)="onCancelLootbox($event)"
                                     (onOpenLootbox)="onOpenLootbox($event)"
                  ></app-bonus-preview>
                </div>
              }
              @for (bonus of bonusTypes[bonuses.Stage.EXPIRED]; track bonus) {
                <div class="bonuses__special_item">
                  <app-bonus-preview [bonus]="bonus" [type]="bonus?.type"></app-bonus-preview>
                </div>
              }
            </div>
          } @else {
            <div class="bonuses__special_list">
              <ng-container *ngTemplateOutlet="ndfs"></ng-container>
            </div>
          }
        </div>
      } @else {
        <app-preloader></app-preloader>
      }
    </ng-template>
    <ng-template #bonusCode>
      <div class="bonuses__code">
        <app-no-deposit-bonus></app-no-deposit-bonus>
      </div>
    </ng-template>
    <ng-template #reloadBonusesTemplate>
      @if (reloadBonuses$ | async; as reloadBonuses) {
        @if (reloadBonuses?.length && user.auth) {
          <div class="block-title">{{ 't.special-bonuses' | translate }}</div>
        }
        @if (showPhoneVerificationPromo) {
          <app-phone-verification-offer></app-phone-verification-offer>
        }
        <div class="top-slots-offer">
          <app-top-slots-super-offer (detailsClick)="onDetailsClick()"></app-top-slots-super-offer>
        </div>
        @if (reloadBonuses?.length) {
          <div class="bonuses__reload">
            @if (blogBonuses$ | async; as blogBonuses) {
              @for (bonus of blogBonuses; track bonus) {
                <div class="bonuses__reload_item">
                  <app-bonus-preview [bonus]="bonus" [type]="bonusTypes.BLOG"></app-bonus-preview>
                </div>
              }
            }
            @for (bonus of reloadBonuses; track bonus) {
              <div class="bonuses__reload_item">
                <app-bonus-preview [bonus]="bonus" [type]="bonusTypes.RELOAD"></app-bonus-preview>
              </div>
            }
          </div>
        }
      }
    </ng-template>
    <ng-template #dailyBonuses>
      @if (dailyRewardBonus$ | async; as dailyBonuses) {
        <div class="bonuses__daily">
          @for (bonus of dailyBonuses; track bonus) {
            <div class="bonuses__daily_item">
              <app-bonus-preview [bonus]="bonus" [type]="bonusTypes.DAILY"></app-bonus-preview>
            </div>
          }
        </div>
      }
    </ng-template>
    <ng-template #social>
      <div class="bonuses__social">
        <app-social></app-social>
      </div>
    </ng-template>
    <ng-template #termsTemplate>
      @if (terms$ | async; as terms) {
        @if (terms?.Content) {
          <div class="bonuses__terms">
            <app-bottom-terms [terms]="terms?.Content"></app-bottom-terms>
          </div>
        }
      }
    </ng-template>
    <ng-template #ndfs>
      @if (ndfsUser$ | async) {
        @if (!user.info.confirmed_at) {
          <div class="bonuses__special_item">
            <app-ndfs-card-bonus-preview></app-ndfs-card-bonus-preview>
          </div>
        }
      }
    </ng-template>
  </div>
}

<app-bottom-navigation></app-bottom-navigation>
@defer (on viewport) {
  <app-footer></app-footer>
} @placeholder {
  <div></div>
}


@if (page$ | async; as page) {
  @if (page?.SeoContent) {
    <div class="container container--redesign">
      <div class="container inner">
        <app-bottom-text [text]="page?.SeoContent"></app-bottom-text>
      </div>
    </div>
  }
}

<app-side-nav></app-side-nav>
