import { Component, OnInit, inject } from '@angular/core';
import {ModalContent, ModalRef} from '../../../modal-ref';
import {FlashPlayerService} from '../../../../services/flash-player.service';
import {
  GoogleTagManagerService,
  POPUP_EVENT_ACTIONS,
  POPUP_EVENT_CATEGORY
} from '../../../../services/google-tag-manager.service';

@Component({
    selector: 'app-game-requires-flash',
    templateUrl: './game-requires-flash.component.html',
    styleUrls: ['./game-requires-flash.component.scss'],

})
export class GameRequiresFlashComponent implements OnInit, ModalContent {
  flash = inject(FlashPlayerService);
  private _gtm = inject(GoogleTagManagerService);


  /**
   * Access to parent modal
   */
  public modal: ModalRef;

  ngOnInit() {
    this._gtm.popupEvent(
      POPUP_EVENT_CATEGORY.GAME_REQUIRES_FLASH,
      POPUP_EVENT_ACTIONS.GAME_REQUIRES_FLASH
    );
  }

}
