import { computed, inject, Injectable, signal } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import { CookieService, PlatformService } from 'ngx-unificator/services';
import { Location } from '@angular/common';
import { LocalstorageService } from './localstorage.service';
import { SsApiService } from './api/ss-api.service';
import { WindowService } from './window.service';
import { EMPTY, Observable } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { NATIVE_APP_COOKIE } from './install-app.service';
import { INSTALL_APP_COOKIE } from '../../page/page.component';

export const PTAG_KEY = 'ptag';

export const COOKIE_ID_ID = 'id_id';
export const HEADER_ID_ID = 'id-id';

export const STAG_URL_KEY = 'stag_url';
export const STAG_KEY = 'stag';

@Injectable({
  providedIn: 'root',
})
export class StagDetectorService {

  private _cookie: CookieService = inject(CookieService);
  private _router: Router = inject(Router);
  private _serializer: UrlSerializer = inject(UrlSerializer);
  private _location: Location = inject(Location);
  private _localStorage: LocalstorageService = inject(LocalstorageService);
  private _ssApi: SsApiService = inject(SsApiService);
  private _window: WindowService = inject(WindowService);
  private _platform: PlatformService = inject(PlatformService);

  private _state = signal({
    params: this._platform.isBrowser ? this._serializer.parse(this._window.nativeWindow.location.search).queryParams : {} as any,
    domain: this._platform.isBrowser ? this._window.nativeWindow.location.hostname
      .split('.').slice(-2).join('.') : '' as any,
  });

  public params = computed(() => this._state().params);
  public domain = computed(() => this._state().domain);

  /**
   * Detect params from url
   */
  public detectParams() {
    if (!this._platform.isBrowser) {
      return;
    }
    this.resolveStagParam$().pipe(
      switchMap(() => {
        const params = this.params();
        if (params?.test) {
          this._resolveTestParam();
        }
        if (params?.bonuscode) {
          this._cookie.set('bonuscode', params?.bonuscode, 999, '/', this.domain());
        }
        if (params.app) {
          this._cookie.set(NATIVE_APP_COOKIE, '1');
          this._cookie.set(INSTALL_APP_COOKIE, 'true', 1, '/');
        }
        if (params?.nocache) {
          this._resolveNocacheParam();
        }
        if (params?.game) {
          this._resolveGameParam();
        }
        return EMPTY;
      }),
    ).subscribe();
  }

  /**
   * Resolve stag param
   */
  public resolveStagParam$(): Observable<any> {
    if (!this._platform.isBrowser) {
      return;
    }
    const stagValueFromParams = this.params()?.stag?.split('_')[0];

    const stagFromApi$ = this._ssApi.infoAffiliate().pipe(
      map(({ stag }) => stag?.split('_')[0]),
    );

    const stagFromParams$ = stagValueFromParams
      ? this._ssApi.infoLocales({ stag: this.params().stag }).pipe(
        map(() => stagValueFromParams),
      )
      : null;

    const stagUpdate$ = stagFromParams$
      ? stagFromParams$.pipe(
        tap((stagValueFromParams) => {
          this._setStagData(stagValueFromParams);
        }),
      )
      : stagFromApi$.pipe(
        tap((stagValueFromApi) => {
          this._setStagData(stagValueFromApi);
        }),
      );

    return stagUpdate$.pipe(
      finalize(() => {
        if (stagValueFromParams)
          this._updateUrlWithoutParameters();
      }),
    );
  }


  /**
   * Resolve test param
   * @private
   */
  private _resolveTestParam() {
    this._localStorage.set('test', 'true');
    this._updateUrlWithoutParameters();
  }

  /**
   * Resolve nocache param
   * @private
   */
  private _resolveNocacheParam() {
    this._updateUrlWithoutParameters();
  }

  /**
   * Resolve game param
   * @private
   */
  private _resolveGameParam() {
    this._cookie.set('game', this.params()?.game, 999, '/', this.domain());
    this._updateUrlWithoutParameters();
  }

  /**
   * Update url tree
   * @private
   */
  private async _updateUrlWithoutParameters() {
    if (!this._platform.isBrowser) {
      return;
    }
    const tree = this._router.parseUrl(this._location.normalize(this._window.nativeWindow.location.pathname));
    const queryParams = tree.queryParams;
    delete queryParams.param;
    tree.queryParams = queryParams;
    const modalParam = this._serializer.parse(this._window.nativeWindow.location.search)?.queryParams?.modal;
    await this._router.navigateByUrl(modalParam ? tree + '?modal=' + modalParam : tree);
    this._state.update(state => {
      return {
        ...state,
        params: {},
      };
    });
  }

  /**
   * Storage cookie and local stag data
   * @param value
   * @param domain
   * @private
   */
  private _setStagData(value: string) {
    value ?
      this._cookie.set(COOKIE_ID_ID, value, 99, '/', this.domain()) :
      this._cookie.delete(COOKIE_ID_ID, '/', this.domain());

    value ?
      this._cookie.set(HEADER_ID_ID, value, 99, '/', this.domain()) :
      this._cookie.delete(HEADER_ID_ID, '/', this.domain());

    value ?
      this._cookie.set(PTAG_KEY, value, 99, '/', this.domain()) :
      this._cookie.delete(PTAG_KEY, '/', this.domain());

    if (!value) {
      this._cookie.delete(STAG_KEY, '/', this.domain());
    }

    value ?
      this._localStorage.set(STAG_URL_KEY, this._window.nativeWindow.location.pathname) :
      this._localStorage.clearItem(STAG_URL_KEY);

    value ?
      this._localStorage.set(STAG_KEY, value) :
      this._localStorage.clearItem(STAG_KEY);
  }
}
