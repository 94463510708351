import { Injectable, inject } from '@angular/core';
import { CmsApiService } from '../../core/services/api/cms-api.service';
import { CmsContentMapperService } from '../../core/services/cms-content-mapper.service';
import { combineLatest, ReplaySubject } from 'rxjs';
import { UpdateOnLangChange } from '../../core/shared/decorators/update-on-lang-change';
import { map } from 'rxjs/operators';
import { SysNotificationsService } from '../../core/services/sys-notifications.service';
import { decodeHexStr, isHexString } from '../../core/helpers/utils';

export enum TemplateType {
  TEXT = 'text',
  ACCORDION = 'accordion',
  CONTACTS = 'contacts',
  BANKING = 'banking',
  BANKING_V2 = 'banking-wf',
  VIP = 'vip',
  REVIEWS = 'reviews',
  PAYMENT = 'payment'
}

@Injectable({
  providedIn: 'root'
})
export class StaticPageService {
  private _api = inject(CmsApiService);
  private _mapper = inject(CmsContentMapperService);
  private _sysNotification = inject(SysNotificationsService);


  /**
   * Get page item from CMS and prepare for using in frontend
   *
   * @param params
   */
  @UpdateOnLangChange('page/item')
  item(params: object = {}): ReplaySubject<any> {
    return combineLatest([
      this._api.pageItem(params),
      this._api.sysNotificationList({page_slug: params['slug']})
    ]).pipe(
      map(([page, notifications]) => {
        if (page.statusCode === 200) {
          const pageData = this._mapper.mapCmsData(
            isHexString(page.data) ? [decodeHexStr(page.data)] : page.data,{
            template_slug: 'template.slug',
            publishAt: 'publishAt.date',
            unpublishAt: 'unpublishAt.date',
            name: 'name',
            slug: 'slug'
          });
          if (pageData.length) {
            this._sysNotification.checkSysNotification(notifications.data ? notifications.data.list : null, page.data[0].slug);
          }
          return pageData;
        } else {
          return [{
            statusCode: page.statusCode
          }];
        }
      })
    ) as ReplaySubject<any>;
  }
}
