<div class="notifications" [ngClass]="{
    'open': notification.dropdownOpen
  }">
  <div class="notifications__top">
    <i (click)="notification.close()" class="icon-close"></i>
    <div class="notifications__top--counter">
      <div class="notifications__title">{{'t.notifications' | translate}}</div>
      <span [ngClass]="{opened: notification.isOpened}" class="notifications__count">{{lists().length || 0}}</span>
    </div>
  </div>
  <div class="notifications__list">
    @for (notify of lists(); track $index) {
      <app-notification-center-item [notify]="notify"></app-notification-center-item>
    }
  </div>
</div>
