import { Component, OnDestroy, OnInit, inject, input, output } from '@angular/core';
import { FormControl, UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import {merge} from 'rxjs';
import { FormControlsModule } from '../../../../../../../core/modules/form-controls/form-controls.module';
import {
  CurrencySelectorStandaloneComponent
} from '../../../../../../../core/shared/standalone/currency-selector-standalone/currency-selector-standalone.component';
import { FormsErrorHandlerService } from '../../../../../../../core/services/forms-error-handler.service';
import { UserService } from '../../../../../../../core/services/user/user.service';

@UntilDestroy()
@Component({
    selector: 'app-input-currencies-selector',
    templateUrl: './input-currencies-selector.component.html',
    styleUrls: ['./input-currencies-selector.component.scss'],
    imports: [FormControlsModule, FormsModule, ReactiveFormsModule, CurrencySelectorStandaloneComponent]
})
export class InputCurrenciesSelectorComponent implements OnInit, OnDestroy {
  private _formErrors = inject(FormsErrorHandlerService);
  user = inject(UserService);


  /**
   * Amount form control
   */
  readonly control = input<FormControl>(new UntypedFormControl());

  /**
   * Label for input
   */
  readonly label = input<string>(undefined);

  /**
   * Initial value for select
   */
  readonly selectedCurrency = input<string>(undefined);

  /**
   * Disable select
   */
  readonly disableSelect = input<boolean>(undefined);

  /**
   * Emit if change currency select
   */
  readonly changeCurrency = output<any>();

  /**
   * Currency symbol
   */
  currency = this.user.currentCurrency.currency || 'EUR';

  /**
   * List of errors for current control
   */
  errors: Array<string> = [];

  public currencyControl: UntypedFormControl = new UntypedFormControl();

  ngOnInit() {
    this._inputChangeHandle();
    this.currencyControl.setValue(this.user.currentCurrency.currency);
  }

  ngOnDestroy(): void {
  }

  /**
   * Handle form control change
   *
   * @private
   */
  private _inputChangeHandle() {
    this.errors = this._formErrors.errors(this.control());
    merge(
      this.control().statusChanges,
      this.control().valueChanges
    ).pipe(
      untilDestroyed(this),
      tap(() => {
        this.errors = this._formErrors.errors(this.control());
      })
    ).subscribe();
  }

  public onBlur(e) {
    e.target.blur();
  }

  /**
   * Change account currency
   * @param $event
   */
  public changeAccountCurrency($event) {
    if (!$event || $event === this.user.currentCurrency.currency) {
      return;
    }
    this.user.changeCurrencyAcc($event);
  }
}
