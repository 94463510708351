@if (notify) {
  <div class="item {{notify?.notify_channel}}">
    <div class="item__left">
      @if (notify?.icon) {
        <div class="item__img">
          <img [src]="notify?.icon" alt="" [class]="notify?.customClass">
        </div>
      }
      <i class="icon-trash" (click)="notification.removeNotify(notify)"></i>
    </div>
    <div class="item__right">
      <div class="item__title">{{ (notify?.title || notify?.templateTitle) | translate }}</div>
      <div class="item__description" [innerHTML]="notify?.description | safe : 'html'"></div>
      <button (click)="handleRedirect(notify?.link); notification.removeNotify(notify)" class="btn btn--filled-primary">{{notify?.templateTitle | translate}}</button>
    </div>
  </div>
}
