import { Component, inject, OnDestroy, OnInit, viewChildren } from '@angular/core';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import { BottomTermsComponent } from '../../core/shared/standalone/bottom-terms/bottom-terms.component';
import { combineLatest, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { catchError, filter, first, map, share, shareReplay, switchMap, tap } from 'rxjs/operators';
import { BlogCategory } from '../blog/blog-articles-list/blog-articles-list.component';
import { CommonBonusInterface } from '../account/account-settings/account-tab/interfaces/common-bonus.interface';
import { ILoyaltyLevelBonus } from '../../core/services/loyalty/loyalty.interface';
import { BonusStage } from '../../core/services/user/data/user-bonuses.data';
import { SeoService } from '../../core/services/seo.service';
import { OffersService } from '../../core/services/offers.service';
import { StaticPageService } from '../static-page/static-page.service';
import { ResponseHandlerService } from '../../core/services/response-handler.service';
import { LoyaltyService } from '../../core/services/loyalty/loyalty.service';
import { TranslationService } from '../../core/shared/translation/translation.service';
import { ToastMessageService } from '../../core/modules/toast-message/toast-message.service';
import { ModalService } from '../../core/modal-v2/modal.service';
import { LootboxService } from '../../core/services/lootbox/lootbox.service';
import { TimeService } from '../../core/services/time.service';
import { GroupsService } from '../../core/services/groups.service';
import { PlatformService } from '../../core/services/platform.service';
import { BreakpointsDetectorService } from '../../core/services/breakpoints-detector.service';
import { ScrollService } from '../../core/services/scroll.service';
import { BlogService } from '../blog/blog.service';
import { UserPhoneService } from '../../core/services/user/user-phone.service';
import { UserService } from '../../core/services/user/user.service';
import { UserBonusesService } from '../../core/services/user/user-bonuses.service';
import { isNullOrUndefined } from '../../core/helpers/utils';
import { TranslatePipe } from '../../core/shared/translation/translate.pipe';
import { SideNavComponent } from '../../core/shared/layout/side-nav/side-nav.component';
import { BottomTextComponent } from '../../core/shared/layout/bottom-text/bottom-text.component';
import { FooterComponent } from '../../core/shared/layout/footer/footer.component';
import { BottomNavigationComponent } from '../../core/shared/layout/bottom-navigation/bottom-navigation.component';
import { PreloaderComponent } from '../../core/shared/components/preloader/preloader.component';
import { SocialComponent } from './social/social.component';
import { TopSlotsSuperOfferComponent } from './top-slots-super-offer/top-slots-super-offer.component';
import { PhoneVerificationOfferComponent } from './phone-verification-offer/phone-verification-offer.component';
import { NoDepositBonusComponent } from './no-deposit-bonus/no-deposit-bonus.component';
import { BonusPreviewComponent } from '../../core/shared/standalone/bonus-preview/bonus-preview.component';
import { WelcomeBonusesListComponent } from './welcome-bonuses-list/welcome-bonuses-list.component';
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { HeaderComponent } from '../../core/shared/layout/header/header.component';
import {
  NdfsCardBonusPreviewComponent,
} from '../../core/shared/components/ndfs-card-bonus-preview/ndfs-card-bonus-preview.component';
import { StaticContentService } from '../../core/services/static-content.service';

export enum BONUS_TYPES {
  WELCOME = 'welcome',
  RELOAD = 'reload',
  DAILY = 'daily',
  CASH = 'money',
  FREESPINS = 'freespins',
  LOOTBOX = 'lootbox',
  DEPOSIT = 'deposit_bonus',
  BLOG = 'blog'
}

export const USER_HAVE_ONE_DEPOSIT_GROUP = 'ID402';
export const USER_RECEIVED_PHONE_BONUS = 'ID677';


@UntilDestroy()
@Component({
    selector: 'app-bonuses',
    templateUrl: './bonuses.component.html',
    styleUrls: ['./bonuses.component.scss'],
    imports: [HeaderComponent, NgTemplateOutlet, NgClass,  WelcomeBonusesListComponent, BonusPreviewComponent, NoDepositBonusComponent, PhoneVerificationOfferComponent, TopSlotsSuperOfferComponent, SocialComponent, BottomTermsComponent, PreloaderComponent, BottomNavigationComponent, FooterComponent, BottomTextComponent, SideNavComponent, AsyncPipe, TranslatePipe, NdfsCardBonusPreviewComponent]
})
export class BonusesComponent implements OnInit, OnDestroy {
  private _static = inject(StaticContentService);
  private _seo = inject(SeoService);
  private _offers = inject(OffersService);
  private _pages = inject(StaticPageService);
  private _responseHandler = inject(ResponseHandlerService);
  private _loyalty = inject(LoyaltyService);
  private _translate = inject(TranslationService);
  private _toastMessage = inject(ToastMessageService);
  private _modals = inject(ModalService);
  private _lootbox = inject(LootboxService);
  private _time = inject(TimeService);
  private _groups = inject(GroupsService);
  private _platform = inject(PlatformService);
  private _breakpoints = inject(BreakpointsDetectorService);
  private _scroll = inject(ScrollService);
  private _blog = inject(BlogService);
  private _phone = inject(UserPhoneService);
  user = inject(UserService);
  bonuses = inject(UserBonusesService);

  readonly bottomTerms = viewChildren(BottomTermsComponent);

  public page$: Observable<any> = this._pages.item({slug: 'bonuses'}).pipe(
    filter(data => !!data),
    map(data => data[0]),
    map(data => data && !data?.statusCode ? data : this._responseHandler.handleResponseStatus(data?.statusCode)),
    share()
  );

  public bottomText$: ReplaySubject<any> = this._seo.getSeoContentForPage('bonuses');

  public activeTab: any;


  public blogBonuses$ = this._blog.list({
    type: BlogCategory.PROMOTIONS,
  }).pipe(
    filter((data) => !!data && data?.list[0]),
    map((data) => data?.list?.filter(e => e?.ShowOnBonusPage)),
    map((data) => {
      if (!this.user.auth) {
        return data?.filter((e) => !e?.OnlyAuth);
      }
      return data;
    }),
  );


  public reloadBonuses$: Observable<CommonBonusInterface[]> = this.user.auth$.pipe(
    switchMap((auth) => combineLatest([this._offers.list({category_slug: 'samurai-reload-bonuses'}), !auth ? of([]) : this._loyalty.resolveLevels$()])),
    filter((list, loyaltyLevels) => !!list),
    switchMap(([list, loyaltyLevels]: any) => combineLatest([of(list), this.user.auth ? this._loyalty.currentLoyaltyLevel$ : of(null), this.user.auth ? of(loyaltyLevels) : of(null)])),
    map(([list, currentLoyaltyLevel, loyaltyLevels]: any) => {
      if (this.user.auth) {
        if (currentLoyaltyLevel?.taken || currentLoyaltyLevel?.Bonuses?.every(b => b?.taken)) {
          currentLoyaltyLevel = loyaltyLevels[currentLoyaltyLevel?.index];
        }

        const sortedList = list?.sort((a, b) => a?.slug?.includes('loyalty') ? -1 : 1);
        const activeLoyaltyBonus: ILoyaltyLevelBonus = currentLoyaltyLevel?.Bonuses?.find(b => b?.isPreviousActivated) || currentLoyaltyLevel?.Bonuses[0];

        return sortedList?.map(bonus => {
          const isFridayBonus = bonus?.Title?.toLowerCase()?.includes('friday') || bonus?.slug?.toLowerCase()?.includes('friday');
          const isLoyaltyBonus = bonus?.Title?.toLowerCase()?.includes('loyalty') || bonus?.slug?.toLowerCase()?.includes('loyalty');
          return {
            ...bonus,
            isFridayBonus,
            isLoyaltyBonus,
            active: isFridayBonus ? this.bonuses.isAllBonusUsed && new Date().getDay() === 5 : false,
            Description: isLoyaltyBonus && Boolean(activeLoyaltyBonus) ? `<b>${activeLoyaltyBonus?.prize}</b>` || bonus?.Description : bonus?.Description,
            SecondDescription: isLoyaltyBonus && Boolean(activeLoyaltyBonus) && !currentLoyaltyLevel?.slug?.includes('level-1') ? '' : isLoyaltyBonus ? activeLoyaltyBonus?.text : bonus?.SecondDescription,
            games: isLoyaltyBonus && activeLoyaltyBonus ? activeLoyaltyBonus?.games : null,
            unlockMessage: isLoyaltyBonus && activeLoyaltyBonus ? (currentLoyaltyLevel?.nextLockedBonus?.bonus?.unlockMessage || activeLoyaltyBonus.unlockMessage) : null,
            Image: isLoyaltyBonus && activeLoyaltyBonus ? activeLoyaltyBonus?.bigImg : bonus?.Image,
            activeLoyaltyBonus
          }
        });
      } else {
        return list?.map(b => ({...b, isLoyaltyBonus: b?.Title?.toLowerCase()?.includes('loyalty') || b?.slug?.toLowerCase()?.includes('loyalty')}));
      }
    })
  );

  public bonusTypes = BONUS_TYPES;

  public bonusStage = BonusStage;

  public dailyRewardBonus$: Observable<any> = this._offers.list({
    category_slug: 'description-bonus'
  }).pipe(
    filter(list => !!list)
  );

  /**
   * Merged CMS and deposit lists and mark as active bonus
   */
  public welcomeBonusList$: Observable<any> = this.bonuses.getMergedBonuses$().pipe(
    tap((data) => {
      const activeHighroller = data?.highroller?.findIndex(b => b?.active);
      const activeStarter = data?.starter?.findIndex(b => b?.active);
      const isUsedFirstWelcomeBonus = activeHighroller > 0 || activeStarter > 0;
      const isAllBonusesOnlyStarter = data?.starter?.every(b => b?.OnlyStarterBonus === '1');
      this.isHighrollerHidded = isUsedFirstWelcomeBonus || isAllBonusesOnlyStarter;
    }),
  );

  public bonuses$;

  private _updateListSubs: Subscription;

  public isHighrollerHidded: boolean;

  public terms$ = this._pages.item({slug: 'bonus-terms'}).pipe(
    filter(data => !!data),
    map(data => data[0])
  );

  public showPhoneVerificationPromo: boolean;

  public ndfsUser$: Observable<boolean> = this._static.item({ slug: 'ndfs' }).pipe(
    filter(data => !!data),
    map(data => Boolean(data?.length)),
    shareReplay(1)
  );

  constructor() {
    this.updateBonusList();
  }

  ngOnInit() {
    this._resolvePhoneVerification();
  }

  ngOnDestroy() {}

  public onSelectTab(e) {
    this.activeTab = e;
  }

  /**
   * Activate provided bonus
   *
   * @param bonus
   */
  activate(bonus: any) {
    bonus.loading = true;
    let path;
    if (this._isFSBonus(bonus)) {
      path = this.bonuses.activateFreeSpins(bonus.id);
    } else {
      path = this.bonuses.activateBonus(bonus.id);
    }

    path.pipe(
      tap(() => {
        bonus.pending_activation = true;
        bonus.activatable = false;
        this._toastMessage.success('t.few-moments');
        this.bonuses.updateBonuses();
      }),
      catchError(error => {
        this._toastMessage.error('t.error');
        return of(error);
      })
    ).subscribe(() => {
      bonus.loading = false;
    });
  }

  /**
   * Cancel provided bonus
   *
   * @param bonus
   */
  cancel(bonus: any) {
    const modal = this._modals.open('BONUS_CANCEL_CONFIRM');
    let path;
    if (this._isFSBonus(bonus)) {
      path = this.bonuses.cancelFreeSpins(bonus.id);
    } else {
      path = this.bonuses.cancelBonus(bonus.id);
    }

    modal.onResult().pipe(
      first(),
      filter(confirm => !!confirm),
      switchMap(() => {
        bonus.loading = true;
        return path;
      }),
      tap(() => {
        this._toastMessage.success('t.bonus-canceled');
        this.bonuses.updateBonuses();
      }),
      catchError(error => {
        this._toastMessage.error('t.error');
        return of(error);
      })
    ).subscribe(() => {
      bonus.loading = false;
    });
  }

  /**
   * Check is bonus FS
   *
   * @private
   */
  private _isFSBonus(bonus) {
    return bonus.activation_path && bonus.activation_path.includes('freespins');
  }

  /*
Update bonus list
 */
  public updateBonusList() {
    this._updateListSubs?.unsubscribe();
    this._updateListSubs = this.user.auth$.pipe(
      untilDestroyed(this),
      filter(auth => !!auth),
      switchMap(() => combineLatest([this._updateBonusList(), this._lootbox.loadUserLootBox()])),
      switchMap((data) => combineLatest([of(data[0]), this._lootbox.lootIssuedBoxList$])),
      tap(([bonuses, lootboxes]) => {
        Object.values(bonuses).forEach(list => (list as any[])?.sort((prev, next) => prev?.type === 'money' || next?.type === 'money' ? -1 : prev?.type === 'freespins' || next?.type === 'freespins' ? 1 : -1));
        bonuses[this.bonusStage.ISSUED] = [...bonuses[this.bonusStage.ISSUED], ...lootboxes.map((b) => ({
          ...b, endAt: !isNullOrUndefined(b.valid_until) ? this._time.timeDiff(new Date(b.valid_until)) : null
        }))];

        bonuses[this.bonusStage.EXPIRED] = bonuses[this.bonusStage.EXPIRED]?.filter(b => this._time.timeDiff(b?.valid_until || b?.activatable_until)?.days < 7);

        this.bonuses$ = of(bonuses);
      })
    ).subscribe();
  }

  private _updateBonusList() {
    return this.bonuses.updateBonuses();
  }

  /**
   * Cancel lootbox
   * @param lootboxId
   */
  onCancelLootbox(lootboxId: number) {
    this._lootbox.cancelLootbox(lootboxId).pipe(
      tap(() => {
        this._toastMessage.success('t.bonus-canceled');
        this.updateBonusList();
      }),
      catchError(error => {
        this._toastMessage.error('t.error');
        return of(error);
      })
    ).subscribe();
  }

  /**
   * Open lootbox modal
   * @param lootboxIssued
   */
  async onOpenLootbox(lootboxIssued: boolean) {
    if (lootboxIssued) {
      const component = await import('../../core/modal-v2/components/lazy/lootbox/lootbox.component');
      const modal = await this._modals.openLazy(component?.LootboxComponent, {
        template: 'CLEAR',
        data: {lootbox: lootboxIssued}
      });
      modal.onResult().pipe(
        tap(() => {
          if (this._lootbox.currentLootboxPrize) {
            this._lootbox.currentLootboxPrize = null;
            this._updateBonusList();
          }
        })
      ).subscribe();
    }
  }

  public onDetailsClick() {
    if (this._platform.isBrowser) {
      this.bottomTerms()?.forEach(t => t?.open());
      const element: HTMLElement = document.querySelector('h4.top-slots');
      if (element) {
        this._scroll.scrollToElement(element, 1000, false, 0, -100);
      }
    }
  }

  private _resolvePhoneVerification() {
    this.user.auth$.pipe(
      switchMap(auth => auth ? this._phone.getPhoneList() : of(true)),
      tap(() => {
        const isMoreTwoDays = this._checkDifferenceMoreTwoDays();
        const isUserHaveDepositPhoneGroup = this._groups.isExistGroup(USER_HAVE_ONE_DEPOSIT_GROUP);
        const isUserReceivedPhoneBonus = this._groups.isExistGroup(USER_RECEIVED_PHONE_BONUS);
        this.showPhoneVerificationPromo = !this._phone.state().isHasVerified && !isUserReceivedPhoneBonus && isUserHaveDepositPhoneGroup && this.user.auth && isMoreTwoDays;
      })
    ).subscribe();
  }

  private _checkDifferenceMoreTwoDays() {
    if (!this.user.info.created_at) {
      return true;
    } else {
      const today = new Date();
      // ios problem handling
      const dateString = this.user.info.created_at.replace(" ", "T").replace('UTC', '').trim() + 'Z';
      const dateRegister = new Date(dateString);
      const millisecondsDiff = today.getTime() - dateRegister.getTime();
      return Math.round(millisecondsDiff / (24 * 60 * 60 * 60)) >= 2;
    }
  }
}
