import { Component, OnInit, inject } from '@angular/core';
import { ReplaySubject, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SsApiService } from 'src/app/core/services/api/ss-api.service';
import { PlatformService } from 'src/app/core/services/platform.service';
import { StaticPageService } from 'src/app/page/static-page/static-page.service';
import { ModalContent, ModalRef } from '../../../modal-ref';
import {CommonModule} from '@angular/common';
import { TabsComponent } from '../../../../modules/tabs/tabs.component';
import { TabItemComponent } from '../../../../modules/tabs/tab-item.component';
import { TranslatePipe } from '../../../../shared/translation/translate.pipe';
import { PreloaderComponent } from '../../../../shared/components/preloader/preloader.component';
import { SafePipe } from '../../../../shared/pipes/safe.pipe';


export enum Tab {
  TERMS = 'terms',
  POLICY = 'policy'
}

@Component({
    selector: 'app-terms-acceptance-form-modal',
    templateUrl: './terms-acceptance-form-modal.component.html',
    styleUrls: ['./terms-acceptance-form-modal.component.scss'],
    imports: [
        CommonModule,
        TabsComponent,
        TabItemComponent,
        TranslatePipe,
        PreloaderComponent,
        SafePipe,
    ]
})
export class TermsAcceptanceFormModalComponent implements OnInit, ModalContent {
  private _page = inject(StaticPageService);
  private _api = inject(SsApiService);
  private _platform = inject(PlatformService);


  /**
   * Access to parent modal component
   */
  public modal: ModalRef;

  /**
   * Terms page observable
   */
  public terms$: ReplaySubject<any> = this._page.item({
    slug: 'terms-and-conditions'
  });

  /**
   * Terms page observable
   */
  public policy$: ReplaySubject<any> = this._page.item({
    slug: 'privacy-policy'
  });

  /**
   * Is loading
   */
  public loading: boolean;

  /**
   * Active tab
   */
  public currentTab: string;

  get Tab() { return Tab; }

  ngOnInit() {
    this.currentTab = this.modal.options.data?.tab || Tab.POLICY;
  }

  decline() {
    this.loading = true;
    this._api.usersSignOut().pipe(
      catchError(error => of(error))
    ).subscribe(() => {
      this.loading = true;

      if (this._platform.isBrowser) {
        window.location.href = '/';
      }
    });
  }

  accept() {
    this.loading = true;
    this._api.authProvidersUpdateDetails({
      user: {
        terms_acceptance: true
      }
    }).pipe(
      catchError(error => of(error))
    ).subscribe(() => {
      this.loading = false;
      this.modal.options.data.user.fetchAllUserData();
      this.modal.close();
    });
  }
}
