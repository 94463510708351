<div class="terms-block">
  <div class="accordion">
    <div class="accordion__header" Accordion>
      <span class="accordion__header-label">{{accordionTitle() | translate}}</span>
      <i class="accordion__header-icon icon-pointer-down"></i>
    </div>
    <div class="accordion__content">
      <div class="accordion__content-container" [innerHTML]="terms() | safe:'html'"></div>
    </div>
  </div>
</div>
