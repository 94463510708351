import { Component, ElementRef, inject } from '@angular/core';

@Component({
    selector: 'app-select-header',
    templateUrl: './select-header.component.html',
    styleUrls: ['./select-header.component.scss'],
    imports: []
})
export class SelectHeaderComponent {
  el = inject(ElementRef);


  public placeholder: boolean;

  public value: string;

}
