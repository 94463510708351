import { Component, OnInit, inject } from '@angular/core';
import {ModalContent, ModalRef} from '../../../modal-ref';
import {BreakpointsDetectorService} from '../../../../services/breakpoints-detector.service';
import {StaticContentService} from '../../../../services/static-content.service';
import {Observable, of} from 'rxjs';
import {catchError, filter, map} from 'rxjs/operators';
import {GamesService} from '../../../../services/games/games.service';
import {UserService} from '../../../../services/user/user.service';
import {Router} from '@angular/router';
import {LanguageService} from '../../../../services/language/language.service';
import {
  GoogleTagManagerService,
  POPUP_EVENT_ACTIONS,
  POPUP_EVENT_CATEGORY
} from '../../../../services/google-tag-manager.service';
import { SafePipe } from '../../../../shared/pipes/safe.pipe';
import { TranslatePipe } from '../../../../shared/translation/translate.pipe';
import { PreloaderComponent } from '../../../../shared/components/preloader/preloader.component';
import { DefaultImage } from '../../../../shared/directives/default-image.directive';
import { ClassLoadedDirective } from '../../../../shared/directives/class-loaded.directive';
import { SetImgAltDirective } from '../../../../shared/directives/set-img-alt.directive';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-deposit-bonus-info',
    templateUrl: './deposit-bonus-info.component.html',
    styleUrls: ['./deposit-bonus-info.component.scss'],
    imports: [SetImgAltDirective, ClassLoadedDirective, DefaultImage, PreloaderComponent, AsyncPipe, TranslatePipe, SafePipe]
})
export class DepositBonusInfoComponent implements OnInit, ModalContent {
  breakpoints = inject(BreakpointsDetectorService);
  private _static = inject(StaticContentService);
  private _games = inject(GamesService);
  private _user = inject(UserService);
  private _router = inject(Router);
  private _lang = inject(LanguageService);
  private _gtm = inject(GoogleTagManagerService);


  /**
   * Access to parent modal
   */
  public modal: ModalRef;

  public modalInfo$: Observable<any> = this._static.item({slug: 'ss-first-deposit-info-modal'}).pipe(
    filter(data => !!data),
    map(data => data[0]),
    map(data => {
      return {
        ...data,
        Winners: Object.values(data?.Winners).map((winner: any) => ({
          ...winner,
          game$: this._games.getGameByExternalId(winner?.game)
        }))
      };
    }),
    catchError(err => {
      this.modal.close();
      return of(err);
    })
  );


  ngOnInit() {
    this._gtm.popupEvent(
      POPUP_EVENT_CATEGORY.DEPOSIT_BONUS_INFO,
      POPUP_EVENT_ACTIONS.DEPOSIT_BONUS_INFO
    );
  }

  /**
   * On deposit button click
   */
  public async onDepositBtnCLick() {
    if (this._user.auth) {
      await this._router.navigate(['/', this._lang.current,  'profile', 'deposit']);
    } else {
      await this._user.authUser();
    }
  }
}
