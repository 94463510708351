import { PlatformService } from './platform.service';
import { Injectable, inject } from '@angular/core';
import { WindowService } from './window.service';

interface Storage {
  getItem(key: string): string | null;
  setItem(key: string, value: string): void;
  removeItem(key: string): void;
}

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {
  private _platform = inject(PlatformService);
  private _window = inject(WindowService);


  private readonly _storage: Storage;

  public constructor() {
    this._storage = this._platform.isBrowser ? this._window?.nativeWindow?.localStorage : null;
  }

  get(key: any): string | null {
    return this._storage?.getItem(key);
  }

  set(key: any, value: string): void {
    this._storage?.setItem(key, value);
  }

  clearItem(key: any): void {
    this._storage?.removeItem(key);
  }

  clearItems(keys: any[]): void {
    keys.forEach((key) => this.clearItem(key));
  }
}
