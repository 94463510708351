import { Component, inject, input, OnInit } from '@angular/core';
import { SideNavService } from '../side-nav/side-nav.service';
import { UserService } from '../../../services/user/user.service';
import { BadgeService } from '../../../services/badge.service';
import { LanguageService } from '../../../services/language/language.service';
import { SeoService } from '../../../services/seo.service';
import { TranslatePipe } from '../../translation/translate.pipe';
import { SetImgAltDirective } from '../../directives/set-img-alt.directive';
import { HeaderBalanceComponent } from './header-balance/header-balance.component';
import { HeaderLoginFormComponent } from './header-login-form/header-login-form.component';
import { AppShellRenderSSRDirective } from '../../directives/app-shell/app-shell-render-ssr.directive';
import {
  NotificationCenterDropDownComponent,
} from '../../../modules/notification-center/notification-center-drop-down/notification-center-drop-down.component';
import { AppShellNoSSRDirective } from '../../directives/app-shell/app-shell-no-ssr.directive';
import { RouterLinkDirective } from '../../directives/router-link.directive';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { WindowService } from '../../../services/window.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
    imports: [NgClass, RouterLink, RouterLinkDirective, AppShellNoSSRDirective, NotificationCenterDropDownComponent, NgTemplateOutlet, AppShellRenderSSRDirective, HeaderLoginFormComponent, HeaderBalanceComponent, RouterLinkActive, SetImgAltDirective, TranslatePipe]
})
export class HeaderComponent implements OnInit {
  sidebar = inject(SideNavService);
  user = inject(UserService);
  badge = inject(BadgeService);
  lang = inject(LanguageService);
  seo = inject(SeoService);
  private _window = inject(WindowService);


  readonly title = input<string>(undefined);

  readonly links = input<Array<{
    img: string;
    url: string;
    tab: string;
}>>([]);

  ngOnInit(): void {
    this._window.scrollForIOS();
  }
}
