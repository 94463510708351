import { computed, inject, Injectable, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { SsApiService } from '../api/ss-api.service';
import { switchMap, tap } from 'rxjs/operators';
import { EnvironmentService } from '../environment.service';
import { SsPaymentsV2Service } from '../../vendor/ss-payments-v2/ss-payment.service';
import { CORRECTED_PHONE_NUMBERS } from 'ngx-unificator/helpers';
import { NgModel } from '@angular/forms';

export enum PhoneStatus {
  VERIFIED = 'verified',
  UNVERIFIED = 'unverified'
}
export interface PhoneState {
  isHasVerified: boolean;
  isDynamicShowMaskTyped: boolean;
  phonePrefix: string;
  phoneMask: string;
  dynamicPhoneMask: string;
  replacedPhoneMaskLength: number;
}

@Injectable({
  providedIn: 'root'
})
export class UserPhoneService {
  public state = computed(() => this._state());

  private _state = signal<PhoneState>({
    isHasVerified: false,
    isDynamicShowMaskTyped: true,
    replacedPhoneMaskLength: 0,
    phonePrefix: '',
    phoneMask: '',
    dynamicPhoneMask: '',
  });

  private _api = inject(SsApiService);
  private _env = inject(EnvironmentService);
  private _ssPayments = inject(SsPaymentsV2Service);

  constructor() {}

  /**
   * Returns list of player phone numbers
   */
  /**
   * Returns list of player phone numbers
   */
  getPhoneList(): Observable<any> {
    return this._api.playerPhone().pipe(
      tap(list => {
        this._state.update(store => {
          return {
            ...store,
            isHasVerified: list.some(phone =>
              phone.verified_status === PhoneStatus.VERIFIED),
          };
        });
      }),
    );
  }

  public updateMask(value: string) {
    if (value?.length >= this.state().replacedPhoneMaskLength) {
      this._updateDynamicPhoneMask(this.state().phoneMask + '*');
      this._updateDynamicMaskState(false);
    } else {
      this._updateDynamicPhoneMask(this.state().phoneMask);
      this._updateDynamicMaskState(true);
    }
  }

  public handlePaste(event: ClipboardEvent, inputModel: NgModel) {
    event.preventDefault();
    const pastedText: any = event.clipboardData?.getData('text') || '';

    if (pastedText.length >= this.state().replacedPhoneMaskLength) {
      this._updateDynamicPhoneMask(this.state().phoneMask + '*');
      this._updateDynamicMaskState(false);
    }
    inputModel.control.setValue(pastedText, { emitEvent: true });
  }


  /**
   * Add user phone
   *
   * @param data
   */
  addPhone(data: object): Observable<any> {
    return this._api.postPlayerPhone({
      phone: data
    }).pipe(
      switchMap(() => this._ssPayments.resetCache())
    );
  }

  /**
   * Delete user phone
   */
  deletePhone(): Observable<any> {
    return this._api.deletePlayerPhone().pipe(
      switchMap(() => this._ssPayments.resetCache())
    );
  }

  /**
   * Verify player [hone
   *
   * @param code
   */
  verifyPhone(code: string): Observable<any> {
    return this._api.playerPhoneConfirm({
      phone: {
        code
      }
    });
  }

  public updateMainEntities(callingCode: string, short: string) {
    this._updatePrefixStore(callingCode);
    this._updateMaskStore(short);
    this._updateDynamicMaskStore();
    this._updateReplacePhoneMask();
  }

  public updatePhoneStoreMask() {
    this._env.env$.pipe(
      tap(({ data }) => {
        this.updateMainEntities(
          data?.country?.callingCode,
          data?.country?.short);
      }),
    ).subscribe();
  }

  private _updatePrefixStore(callingCode: string) {
    this._state.update(store => {
      return {
        ...store,
        phonePrefix: `+${callingCode} `,
      };
    });
  }

  private _updateMaskStore(country: string) {
    this._state.update(store => {
      return {
        ...store,
        phoneMask: this._getPhoneExampleMask(country),
      };
    });
  }


  private _updateDynamicMaskStore() {
    this._state.update(store => {
      return {
        ...store,
        dynamicPhoneMask: store.phoneMask,
      };
    });
  }

  private _updateReplacePhoneMask() {
    this._state.update(store => {
      return {
        ...store,
        replacedPhoneMaskLength: store.phoneMask.replace(/ /g, '').length,
      };
    });
  }

  private _updateDynamicPhoneMask(dynamicPhoneMask: string) {
    this._state.update(store => {
      return {
        ...store,
        dynamicPhoneMask,
      };
    });
  }

  private _updateDynamicMaskState(isDynamicShowMaskTyped: boolean) {
    this._state.update(store => {
      return {
        ...store,
        isDynamicShowMaskTyped,
      };
    });
  }

  private _getPhoneExampleMask(country: any): string {
    const exampleNumber = CORRECTED_PHONE_NUMBERS[country?.toUpperCase()];
    const strippedNumber = exampleNumber.replace(`${this.state().phonePrefix}`, '').trim();
    return `${strippedNumber.replace(/\d/g, '0')}`;
  }
}
