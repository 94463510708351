import { Injectable, inject } from '@angular/core';
import { CmsApiService } from './api/cms-api.service';
import { forkJoin } from 'rxjs';
import { UserService } from './user/user.service';
import { LanguageService } from './language/language.service';

export enum WATCHED_FOR_OPEN {
  GAMES = 'game-list-open',
  TOURNAMENTS = 'tournament-list-open',
  OFFERS = 'promotion-list-open'
}

@Injectable({
  providedIn: 'root'
})
export class BadgeService {
  private _api = inject(CmsApiService);
  _user = inject(UserService);
  _lang = inject(LanguageService);


  private _gameBadge = null;
  private _promotionBadge = null;
  private _tournamentBadge = null;

  public get langBadge() {
    return this._lang.langBadge;
  }

  public get gameBadge() {
    return this._user.auth && this._gameBadge;
  }

  public get offersBadge() {
    return this._user.auth && this._promotionBadge;
  }

  public get tournamentBadge() {
    return this._user.auth && this._tournamentBadge;
  }

  public get anyBadge() {
    return this.gameBadge || this.offersBadge || this.tournamentBadge;
  }

  public get sumBadges() {
    return this.gameBadge + this.offersBadge + this.tournamentBadge + this.langBadge;
  }

  /**
   * Load all badges by main Entitys
   */
  private _checkBadgeUpdates() {
    forkJoin([
      this._api.gameBadgeList(),
      this._api.promotionBadgeList(),
      this._api.tournamentBadgeList()
    ])
    .subscribe(([gameBadge, promotionBadge, tournamentBadge]) => {
      this._gameBadge = gameBadge?.isUpdated || gameBadge?.data?.isUpdated;
      // this._promotionBadge = promotionBadge.data.isUpdated;
      this._tournamentBadge = tournamentBadge?.isUpdated || tournamentBadge?.data?.isUpdated;
    });
  }

  /**
   * Load & show badges in menu if user auth
   */
  public loadBadgeInfo() {
    if (this._user.auth) {
      this._checkBadgeUpdates();
    }
  }

  /**
   * Send POST request if user watched some entity list
   * @param slug
   * @param params
   */
  public openListEntity(slug: string) {
    if (this._user.auth) {
      this._api.postListEntityOpen(slug).subscribe(() => this.deleteBadgeOn(slug));
    }
  }

  /**
   * Delete badge on item by slug
   * @param slug
   */
  public deleteBadgeOn(slug) {
    switch (slug) {
      case WATCHED_FOR_OPEN.GAMES :
        this._gameBadge = null;
        break;
      case WATCHED_FOR_OPEN.TOURNAMENTS :
        this._tournamentBadge = null;
        break;
      case WATCHED_FOR_OPEN.OFFERS :
        this._promotionBadge = null;
        break;
    }
  }
}
