import { AfterViewInit, Component, OnChanges, OnDestroy, OnInit, SimpleChanges, inject, input, viewChildren, viewChild } from '@angular/core';
import { NEVER, Observable, of } from 'rxjs';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AccordionDirective } from '../../../../../../core/shared/directives/accordion.directive';
import { UserService } from '../../../../../../core/services/user/user.service';
import { LotteryService } from '../../../../../../core/services/lottery.service';
import { UserBonusesService } from '../../../../../../core/services/user/user-bonuses.service';
import { ToastMessageService } from '../../../../../../core/modules/toast-message/toast-message.service';
import { FormsErrorHandlerService } from '../../../../../../core/services/forms-error-handler.service';
import { LocalstorageService } from '../../../../../../core/services/localstorage.service';
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { SliderComponent } from '../../../../../../core/modules/slider/slider.component';
import { TranslatePipe } from '../../../../../../core/shared/translation/translate.pipe';
import { SafePipe } from 'ngx-unificator/pipes';
import { PreloaderComponent } from '../../../../../../core/shared/components/preloader/preloader.component';
import {
  FormCheckboxComponent,
} from '../../../../../../core/modules/form-controls/form-checkbox/form-checkbox.component';
import { FormInputComponent } from '../../../../../../core/modules/form-controls/form-input/form-input.component';
import { CookieService } from 'ngx-unificator/services';

const STORAGE_BONUSES_NAME = '--deposit-bonuses';

@Component({
    selector: 'app-deposit-bonus',
    templateUrl: './deposit-bonus.component.html',
    styleUrls: ['./deposit-bonus.component.scss'],
    imports: [
        AsyncPipe,
        SliderComponent,
        NgClass,
        TranslatePipe,
        SafePipe,
        NgTemplateOutlet,
        PreloaderComponent,
        FormCheckboxComponent,
        ReactiveFormsModule,
        AccordionDirective,
        FormInputComponent
    ]
})
export class DepositBonusComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  user = inject(UserService);
  lottery = inject(LotteryService);
  private _toastMessage = inject(ToastMessageService);
  private _userBonus = inject(UserBonusesService);
  private _fb = inject(UntypedFormBuilder);
  private _formErrors = inject(FormsErrorHandlerService);
  private _storage = inject(LocalstorageService);
  private _cookie = inject(CookieService);


  readonly bonusCode = input<string>(undefined);

  readonly _accordion = viewChildren(AccordionDirective);

  readonly bonusAccordion = viewChild('bonusAccordion', { read: AccordionDirective });

  public bonusList$: Observable<any> = NEVER;
  public bonuscodeForm: UntypedFormGroup;

  public showSlider;

  public depositBonuses$;

  sliderConfig = {
    loop: true,
    breakpoints: {
      '(max-width: 720px)': {
        slides: {
          perView: 1,
          spacing: 12,
        },
      },
      '(min-width: 721px)': {
        slides: {
          spacing: 10,
        },
      },
    }
  };

  ngOnInit() {
    this._initBonuscodeForm(this._cookie.get('bonuscode')?.trim());
    this._getActiveBonuses();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.bonusCode && changes.bonusCode.firstChange && changes.bonusCode.currentValue) {
      const bonusCode = changes.bonusCode.currentValue.trim();
      this._initBonuscodeForm(bonusCode);
    }
  }

  ngAfterViewInit() {
    const bonusCode = this.bonusCode();
    if (bonusCode || this.user.info.deposit_bonus_code || this._cookie.get('bonuscode')?.trim()) {
      this.bonuscodeForm.get('code').setValue(bonusCode || this._cookie.get('bonuscode')?.trim() || this.user.info.deposit_bonus_code);
      this.onBonuscodeFormSubmit(bonusCode || this._cookie.get('bonuscode')?.trim() || this.user.info.deposit_bonus_code);
      this.bonusAccordion().open();
    }
  }

  ngOnDestroy() {
    this._storage.clearItem(STORAGE_BONUSES_NAME);
  }

  /**
   * Change issue bonus
   */
  onChangeIssueBonus(event) {
    this._userBonus
      .changeIssues(event.target.checked)
      .pipe(
        tap(() => this._getActiveBonuses()),
        switchMap(() => this.user.getUserInfo()),
        switchMap(() => this._userBonus.depositBonusList()),
      )
      .subscribe();
  }

  /**
   * Send deposit bonus code
   */
    onBonuscodeFormSubmit(bonusCode = '') {
    this._userBonus.activateDepositBonusCode(bonusCode || this.bonuscodeForm.get('code').value).pipe(
      catchError(error => {
        this._toastMessage.error(this._formErrors.ssBackendErrorsToArray(error.error)[0] || 't.went-wrong');
        return of(error);
      }),
      switchMap(() => this.user.getUserInfo()),
      tap(() => {
        this.bonuscodeForm.get('code').setValue('');
        this._updateBonusList();
        this._updateBonuscodeForm();
        this._toastMessage.success('t.bonus-code-added');
      }),
      delay(3000),
      switchMap(() => this.bonusList$),
      tap((list) => {
        if (!list.length) {
          this._toastMessage.success('t.bonus-activated-msg');
        }
      }),
    ).subscribe();
  }

  /**
   * Delete deposit bonus code
   */
  deleteDepositeBonus(bonusCode = '') {
    this._userBonus.deleteDepositBonusCode().pipe(
      switchMap(() => this.user.getUserInfo()),
      tap(() => {
        this._updateBonusList();
        this._updateBonuscodeForm();

        if (bonusCode) {
          this.onBonuscodeFormSubmit(bonusCode);
        }
      })
    ).subscribe();
  }

  private _initBonuscodeForm(bonusCode: string = '') {
    if (!this.bonuscodeForm) {
      this.bonuscodeForm = this._fb.group({
        code: [bonusCode]
      });
    }
  }

  private _updateBonusList() {
    this.bonusList$ = NEVER;
    this.bonusList$ = this._userBonus.depositBonusList();
    this._getActiveBonuses();
  }

  /**
   * Update bonus code value from user info
   */
  private _updateBonuscodeForm() {
    const input = this.bonuscodeForm.get('code');
    if (this.user.info.deposit_bonus_code) {
      input.setValue(this.user.info.deposit_bonus_code);
      input.disable();
    } else {
      input.setValue('');
      input.enable();
    }
  }

  private _getActiveBonuses() {
    this.showSlider = false;
    this.depositBonuses$ = this._userBonus.depositBonusList().pipe(
      map(bonuses => {
        if (bonuses?.length) {
          this._storage.set(STORAGE_BONUSES_NAME, JSON.stringify(bonuses));
          return bonuses;
        }
        const storageBonuses = this._storage.get(STORAGE_BONUSES_NAME);
        return storageBonuses ? JSON.parse(storageBonuses) : [];
      }),
      tap(() => {
        setTimeout(() => {
          this.showSlider = true;
        }, 600);
      })
    );
  }
}
