import { Injectable, inject } from '@angular/core';
import {WindowSizeService} from "./window-size.service";

const MOBILE_MAX_WIDTH = 767.98;
const TABLET_MAX_WIDTH = 991.98;
const SMALL_DESKTOP_MAX_WIDTH = 1199.98;

@Injectable({
  providedIn: 'root'
})

export class BreakpointsDetectorService {
  private _windowSize = inject(WindowSizeService);


  private _windowWidth = this._windowSize.newWindowSize.value.width;
  private _subscription;

  private _isMobile: boolean;
  private _isTablet: boolean;
  private _isSmallDesktop: boolean;
  private _isDesktop: boolean;

  private _belowDesktop: boolean;
  private _belowSmallDesktop: boolean;
  private _belowTablet: boolean;
  private _aboveTablet: boolean;

  constructor() {
    this._subscription = this._windowSize.newWindowSize.subscribe(
      value => {
        this._windowWidth = value.width;
        this._updateBreakpoints();
      }
    );
  }

  /**
   * Screen lass then MOBILE_MAX_WIDTH
   */
  public get isMobile(): boolean {
    return this._isMobile;
  }

  /**
   * Screen lass then TABLET_MAX_WIDTH and more then MOBILE_MAX_WIDTH
   */
  public get isTablet(): boolean {
    return this._isTablet;
  }

  /**
   * Screen lass then SMALL_DESKTOP_MAX_WIDTH and more then TABLET_MAX_WIDTH
   */
  public get isSmallDesktop(): boolean {
    return this._isSmallDesktop;
  }

  /**
   * Screen grater then SMALL_DESKTOP_MAX_WIDTH
   */
  public get isDesktop(): boolean {
    return this._isDesktop;
  }

  /**
   * Screen lass then SMALL_DESKTOP_MAX_WIDTH
   */
  public get belowDesktop(): boolean {
    return this._belowDesktop;
  }

  /**
   * Screen lass then TABLET_MAX_WIDTH
   */
  public get belowSmallDesktop(): boolean {
    return this._belowSmallDesktop;
  }

  /**
   * Screen lass then MOBILE_MAX_WIDTH
   */
  public get belowTablet(): boolean {
    return this._belowTablet;
  }

  /**
   * Screen more then TABLET_MAX_WIDTH
   */
  public get aboveTablet(): boolean {
    return this._aboveTablet;
  }

  /**
   * Update breakpoint values
   *
   * @private
   */
  private _updateBreakpoints() {
    this._isMobile = this._windowWidth <= MOBILE_MAX_WIDTH;
    this._isTablet = this._windowWidth <= TABLET_MAX_WIDTH && this._windowWidth > MOBILE_MAX_WIDTH;
    this._isSmallDesktop = this._windowWidth <= SMALL_DESKTOP_MAX_WIDTH && this._windowWidth > TABLET_MAX_WIDTH;
    this._isDesktop = this._windowWidth > SMALL_DESKTOP_MAX_WIDTH;

    this._belowDesktop = this._windowWidth <= SMALL_DESKTOP_MAX_WIDTH;
    this._belowSmallDesktop = this._windowWidth <= TABLET_MAX_WIDTH;
    this._belowTablet = this._windowWidth <= MOBILE_MAX_WIDTH;
    this._aboveTablet = this._windowWidth > TABLET_MAX_WIDTH;
  }
}
