import { Component, input, model, TemplateRef } from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'app-tab-item',
    styles: [
        `.tab-item {
      width: 100%;
    }
    `
    ],
    template: `
    <div [hidden]="!active()" class="tab-item">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>

    <ng-template #content>
      <ng-content></ng-content>
    </ng-template>
  `,
    imports: [NgTemplateOutlet]
})
export class TabItemComponent {
  public title: string = null;
  public titleTpl: TemplateRef<any> = null;

  /**
   * @Inputs
   */
  public badge = input<string | number>('', {
    alias: 'badge',
  });
  public active = model(false, {
    alias: 'active',
  });
  public disabled = input(false, {
    alias: 'disabled',
  });
  public alt = input('', {
    alias: 'alt',
  });
  public id = input<number | string>('', {
    alias: 'id',
  });
  public data = input(null, {
    alias: 'data',
  });
  public tabTitle = input<string | TemplateRef<any>, string | TemplateRef<any>>(null, {
    alias: 'tabTitle',
    transform: (value: string | TemplateRef<any>) => {
      if (value instanceof TemplateRef) {
        this.titleTpl = value;
      } else {
        this.title = value;
      }
      return value;
    }
  });

  constructor() {

  }


}
