import { Injectable, inject } from '@angular/core';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root'
})
export class FlashPlayerService {
  private _platform = inject(PlatformService);


  private readonly FLASHPLAYER_ACTIVATION_URL = 'https://get.adobe.com/flashplayer/';

  /**
   * Access to activation link from outside
   */
  get activationUrl(): string {
    return this.FLASHPLAYER_ACTIVATION_URL;
  }

  /**
   * Returns true if flash player installed
   */
  flashPlayerEnabled(): boolean {
    return this._platform.isBrowser && !!navigator.plugins.namedItem('Shockwave Flash');
  }
}
