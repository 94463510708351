import { Component, inject, output } from '@angular/core';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {StaticContentService} from '../../../core/services/static-content.service';
import {UserService} from '../../../core/services/user/user.service';
import {Router} from '@angular/router';
import {PlatformService} from '../../../core/services/platform.service';
import {LanguageService} from '../../../core/services/language/language.service';
import {CopyTextService} from '../../../core/services/copy-text.service';
import {ArabicService} from '../../../core/services/arabic.service';
import {BreakpointsDetectorService} from '../../../core/services/breakpoints-detector.service';
import { TranslatePipe } from '../../../core/shared/translation/translate.pipe';
import { SafePipe } from '../../../core/shared/pipes/safe.pipe';
import { SetImgAltDirective } from '../../../core/shared/directives/set-img-alt.directive';
import { NgClass, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-top-slots-super-offer',
    templateUrl: './top-slots-super-offer.component.html',
    styleUrls: ['./top-slots-super-offer.component.scss'],
    imports: [SetImgAltDirective, NgClass, AsyncPipe, SafePipe, TranslatePipe]
})
export class TopSlotsSuperOfferComponent {
  private _static = inject(StaticContentService);
  private _user = inject(UserService);
  private _router = inject(Router);
  private _platform = inject(PlatformService);
  private _lang = inject(LanguageService);
  private _copy = inject(CopyTextService);
  rtl = inject(ArabicService);
  breakpoints = inject(BreakpointsDetectorService);


  readonly detailsClick = output<any>();

  public offer$: Observable<{
    Title: string,
    Description: string,
    Image: string,
    ImageMob: string,
    ImageRedesign: string,
    ImageRedesignMob: string
  }> = this._static.item({slug: 'top-slots-super-offer'}).pipe(
    filter(offer => !!offer?.length),
    map(offer => {
      offer[0].Description = offer[0].Description.replace(/<img class="copy-code" src="\/assets\/img\/top-slots-offer\/copy.png">/g, '');
      offer[0].Description = offer[0].Description.replace(/<img class="copy-code" src="\/assets\/img\/top-slots-offer\/copy-rtl.png">/g, '');
      offer[0].Description = offer[0].Description.replace(/<\/strong>/g,
        !this.rtl.isAr ? '</strong> <img class="copy-code" src="/assets/img/top-slots-offer/copy.png">' : '</strong> <img class="copy-code" src="/assets/img/top-slots-offer/copy-rtl.png">'
      );
      return offer[0];
    }),
  );

  public async onDepositClick() {
    if (this._user.auth) {
      await this._router.navigateByUrl('/deposit');
    } else {
      await this._user.authUser();
    }
  }

  public onCodeClick($event) {
    if (this._platform.isBrowser) {
      const target = $event?.target;
      const previousElementSibling = target?.previousElementSibling;
      if (target?.tagName === 'IMG' && previousElementSibling?.tagName === 'STRONG') {
        if (this._user.auth) {
          const bonusCode = previousElementSibling?.innerHTML?.trim();
          if (bonusCode) {
            this._copy.copy(previousElementSibling, bonusCode);
          }
        } else {
          this._user.authUser().then();
        }
      } else if (target?.tagName === 'STRONG') {
        if (this._user.auth) {
          const bonusCode = target?.innerHTML?.trim();
          if (bonusCode) {
            this._router.navigate(['/', this._lang.current,  'profile', 'deposit'], {
              queryParams: {
                bonusCode,
              }
            }).then();
          }
        } else {
          this._user.authUser().then();
        }
      }
    }
  }
}
