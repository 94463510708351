import { Injectable, inject } from '@angular/core';
import { SsApiService } from '../api/ss-api.service';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { CommonDataService } from '../common-data.service';
import { GamesService } from '../games/games.service';
import { FiltersService } from '../filters.service';
import { CmsTransactionsService } from '../cms-transactions.service';
import { LocalstorageService } from '../localstorage.service';
import { TimeService } from '../time.service';
import { PaymentsMethod } from '../../vendor/ss-payments-v2/ss-payment-types';
import { TransactionLimit } from '../../../../environments/environment';

export enum TRANSACTIONS_TYPES {
  CASHOUT = 'cashout',
  DEPOSIT = 'deposit',
}

/**
 * Available transaction statuses (for frontend only)
 */
export enum TransactionStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  DISCARDED = 'discarded'
}

@Injectable({
  providedIn: 'root'
})
export class UserTransactionsService {
  private _api = inject(SsApiService);
  private _data = inject(CommonDataService);
  private _games = inject(GamesService);
  private _filters = inject(FiltersService);
  private _cmsTransactions = inject(CmsTransactionsService);
  private _storage = inject(LocalstorageService);
  private _time = inject(TimeService);


  private _isTransactionDisabled: boolean;

  public transactionList$: BehaviorSubject<any> = new BehaviorSubject<[]>([]);

  get TransactionStatus() {
    return TransactionStatus;
  }

  get TransactionAction() {
    return TRANSACTIONS_TYPES;
  }

  get isTransactionTemporaryDisabled() {
    return this._isTransactionDisabled;
  }

  /**
   * Returns users transactions list
   */
  transactionList(): Observable<any> {
    return forkJoin([
      this._api.playerPayments(),
      this._cmsTransactions.isStreamer ? this._cmsTransactions.getTransactions() : of({data: {list: []}})
    ]).pipe(
      map(([transactionsSS, transactionsCMS]) => {

          const _transactionsCMS = transactionsCMS.data.list;
          const _transactionsSS = transactionsSS.map(transaction => {

            return {
              ...transaction,
              amount: this._data.subunitsToUnits(transaction.amount_cents, transaction.currency),
              currency_symbol: this._data.currencySymbol(transaction.currency),
              created_at: new Date(transaction.created_at),
              status: transaction.finished_at ?
                transaction.success ?
                  TransactionStatus.ACCEPTED :
                  TransactionStatus.DISCARDED :
                  TransactionStatus.PENDING
            };
          });
          return this._cmsTransactions.mappedCashoutTransactions(_transactionsCMS, _transactionsSS);
      }),
      tap(list => this.transactionList$.next(list)),
      catchError(error => of([])
      ));
  }

  /**
   * Returns users bets list
   */
  betList(): Observable<any> {
    const games = new Set();
    const bets = [];

    return this._api.playerGames().pipe(
      tap(list => {
        list.forEach(bet => {
          games.add(bet.game);
          bets.push({
            ...bet,
            amount: this._data.subunitsToUnits(bet.total_bets, bet.currency),
            win: this._data.subunitsToUnits(bet.total_wins, bet.currency),
            currency_symbol: this._data.currencySymbol(bet.currency),
            date: new Date(bet['created_at']),
          });
        });
      }),
      switchMap(() => this._games.list({
        'external_id[]': [...games]
      })),
      filter(response => !!response),
      map(gameResponse => {
        const gamesByExternalId = this._filters.valueAsKeyObject('externalId', gameResponse.gameList || []);

        return bets.map(bet => ({
          ...bet,
          game: gamesByExternalId[bet.game]
        }));
      })
    );
  }

  /**
   * Cancel pending cash out
   *
   * @param id
   */
  cashoutRecall(id: string): Observable<any> {
    return this._api.playerPaymentsRecall(id);
  }

  /**
   * Handles failed transactions for a given payment method.
   * @param paymentArray - Array of payment transactions.
   * @param paymentMethod - The payment method for which failed transactions are checked.
   */
  public handleFailedTransactions(paymentArray: any[], paymentMethod: PaymentsMethod) {
    const transactionLimitStorage = JSON.parse(this._storage.get(TransactionLimit.STORAGE_KEY)) || [];

    if (this._filterTransactionsByDate(paymentArray, paymentMethod.brand)[0]?.success) {
      transactionLimitStorage.forEach(item => {
        if (item.paymentMethod === paymentMethod.brand) {
          item.lastTransactionId = paymentArray[0]?.id;
        }
      });
      this._storage.set(TransactionLimit.STORAGE_KEY, JSON.stringify(transactionLimitStorage));
      return;
    }

    const transactionsAboveIdCount = this._countTransactionsAboveId(paymentArray,
      this._getLastTransactionId(transactionLimitStorage, paymentMethod.brand), paymentMethod.brand);

    const todayDiscardedTransactions = this._countTodayDiscardedTransactions(paymentArray, paymentMethod.brand);

    if (this._isDateGreaterThanEnableTime(transactionLimitStorage, paymentMethod.brand)) {
      transactionLimitStorage.forEach(item => {
        if (item.paymentMethod === paymentMethod.brand) {
          item.isDisabled = false;
        }
      });
      this._storage.set(TransactionLimit.STORAGE_KEY, JSON.stringify(transactionLimitStorage));
    }

    if (transactionsAboveIdCount >= TransactionLimit.MAX_COUNT) {
      this._updateTransactionLimitAfterMaxCount(transactionLimitStorage, paymentArray, paymentMethod.brand);
      this._storage.set(TransactionLimit.STORAGE_KEY, JSON.stringify(transactionLimitStorage));
      this._isTransactionDisabled = !!transactionLimitStorage.find(item => item.paymentMethod === paymentMethod.brand)?.isDisabled;
      return;
    }

    if (todayDiscardedTransactions.length >= TransactionLimit.MAX_COUNT) {
      if (!this._isPaymentMethodInLimitStorage(transactionLimitStorage, paymentMethod.brand)) {
        this._addNewPaymentMethodToLimitStorage(transactionLimitStorage, paymentArray, paymentMethod.brand);
        this._storage.set(TransactionLimit.STORAGE_KEY, JSON.stringify(transactionLimitStorage));
      }
    }

    this._isTransactionDisabled = !!transactionLimitStorage.find(item => item.paymentMethod === paymentMethod.brand)?.isDisabled;

  }

  /**
   * Counts the number of transactions above a given transaction ID.
   * @param transactions - Array of transactions.
   * @param lastTransactionId - The last known transaction ID.
   * @param paymentMethodBrand
   * @returns The count of transactions above the last known ID.
   */
  private _countTransactionsAboveId(transactions: any[], lastTransactionId: number, paymentMethodBrand: string) {
    const transactionIndex = this._filterTransactionsByDate(transactions, paymentMethodBrand)
      .findIndex(transaction => transaction.id === lastTransactionId);
    return transactionIndex !== -1 ? transactions.slice(0, transactionIndex).length : 0;
  }

  private _countTodayDiscardedTransactions(paymentArray: any[], paymentMethodBrand: string) {
    return this._filterTransactionsByDate(paymentArray, paymentMethodBrand)
      .slice(0, TransactionLimit.MAX_COUNT);
  }

  private _filterTransactionsByDate(transactions: any[], paymentMethodBrand: string) {
    return transactions
      .sort((a, b) => b.id - a.id)
      .filter(payment =>
        this._time.areSameUTCDates(payment.created_at) &&
        paymentMethodBrand === payment.brand);
  }

  /**
   * Retrieves the last known transaction ID for a given payment method.
   * @param transactionLimitStorage - Transaction limit storage array.
   * @param paymentMethodBrand - The brand of the payment method.
   * @returns The last known transaction ID for the given payment method.
   */
  private _getLastTransactionId(transactionLimitStorage: any[], paymentMethodBrand: string) {
    const paymentMethodEntry = transactionLimitStorage.find(item => item.paymentMethod === paymentMethodBrand);
    return paymentMethodEntry?.lastTransactionId;
  }

  /**
   * Updates the disabled status of payment methods based on their enable time.
   * @param transactionLimitStorage - Transaction limit storage array.
   * @param paymentMethodBrand - The brand of the payment method.
   */
  private _isDateGreaterThanEnableTime(transactionLimitStorage: any[], paymentMethodBrand: string): boolean {
    const matchingItem = transactionLimitStorage.find(item => item.paymentMethod === paymentMethodBrand);

    if (matchingItem) {
      const currentDate = new Date();
      const enableTimeDate = new Date(matchingItem.enableTime);
      return currentDate > enableTimeDate;
    }

    return false;
  }

  /**
   * Updates the transaction limit storage after reaching the maximum count of failed transactions.
   * @param transactionLimitStorage - Transaction limit storage array.
   * @param paymentArray
   * @param paymentMethodBrand - The brand of the payment method.
   */
  private _updateTransactionLimitAfterMaxCount(transactionLimitStorage: any[], paymentArray: any[], paymentMethodBrand: string) {
    transactionLimitStorage.forEach(item => {
      if (item.paymentMethod === paymentMethodBrand) {
        item.enableTime = new Date(new Date().getTime() + TransactionLimit.DISABLED_TIME * 60 * 1000);
        item.isDisabled = true;
        item.lastTransactionId = this._filterTransactionsByDate(paymentArray, paymentMethodBrand)[0]?.id;
      }
    });
  }

  /**
   * Checks if a payment method is already present in the transaction limit storage.
   * @param transactionLimitStorage - Transaction limit storage array.
   * @param paymentMethodBrand - The brand of the payment method.
   * @returns True if the payment method is present; otherwise, false.
   */
  private _isPaymentMethodInLimitStorage(transactionLimitStorage: any[], paymentMethodBrand: string) {
    return Boolean(transactionLimitStorage.find(item => item.paymentMethod === paymentMethodBrand));
  }

  /**
   * Adds a new payment method entry to the transaction limit storage.
   * @param transactionLimitStorage - Transaction limit storage array.
   * @param paymentArray
   * @param paymentMethodBrand
   */
  private _addNewPaymentMethodToLimitStorage(transactionLimitStorage: any[], paymentArray: any[], paymentMethodBrand: string) {
    transactionLimitStorage.push({
      paymentMethod: paymentMethodBrand,
      lastTransactionId: this._filterTransactionsByDate(paymentArray, paymentMethodBrand)[0]?.id,
      enableTime: new Date(new Date().getTime() + TransactionLimit.DISABLED_TIME * 60 * 1000),
      isDisabled: true,
    });
  }
}
