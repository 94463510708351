import { Component, inject, output } from '@angular/core';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {StaticContentService} from '../../../core/services/static-content.service';
import {UserService} from '../../../core/services/user/user.service';
import {Router} from '@angular/router';
import {PlatformService} from '../../../core/services/platform.service';
import {LanguageService} from '../../../core/services/language/language.service';
import {CopyTextService} from '../../../core/services/copy-text.service';
import {GamesService} from '../../../core/services/games/games.service';
import {ModalService} from '../../../core/modal-v2/modal.service';
import {GamesLauncherService} from '../../../core/services/games/games-launcher.service';
import {BreakpointsDetectorService} from '../../../core/services/breakpoints-detector.service';
import {ArabicService} from '../../../core/services/arabic.service';
import { TranslatePipe } from '../../../core/shared/translation/translate.pipe';
import { SafePipe } from '../../../core/shared/pipes/safe.pipe';
import { SetImgAltDirective } from '../../../core/shared/directives/set-img-alt.directive';
import { NgClass, AsyncPipe } from '@angular/common';
@Component({
    selector: 'app-phone-verification-offer',
    templateUrl: './phone-verification-offer.component.html',
    styleUrls: ['./phone-verification-offer.component.scss'],
    imports: [SetImgAltDirective, NgClass, AsyncPipe, SafePipe, TranslatePipe]
})
export class PhoneVerificationOfferComponent {
  private _static = inject(StaticContentService);
  private _user = inject(UserService);
  private _router = inject(Router);
  private _platform = inject(PlatformService);
  private _lang = inject(LanguageService);
  private _copy = inject(CopyTextService);
  private _games = inject(GamesService);
  private _modals = inject(ModalService);
  private _gameLauncher = inject(GamesLauncherService);
  breakpoints = inject(BreakpointsDetectorService);
  rtl = inject(ArabicService);


  readonly detailsClick = output<any>();

  public offer$: Observable<{
    Title: string,
    Desc: string,
    SecondDesc: string,
    Image: string,
    ButtonAction: string,
    ButtonRoute: string,
    Terms: string,
    Content: string,
    game$: Observable<any>
  }> = this._static.item({slug: 'phone-verification-redesign'}).pipe(
    filter(offer => !!offer?.length),
    map(offer => {
      return {...offer[0], game$: this._games.getGameByExternalId(offer[0]?.SecondDesc)};
    }),
  );

  public async onDepositClick(route?: string) {
    if (this._user.auth) {
      await this._router.navigateByUrl((route || '/profile/security') + '?scroll=true');
    } else {
      await this._user.authUser();
    }
  }

  public async openPhoneTermsInfoModal(offer) {
    const component = await import('../../../core/modal-v2/components/lazy/phone-verification/phone-verification.component');
    await this._modals.openLazy(component?.PhoneVerificationComponent, {
      template: 'CLEAR',
      data: {
        Text: offer?.Content,
        Terms: offer?.Terms,
        ButtonRoute: offer?.ButtonRoute
      }
    })
  }

  public openGame($event, game) {
    if ($event?.target.tagName === 'SPAN') {
      this._gameLauncher.openGameByExternalId(game);
    }
  }
}
