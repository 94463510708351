import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { CookieService } from 'ngx-unificator/services';
import { BannersService } from '../../../services/banners.service';
import { fromEvent, NEVER, Observable } from 'rxjs';
import { BannerType } from '../../../../../environments/environment';
import { PlatformService } from '../../../services/platform.service';
import { debounceTime, tap } from 'rxjs/operators';
import { UserService } from '../../../services/user/user.service';
import { GoogleTagManagerService } from '../../../services/google-tag-manager.service';
import { DeleteNotFoundDirective } from '../../directives/delete-not-found.directive';
import { SetImgAltDirective } from '../../directives/set-img-alt.directive';
import { AsyncPipe, NgClass } from '@angular/common';

@Component({
    selector: 'app-sticky-bonus',
    templateUrl: './sticky-bonus.component.html',
    styleUrls: ['./sticky-bonus.component.scss'],
    imports: [NgClass, SetImgAltDirective, DeleteNotFoundDirective, AsyncPipe]
})
export class StickyBonusComponent implements OnInit, OnDestroy {
  private _cookie = inject(CookieService);
  private _banners = inject(BannersService);
  private _platform = inject(PlatformService);
  private _user = inject(UserService);
  private _gtm = inject(GoogleTagManagerService);


  /**
   * Banner from CMS
   */
  public banner$: Observable<any> = NEVER;

  /**
   *  Is banner visible
   */
  public visible: boolean;

  ngOnInit() {
    if (!this._cookie.check('sticky-banner')) {
      this.banner$ = this._banners.list({
        type: BannerType.BOTTOM_STICKY
      });

      this._handleScroll();
    }
  }

  /**
   * Handle banner close
   */
  close() {
    this._cookie.set('sticky-banner', '1', 33, '/');
    this.banner$ = NEVER;
  }

  onButtonClick() {
    this._gtm.stickyBottomButtonClick('register_notification', 'notification_footer');
  }

  ngOnDestroy(): void {
  }

  /**
   * Handle window scroll
   *
   * @private
   */
  private _handleScroll() {
    if (!this._platform.isBrowser) { return; }

    fromEvent(window, 'scroll', {
      capture: true,
      passive: true
    }).pipe(
      debounceTime(300),
      tap(event => {
        this.visible = (window.scrollY > 300) && !this._user.auth;
      })
    ).subscribe();
  }

}
