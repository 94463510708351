import { Component, ElementRef, OnInit, inject, contentChildren } from '@angular/core';
import { SelectService } from '../select.service';
import { SelectOptionComponent } from '../select-option/select-option.component';

@Component({
    selector: 'app-select-dropdown',
    templateUrl: './select-dropdown.component.html',
    styleUrls: ['./select-dropdown.component.scss'],

})
export class SelectDropdownComponent implements OnInit {
  select = inject(SelectService);
  el = inject(ElementRef);


  readonly options = contentChildren(SelectOptionComponent);

  ngOnInit() {
  }

}
