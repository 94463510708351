import { Component, inject } from '@angular/core';
import { filter, tap } from 'rxjs/operators';
import { WindowService } from '../../../services/window.service';
import { StaticContentService } from '../../../services/static-content.service';
import { Observable } from 'rxjs';
import { ModalService } from '../../../modal-v2/modal.service';
import { TranslatePipe } from '../../translation/translate.pipe';
import { SetImgAltDirective } from '../../directives/set-img-alt.directive';
import { AsyncPipe } from '@angular/common';

export enum SocialAuthType {
  TELEGRAM = 'Telegram',
  GOOGLE = 'Google'
}

interface SocialAuthTypeData {
  apiUrl: string;
}

interface SocialAuthData {
  // @ts-ignore
  [key in SocialAuthType]: SocialAuthTypeData;
}

export const SocialAuthData: SocialAuthData = {
  [SocialAuthType.TELEGRAM]: {
    apiUrl: '/users/auth/telegram'
  },
  [SocialAuthType.GOOGLE]: {
    apiUrl: '/users/auth/google_oauth2'
  }
};

@Component({
    selector: 'app-social-auth',
    templateUrl: './social-auth.component.html',
    styleUrls: ['./social-auth.component.scss'],
    imports: [SetImgAltDirective, AsyncPipe, TranslatePipe]
})
export class SocialAuthComponent {
  private _window = inject(WindowService);
  private _static = inject(StaticContentService);
  private _modal = inject(ModalService);


  /**
   * Telegram by static item from CMS
   */
  public telegram$: Observable<any> = this._static.item({slug: 'ss-telegram'}).pipe(
    filter(data => Boolean(data?.[0])),
    tap(() => this.isSocialEnabled = true)
  );

  /**
   * Google by static item from CMS
   */
  public google$: Observable<any> = this._static.item({slug: 'ss-google'}).pipe(
    filter(data => Boolean(data?.[0])),
    tap(() => this.isSocialEnabled = true)
  );

  /**
   * True If Telegram or Google is available
   */
  public isSocialEnabled: boolean;

  get socialAuthType() {
    return SocialAuthType;
  }

  get socialAuthData() {
    return SocialAuthData;
  }


  /**
   * On auth link
   * @param apiUrl
   * @param type
   */
  public async onAuth(apiUrl: string, type: string) {
    if (type === SocialAuthType.TELEGRAM) {
      const component = await import('../../../modal-v2/components/lazy/telegram-auth-modal/telegram-auth-modal.component');
      await this._modal.openLazy(component.TelegramAuthModalComponent);
    } else {
      this._window.nativeWindow.location.replace(this._resolveSocialUrl(apiUrl));
    }
  }


  /**
   * Resolve apiUrl
   * @param apiUrl
   * @private
   */
  private _resolveSocialUrl(apiUrl: string): string {
    const originUrl = this._window.nativeWindow.location.origin;
    if (originUrl.includes('localhost') || originUrl.includes('stage')) {
      return `https://spinsamurai.com${ apiUrl }`;
    } else {
      return `${ this._window.nativeWindow.location.origin.replace('www.', '') }` + apiUrl;
    }
  }
}
