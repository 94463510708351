import { Component, OnInit, ViewContainerRef, inject, viewChild } from '@angular/core';
import { Modal } from '../../modal';
import { showHide } from '../../modal-animations';
import { GoogleTagManagerService } from '../../../services/google-tag-manager.service';
import { UserService } from '../../../services/user/user.service';
import {ScrollService} from '../../../services/scroll.service';

@Component({
    selector: 'app-modal-low-balance',
    templateUrl: './modal-low-balance.component.html',
    styleUrls: ['./modal-low-balance.component.scss'],
    animations: [showHide],
    standalone: false
})
export class ModalLowBalanceComponent extends Modal implements OnInit {
  private _gtm = inject(GoogleTagManagerService);
  private _user = inject(UserService);
  private _scroll = inject(ScrollService);


  readonly contentRef = viewChild('content', { read: ViewContainerRef });

  ngOnInit() {

  }

  onClose() {
    this._gtm.lowBalancePopupClose('popup_close', this._user.info.id);
    this.close();
    this._scroll.UnblockScroll();
  }

}
