import { Component, Input, OnChanges, SimpleChanges, inject, input, output } from '@angular/core';
import {FormsModule, ReactiveFormsModule, UntypedFormControl} from '@angular/forms';
import {UserService} from '../../../services/user/user.service';

import { SelectComponent } from '../../../modules/select/select.component';
import { SelectHeaderComponent } from '../../../modules/select/select-header/select-header.component';
import { SelectDropdownComponent } from '../../../modules/select/select-dropdown/select-dropdown.component';
import { SelectOptionComponent } from '../../../modules/select/select-option/select-option.component';


@Component({
    selector: 'app-currency-selector-standalone',
    templateUrl: './currency-selector-standalone.component.html',
    styleUrls: ['./currency-selector-standalone.component.scss'],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        SelectComponent,
        SelectHeaderComponent,
        SelectDropdownComponent,
        SelectOptionComponent
    ]
})
export class CurrencySelectorStandaloneComponent implements OnChanges {
  user = inject(UserService);

  /**
   * Disable select
   */
  readonly disable = input<boolean>(undefined);

  /**
   * Initial value for select
   */
  @Input() selectedCurrency: string;


  /**
   * For correct select working
   */
  currencyControl: UntypedFormControl = new UntypedFormControl(null);

  /**
   * Emit if change currency select
   */
  readonly changeCurrency = output<any>();

  ngOnChanges(changes: SimpleChanges) {
    if (changes.initialValue && changes.initialValue.firstChange && changes.initialValue.currentValue) {
      this.currencyControl.setValue(changes.initialValue.currentValue, {emitEvent: false});
    }

    if (changes.disable && changes.disable.firstChange && changes.disable.currentValue) {
      this.currencyControl.disable();
    }
  }

  /**
   * Change account currency
   * @param $event
   */
  public changeAccountCurrency($event) {
    this.selectedCurrency = $event;
    this.changeCurrency.emit($event);
  }
}
