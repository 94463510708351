import { AfterViewInit, Directive, ElementRef, inject, input, Renderer2 } from '@angular/core';
import { PlatformService } from '../../services/platform.service';
import { fromEvent, of } from 'rxjs';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import { catchError, delay, filter, take, tap } from 'rxjs/operators';

export enum DefaultImageType {
  GAME = '/assets/img/loading.jpg'
}

@UntilDestroy()
@Directive({
    selector: '[defaultImage]',

})
export class DefaultImage implements AfterViewInit {
  private _el = inject(ElementRef);
  private _platform = inject(PlatformService);
  private _renderer = inject(Renderer2);


  /**
   * Alternative src for image. Set if src broken
   */
  readonly alternative = input<string>(undefined);

  /**
   * Default image src. Set if src and alternative src broken
   */
  readonly default = input<DefaultImageType>(undefined);

  /**
   * Target image element that will be checked
   */
  private _target: HTMLImageElement;

  /**
   * Current data-src for image
   */
  private _currentSrc = '';

  ngAfterViewInit(): void {
    this._target = this._el.nativeElement instanceof HTMLImageElement ?
      this._el.nativeElement :
      this._el.nativeElement.querySelector('img');

    if (!this._target) {
      return;
    }

    fromEvent(this._target, 'error').pipe(
      untilDestroyed(this),
      take(2),
      catchError(error => of(error)),
      filter(() => this._platform.isBrowser),
      tap(() => {
        const alternative = this.alternative();
        const defaultSignal = this.default();
        if (alternative && this._currentSrc !== alternative) {
          this._currentSrc = alternative
        } else if (this._currentSrc !== defaultSignal) {
          this._currentSrc = defaultSignal;
        }
      }),
      tap(() => {
        this._renderer.setAttribute(this._el?.nativeElement, 'data-src', this._currentSrc);
        this._renderer.setAttribute(this._el?.nativeElement, 'src', this._currentSrc);
      }),
      delay(100),
      tap(() => {
        this._renderer.setStyle(this._el?.nativeElement, 'opacity', '1');
        this._renderer.setStyle(this._el?.nativeElement, 'transition', 'all .1s');
      })
    ).subscribe();
  }
}
