<div class="amount-list-container">
  <div class="amount-list">
    @for (amount of valueList(); track amount) {
      <span class="amount-val"
            [ngClass]="{active: amount === control().value}"
            (click)="selectAmount(amount)">{{ user.currentCurrency?.symbol }} {{ amount }}</span>
    }
  </div>
</div>


<div class="amount-container">
  <div class="input-container">
    <app-form-input [control]="control()"
      [errorType]="'tooltipError'"
      [label]="('labl.amount' | translate)">
      <input InputNumber type="number" class="input" inputmode="decimal" (keydown.enter)="onBlur($event)" [formControl]="control()" min="0" name="amount">
    </app-form-input>
  </div>
  <app-currency-selector-standalone (changeCurrency)="changeAccountCurrency($event)"></app-currency-selector-standalone>
</div>

