import { StoreEntityType } from 'ngx-unificator/store';

export interface VipStore {
  page?: VipPage;
  vipLevels?: VipLevel[],
  currentVipUserLevel?: VipLevel,
  nextVipUserLevel?: VipLevel,
  prevVipUserLevel?: VipLevel,
  isShowManagers?: boolean,
  isLoadingPage?: boolean,
  userTransferGroups?: { monthGroup: string, date: string }[];
  visibleRulesCount: number;
  userStatuses: string[];
  isShowProgressBar: boolean;
  numberOfDepositsDaysPerMonth: number;
  depositCountPerMonth: number;
  medianDepositPerTransaction: number;
  wageringPerMonth: number;
  differentGameProviders: number;
  consecutiveMonthOfActivity: number;
}

export interface UserVipConditionItem {
  min: number,
  max: number,
  reward: number
}

export interface VipPage {
  NoAuthTitle: string;
  NoAuthDesc: string;
  NoAuthImage: string;
  Benefits: { title: string }[];
  HowGetVip: { title: string, desc: string, buttonName: string, buttonUrl: string }[];
  VipBenefitsTitle: string;
  HowGetVipTitle: string;
  VipLevelsTitle: string;
  VipLevelsDesc: string;
  VipAdvantagesTitle: string;
  VipAdvantagesFeatures: { desc: string }[];
  VipAdvantagesDesc: string;
  VipAdvantagesImage: string;
  Faq: string;
  HowEarnPointsTitle: string;
  VipGamesTitle: string;
  VipTournamentsTitle: string;
  Managers: ManagerItem[];
  CurrentManager: ManagerItem;
  userVipConditions: {
    numberOfDepositsDaysPerMonth: UserVipConditionItem[],
    depositCountPerMonth: UserVipConditionItem[],
    medianDepositPerTransaction: UserVipConditionItem[],
    wageringPerMonth: UserVipConditionItem[],
    differentGameProviders: UserVipConditionItem[],
    consecutiveMonthOfActivity: UserVipConditionItem [],
  };
}

export interface VipLevel {
  Benefits: { title: string; active: boolean }[];
  HowEarnVPoints: {
    title: string;
    firstDesc: string;
    secondDesc: string,
    firstCondition: boolean;
    secondCondition: boolean;
    thirdCondition: boolean;
  }[];
  Image: string;
  LevelName: string;
  TotalReward: number;
  LevelComplete: boolean;
  LevelGroup: string;
  VPointsToReach: number;
  ProgressPercent: number;
  DoneConditions: any;
}

export interface ManagerItem {
  name: string;
  email: string;
  telegramName: string;
  telegramLink: string;
  whatsAppName: string;
  whatsAppLink: string;
  managerGroup: string;
}

export const vipStore: StoreEntityType<VipStore> = {
  isShowProgressBar: true,
  page: null,
  vipLevels: [],
  currentVipUserLevel: null,
  nextVipUserLevel: null,
  prevVipUserLevel: null,
  isShowManagers: false,
  isLoadingPage: true,
  userTransferGroups: [
    { monthGroup: 'ID1026', date: 'Jan' },
    { monthGroup: 'ID1027', date: 'Feb' },
    { monthGroup: 'ID1016', date: 'Mar' },
    { monthGroup: 'ID1017', date: 'Apr' },
    { monthGroup: 'ID1018', date: 'May' },
    { monthGroup: 'ID1019', date: 'Jun' },
    { monthGroup: 'ID1020', date: 'Jul' },
    { monthGroup: 'ID1021', date: 'Aug' },
    { monthGroup: 'ID1022', date: 'Sep' },
    { monthGroup: 'ID1023', date: 'Oct' },
    { monthGroup: 'ID1024', date: 'Nov' },
    { monthGroup: 'ID1025', date: 'Dec' },
  ],
  visibleRulesCount: 3,
  userStatuses: [],
  numberOfDepositsDaysPerMonth: 0,
  depositCountPerMonth: 0,
  medianDepositPerTransaction: 0,
  wageringPerMonth: 0,
  differentGameProviders: 0,
  consecutiveMonthOfActivity: 0,
};

export type VipStoreEntity = typeof vipStore;
