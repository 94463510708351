import { AfterViewInit, Directive, ElementRef, OnDestroy, inject } from '@angular/core';
import { PlatformService } from '../../services/platform.service';
import { fromEvent, of } from 'rxjs';
import { catchError, filter, first, tap } from 'rxjs/operators';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { untilDestroyed } from 'ngx-unificator/rxjs';

/**
 * Remove target element if image not exists
 * If target element not 'img' - for checking will be used first inner 'img'
 */
@UntilDestroy()
@Directive({
    selector: '[DeleteNotFound]',

})
export class DeleteNotFoundDirective implements AfterViewInit, OnDestroy {
  private _el = inject(ElementRef);
  private _platform = inject(PlatformService);


  /**
   * Target image element that will be checked
   */
  private _target: HTMLImageElement;

  ngAfterViewInit(): void {
    this._target = this._el.nativeElement instanceof HTMLImageElement ?
      this._el.nativeElement :
      this._el.nativeElement.querySelector('img');

    if (!this._target) {
      return;
    }

    fromEvent(this._target, 'error').pipe(
      untilDestroyed(this),
      first(),
      catchError(error => of(error)),
      filter(() => this._platform.isBrowser),
      tap(() => {
        this._el.nativeElement.remove();
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
  }
}
