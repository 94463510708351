import { Injectable, OnDestroy, inject } from '@angular/core';
import { UserService } from '../../../services/user/user.service';
import { Router } from '@angular/router';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import { ScrollService } from '../../../services/scroll.service';
import { CookieService } from 'ngx-unificator/services';
import { Payment } from 'payments-lib-types';

export const OPENED_SIDENAV_COOKIE_NAME = '--opened-cookie-date';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class SideNavService implements OnDestroy {
  private _scroll = inject(ScrollService);
  private _router = inject(Router);
  private _cookie = inject(CookieService);
  _user = inject(UserService);


  /**
   * Sidebar state
   */
  public isOpen: boolean;

  /**
   * True if side nav casino link opened today
   * @private
   */
  public isOpenedCasinoToday: boolean;

  constructor() {
    /**
     * Logic for toggle bell near casino link (uses for redesign AB test)
     */
    if (this.isOpenedCasinoToday === undefined) {
      this._setIsOpenedCasinoTodayValue();
    }
  }

  ngOnDestroy() {
  }

  /**
   * Open side-nav
   */
  public open() {
    if (!this.isOpen) {
      this.isOpen = true;
      this._scroll.blockScroll();
      this._checkPaymentRoute();
    }
  }

  /**
   * Close side-nav
   */
  public close() {
    if (this.isOpen) {
      this.isOpen = false;
      this._scroll.UnblockScroll();
    }
  }

  /**
   * Toggle side-nav
   */
  public toggle() {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  /**
   * Exclude Deposit and Cashout routes for update balance into sidebar
   * @private
   */
  private _checkPaymentRoute() {
    const route = this._router.url;
    if (route.includes(Payment.Action.Deposit) || route.includes(Payment.Action.Cashout)) {
      return;
    } else {
      this._user.getUserAccounts().pipe(
        untilDestroyed(this)
      ).subscribe();
    }
  }


  /**
   * Logic for toggle bell near casino link
   */
  public setisOpenedCasinoTodayInCookie() {
    const currentDateFormat = this._getCurrentDateFormat();
    this._cookie.delete(OPENED_SIDENAV_COOKIE_NAME);
    this._cookie.set(OPENED_SIDENAV_COOKIE_NAME, currentDateFormat, 999, '/');
    this.isOpenedCasinoToday = true;
  }

  private _setIsOpenedCasinoTodayValue() {
    const currentDateFormat = this._getCurrentDateFormat();
    const cookieOpenedDate = this._cookie.get(OPENED_SIDENAV_COOKIE_NAME);
    this.isOpenedCasinoToday = cookieOpenedDate === currentDateFormat;
  }

  private _getCurrentDateFormat() {
    const date = new Date();
    const  day = date.getDate();
    const  month = date.getMonth() + 1;
    const  year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
}
