<div class="page" lazy-load-images>
  <router-outlet></router-outlet>
</div>

<ng-container *appShellNoSSR>
  <app-toast-message></app-toast-message>

  <app-sticky-bonus></app-sticky-bonus>

  @if (translations.translationLoading$ | async) {
    <div class="language-preloader">
      <div class="language-preloader__bar"></div>
    </div>
  }

  @if (installApp.showInstallAppAfterDepositBanner && installApp.isEnableNativeApp) {
    <app-install-app-banner></app-install-app-banner>
  }

</ng-container>

<img src="/assets/svg/top.svg" class="to-top-btn" [ngClass]="{'visible': toTopVisible}" (click)="onToTopClick()">

<ng-container *appShellNoSSR>
  @defer (when gameIframe.isShowFrame) {
    <app-game-iframe ></app-game-iframe>
  }
</ng-container>
