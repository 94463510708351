@if (place() === 'promo') {
  <div class="bonuses flex gap-10">
    @for (item of easter.store()?.page?.Bonuses; track $index) {
      <div class="bonuses__item flex flex-col items-center justify-center px-24 py-10 relative sm:px-4">
        <div [ngClass]="{available: !item.isTaken, taken: item.isTaken}"
             class="bonuses__item-status absolute uppercase text-12 font-weight-700 sm:text-10">
          {{ (!item.isTaken ? 'labl.available' : 't.taken') | translate }}
        </div>
        <div [class.taken]="item.isTaken" class="title not-deposit text-24 font-weight-900 uppercase text-center mt-4 sm:text-16 mt-14" [innerHTML]="item?.title | safe: 'html'"></div>
        <div class="bonuses__item-rule font-weight-700 text-12 mt-10 sm:text-10 sm:mb-6">
          {{ item?.rule }}
        </div>
      </div>
    }
  </div>
} @else {
  <div class="bonuses-deposit flex flex-col gap-14 mt-20">
    <app-slider #bonusesSlider [slideList]="easter.store()?.page?.FilteredBonuses" [config]="sliderConfig">
      @for (item of easter.store()?.page?.FilteredBonuses; track $index) {
        <div class="relative bonuses-deposit__item style-{{item?.bonusLevel}} card flex gap-10 items-center py-10">
          <div class="flex flex-col px-16 pt-4">
            <div class="title font-weight-800 text-12 mt-10 sm:mb-6">{{ easter.store()?.page?.Title }}</div>
            <div class="title mw-155 text-18 font-weight-800 mt-14 sm:mt-4" [innerHTML]="item?.title | safe: 'html'"></div>
            <div class="title font-weight-800 text-12 mt-16 mb-12 sm:mt-10">{{ item?.rule }}</div>
          </div>
          <img class="ml-auto pr-10" src="/assets/img/promo/easter/bonuses/{{item?.bonusLevel}}.png" alt="">
        </div>
      }
    </app-slider>
    <div class="pagination-container mt--16">
      @if (bonusesSlider) {
        <ng-container *ngTemplateOutlet="bonusesSlider?.paginationElement"></ng-container>
      }
    </div>
  </div>
}
