import { Injectable, inject } from '@angular/core';
import {filter, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {StaticPageService} from '../../../page/static-page/static-page.service';
import {LocalHistoryService} from '../local-history.service';
import {SeoService} from '../seo.service';
import {ResponseHandlerService} from '../response-handler.service';
import {combineLatest, of, ReplaySubject, Subject} from 'rxjs';
import {ILoyaltyLevel} from './loyalty.interface';
import {BonusStage} from '../user/data/user-bonuses.data';
import {OffersService} from '../offers.service';
import {UserBonusesService} from '../user/user-bonuses.service';
import {UserService} from '../user/user.service';
import {ArrayService} from '../array.service';
import {UserCompPointsService} from '../user/user-comp-points.service';
import {GamesService} from '../games/games.service';
import {TranslationService} from '../../shared/translation/translation.service';
import {GroupsService, UserGroup} from '../groups.service';
import {GoogleTagManagerService} from '../google-tag-manager.service';
import {Router} from '@angular/router';
import {ArabicService} from '../arabic.service';
import { GamesLauncherService } from '../games/games-launcher.service';

@Injectable({
  providedIn: 'root'
})

export class LoyaltyService {
  private _pages = inject(StaticPageService);
  private _history = inject(LocalHistoryService);
  private _seo = inject(SeoService);
  private _responseHandler = inject(ResponseHandlerService);
  private _offers = inject(OffersService);
  private _bonuses = inject(UserBonusesService);
  private _user = inject(UserService);
  private _array = inject(ArrayService);
  private _compPoints = inject(UserCompPointsService);
  private _games = inject(GamesService);
  private _translate = inject(TranslationService);
  private _gtm = inject(GoogleTagManagerService);
  private _groups = inject(GroupsService);
  private _router = inject(Router);
  private _rtl = inject(ArabicService);
  private _gamesLauncher = inject(GamesLauncherService);


  /**
   * Loyalty levels from CMS Observable
   * @private
   */
  private _loyaltyLevels$: ReplaySubject<ILoyaltyLevel[]> = this.resolveLevels$();

  /**
   * Current loyalty level
   * @private
   */
  private _currentLoyaltyLevel: ILoyaltyLevel;

  /**
   * Current loyalty level
   * @private
   */
  private _currentLoyaltyLevel$: ReplaySubject<ILoyaltyLevel> = new ReplaySubject<ILoyaltyLevel>(1);

  private _imgPath: string = '/assets/img/loyalty';

  private _activeLevelIndex: number = 0;

  public loyaltyPathChoose$: Subject<any> = new Subject<any>();

  get loyaltyLevels$(): ReplaySubject<ILoyaltyLevel[]> {
    return this._loyaltyLevels$;
  }

  get currentLoyaltyLevel(): ILoyaltyLevel {
    return this._currentLoyaltyLevel;
  }

  get currentLoyaltyLevel$(): ReplaySubject<ILoyaltyLevel> {
    return this._currentLoyaltyLevel$;
  }

  get Group() {
    return UserGroup;
  }

  get activeLevelIndex(): number {
    return this._activeLevelIndex;
  }

  /**
   * Get slug from URL Params
   *
   * @private
   */
  public resolvePage$() {
    return this._pages.item({slug: 'loyalty-program-redesign'}).pipe(
      filter(data => !!data),
      map(page => {
        if (!page) {
          return this._history.skipHistoryNavigate('/404');
        }

        if (!page.length) {
          return this._history.skipHistoryNavigate('/404');
        } else {
          if (page[0] && !page[0].statusCode) {
            this._seo.setMetaTags(page[0] || {});
            return page[0];
          } else {
            return this._responseHandler.handleResponseStatus(page[0].statusCode);
          }
        }
      })
    );
  }

  /**
   * Click on locked link
   * @param event
   * @param game
   */
  public onLockedTextClick(event: any, game: any) {
    event.preventDefault();
    const target: HTMLElement = event?.target;
    if (target?.tagName === 'A') {
      this.openGame(game, this._rtl.isAr);
    }
  }

  public openGame(game, toGamesIfArabic = false) {
    if (!this._user.auth) {
      this._user.authUser().then();
      return;
    }

    if (this._user?.currentCurrency?.amount < 1) {
      this._router.navigateByUrl('/deposit').then();
      return;
    }

    if (toGamesIfArabic || !game) {
      this._router.navigateByUrl('/games').then();
    } else {
      this._gamesLauncher.openGameByExternalId(game);
    }
  }

  public resolveLevels$(): ReplaySubject<any> {
    return this._user.auth$.pipe(
      switchMap(auth => !!auth ? this._bonuses.freeSpinsList() : of(auth)),
      switchMap(data => {
        return combineLatest([
          typeof data === 'boolean' ? of([]) : this._compPoints.getCompPoints(),
          typeof data === 'boolean' ? of([]) : this._bonuses.loyaltyFreeSpinsBonuses$,
          this._offers.list({category_slug: 'loyalty-levels'}, {gameList: 'gameList'})
        ]);
      }),
      filter(([points, fs, levels]: any) => levels && levels.length),
      map(([points, fs, levels]) => {
        const generalLevels = levels.filter(level => !level?.slug?.includes('ninja') && !level?.slug?.includes('samurai'));
        const ninjaLevels = levels.filter(level => level?.slug?.includes('ninja'));
        const samuraiLevels = levels.filter(level => level?.slug?.includes('samurai'));
        const filteredLevels = this._groups.loyaltyGroup === UserGroup.NINJA ? [...generalLevels, ...ninjaLevels] : [...generalLevels, ...samuraiLevels];
        this._activeLevelIndex = filteredLevels?.findIndex(this._getActiveIndex.bind(this));
        this._activeLevelIndex = this._activeLevelIndex < 0 ? 0 : this._activeLevelIndex;

        const resolvedLevels = filteredLevels.map((level, i, arr) => this._resolveLoyaltyLevel(
          level,
          i,
          fs,
          this._activeLevelIndex,
          arr.length,
          arr,
          points
        ));
        if (this._user.auth && !this.currentLoyaltyLevel) {
          this._currentLoyaltyLevel = resolvedLevels[this._activeLevelIndex];
          this._currentLoyaltyLevel  = {...this._currentLoyaltyLevel,
            nextLockedBonus: {bonus: resolvedLevels[this._activeLevelIndex + 1]?.Bonuses?.find(b => !b.active && !b.taken), index: this._activeLevelIndex + 1}
          }

          this._currentLoyaltyLevel$.next(this._currentLoyaltyLevel);
        }

        // Increase active loyalty level index if level taken ot every sublevels taken
        if (this.currentLoyaltyLevel?.taken || this.currentLoyaltyLevel?.Bonuses?.every(b => b?.taken)) {
          this._activeLevelIndex = this.activeLevelIndex + 1;
        }

        return resolvedLevels;
      }),
      shareReplay(1)
    ) as ReplaySubject<any>;
  }

  /**
   * Resolve loyalty level
   * @param level
   * @param index
   * @param fs
   * @param activeLevelIndex
   * @param levelsArr
   * @param points
   * @private
   */
  private _resolveLoyaltyLevel(
    level: ILoyaltyLevel,
    index: number, fs: any[], activeLevelIndex: number, levelsLength: number, levelsArr: ILoyaltyLevel[], points: any
  ) {
    let bonuses = new Array(Object.values(level.Bonuses).length > 1 ? 9 : 0).fill(null);

    Object.values(level.Bonuses).forEach((bonus, i, arr) => {
      const boIdentifiers = (bonus?.BOIdentifier?.includes(',') ? bonus?.BOIdentifier?.split(',') : [bonus?.BOIdentifier]).map(f => f?.trim());
      const previousBoIdentifiers = (arr[i - 1]?.BOIdentifier?.includes(',') ? arr[i - 1]?.BOIdentifier?.split(',') : [arr[i - 1]?.BOIdentifier]).map(f => f?.trim());
      const freeSpinsForBonus = fs.find(item => boIdentifiers?.includes(item.group_key));
      const previousFreeSpins = fs.find(item => previousBoIdentifiers?.includes(item.group_key));
      const active = fs.some(f => boIdentifiers?.includes(f.group_key) && f.stage === BonusStage.ISSUED);
      const taken = index < activeLevelIndex || fs.some(f => boIdentifiers?.includes(f.group_key) && f.stage === BonusStage.ACTIVATED);
      const isPreviousActivated = previousFreeSpins?.state === 'activated' || arr.length === 1 && index === activeLevelIndex && index !== 0;
      const shortPrize = bonus?.prize?.split(' ')?.find(v => typeof Number(v) === 'number');
      const pointsToUnlock = this._user.auth ? Number(bonus.points) - Number(this._compPoints.points.persistent) : Number(bonus.points);
      const unlockMessage = this._translate.translate('t.points-to-unlock', {
        points: pointsToUnlock,
        link: `<a class="link ">${this._translate.translate('t.earn')}</a>`,
        img: `<img src="/assets/img/loyalty/points-img.svg" alt="">`
      });

      const unlockMessageAccount = this._translate.translate('t.unlock-freespins', {
        points: pointsToUnlock,
        fs: shortPrize?.replace('+', '')
      });

      const resolvedBonus = {
        ...bonus,
        active, taken, isPreviousActivated, unlockMessage, shortPrize, unlockMessageAccount,
        activateId: freeSpinsForBonus?.stage === BonusStage.ISSUED ? freeSpinsForBonus?.id : null,
        expired: freeSpinsForBonus?.stage === 'expired',
        games: this._resolveGamesForSublevel(level?.gameList, bonus?.game),
        smallImg: this._getBonusImage('small', index, i, levelsLength, arr.length, taken),
        bigImg: this._getBonusImage('big', index, i, levelsLength, arr.length, taken),
      };

      bonuses[i] = resolvedBonus;

      // One bonus for Vip bonus. If bonus taken
      if (index === levelsLength - 1 && taken) {
        bonuses = bonuses.slice(0, 1);
      }
    });

    const activeIndexBonus = bonuses.findIndex(b => b?.active || b?.isPreviousActivated);

    const pts = points.persistent;
    const chargeable = points.chargeable;
    const total = Number(level?.Points);

    let resolvedLevel: ILoyaltyLevel = {
      ...level,
      Bonuses: bonuses,
      chargeable,
      pts,
      left: total - pts,
      index: index + 1,
      levelsLength,
      active: this._initActiveLevel(level),
      taken: index < activeLevelIndex || (activeLevelIndex === levelsLength - 1 && bonuses?.every(b => b === null || b?.taken || b?.expired)),
      choosenBonus: {bonus: bonuses[activeIndexBonus >= 0 ? activeIndexBonus : 0], index: activeIndexBonus >= 0 ? activeIndexBonus : 0}
    };

    return resolvedLevel;
  }

  private _getBonusImage(
    imgType: 'small' | 'big',
    levelIndex: number,
    bonusIndex: number,
    levelsLength: number,
    bonusesLength: number,
    taken: boolean
  ): string {
    return this._imgPath + (
      taken && levelIndex === levelsLength - 1 ?
        `/sublevels/${levelIndex}/${imgType}${this._rtl.isAr ? '/ar/' : '/'}vip.png` :
        `/sublevels/${levelIndex}/${imgType}/${this._rtl.isAr ? 'ar/' : ''}${bonusIndex}.png`
    );
  }
  /**
   * Return true or false for set active and isSliderMode fields
   * @private
   */
  private _initActiveLevel(level: ILoyaltyLevel): boolean {
    return this._user.auth ?
      level?.BOIdentifier?.trim()?.toLowerCase() === this._user?.loyaltyActiveLevel?.toLowerCase()?.trim() :
      false;
  }

  /**
   * Return active level index
   * @param level
   * @private
   */
  private _getActiveIndex(level: ILoyaltyLevel): boolean {
    return this._user.auth ?
      level?.BOIdentifier?.trim()?.toLowerCase() === this._user.loyaltyActiveLevel?.toLowerCase()?.trim() :
      false;
  }

  /**
   * Ad signed in player to group
   *
   * @param group
   */
  public addPlayerToGroup(group: UserGroup) {
    if (group === UserGroup.SAMURAI) {
      this._gtm.loyaltyPageClick('register_loyalty_samurai', 'loyalty_samurai');
    } else {
      this._gtm.loyaltyPageClick('register_loyalty_ninja', 'loyalty_ninja');
    }

    if (this._user.auth && !this._groups.loyaltyGroup) {
      this._groups.addToGroup(group).pipe(
        tap(() => this.loyaltyPathChoose$.next(true)),
        switchMap(() => this._user.getUserInfo()),
        tap(() => {
          this._router.navigateByUrl('/deposit').then();
        })
      ).subscribe();
    }
  }

  private _resolveGamesForSublevel(gameList: any[], gamesForSublevel: string): any[] {
    const bonusGamesSlugArr = gamesForSublevel?.includes(',') ? gamesForSublevel.split(',').map(g => g?.trim()) : [gamesForSublevel];
    if (Array.isArray(gameList) || (gameList && (Array.isArray(Object.values(gameList))))) {
      const gamesList = Array.isArray(gameList) ? gameList : Object.values(gameList);
      const games = bonusGamesSlugArr?.map(gameExternalId => gamesList?.find(game => game?.externalId === gameExternalId));
      return games.filter(game => !!game).map(g => ({...g, imgSrc: this._games.resolveImageSrc(g)}));
    }
    return [];
  }
}
