import { Directive, inject, input } from '@angular/core';
import { TimeService } from '../services/time.service';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { untilDestroyed } from 'ngx-unificator/rxjs';

@UntilDestroy()
@Directive()
export class BaseTimerComponent  {

  readonly timeLeft = input<Date>(undefined);

  public timeLive: any;

  public time: TimeService = inject(TimeService);

  constructor() {
  }

  public resolveTimer() {
    if (this.timeLeft()) {
      this.time.currentDate$()
        .pipe(
          untilDestroyed(this)
        )
        .subscribe(() => {
          const timeLeft = this.timeLeft();
          this.timeLive = this.time.timeDiff(typeof timeLeft === 'string' ? new Date(timeLeft) : timeLeft);
        });
    } else {
      this.timeLive = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        daysLabel: 't.days',
        hoursLabel: 't.hrs',
        minLabel: 't.mins',
        secLabel: 't.secs'
      };
    }
  }

}
