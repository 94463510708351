import { Directive, ElementRef, inject } from '@angular/core';
import {PlatformService} from '../../services/platform.service';
import {NgControl} from '@angular/forms';

@Directive({
    selector: '[setValueAutofill]',

})
export class SetValueAutofillDirective {
  private _el = inject(ElementRef);
  private _control = inject(NgControl);
  private _platform = inject(PlatformService);


  constructor() {
    this._setValueAfterAutofill();
  }

  /**
   * Set value after autofill
   *
   * @private
   */
  private _setValueAfterAutofill() {
    if(this._platform.isBrowser && this._el.nativeElement) {
      this._el.nativeElement.addEventListener('change', () => {
        setTimeout(() => {
          if(this._control && !this._control.control.value) {
            this._control.control.setValue(this._el.nativeElement.value);
          }
        }, 250);
      });
    }
  }
}
