import { Component, OnInit, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import {HttpStatus} from '../../../core/services/response-handler.service';
import {OffersService} from '../../../core/services/offers.service';
import {LanguageService} from '../../../core/services/language/language.service';
import {ArabicService} from '../../../core/services/arabic.service';
import { SetImgAltDirective } from '../../../core/shared/directives/set-img-alt.directive';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-social',
    templateUrl: './social.component.html',
    styleUrls: ['./social.component.scss'],
    imports: [SetImgAltDirective, AsyncPipe]
})
export class SocialComponent implements OnInit {
  private _offer = inject(OffersService);
  lang = inject(LanguageService);
  arabic = inject(ArabicService);


  public socialPromo$: Observable<any> = this._offer.item({slug: 'ss-social-promo'})
    .pipe(filter(response => response &&
      response[0].statusCode !== HttpStatus.MOVED_PERMANENTLY &&
      response[0].statusCode !== HttpStatus.NOT_FOUND));
  ngOnInit() {
  }

}
