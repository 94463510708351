import { computed, inject, Injectable, signal } from '@angular/core';
import { UserService } from './user/user.service';
import { filter, finalize, first, map, switchMap, tap } from 'rxjs/operators';
import { AB_TEST_LIST } from '../ab-test/ab-test.data';
import { GroupsService } from './groups.service';
import { ModalService } from '../modal-v2/modal.service';
import { ReplaySubject } from 'rxjs';
import { AbTestNewService } from '../ab-test/ab-test.service';
import { NotificationCenterService } from '../modules/notification-center/notification-center.service';

export interface MascotState {
  mascots: MascotStateItem[];
  filteredMascots: MascotStateItem[] | undefined;
  currentMascot: MascotStateItem;
  isAbTestMascotV2: boolean;
}

export interface MascotStateItem {
  isAvailable: boolean,
  isActive: boolean,
  image: string,
  availableGroup: string,
  activeGroup: string,
  name: string,
  id: MASCOT_ID,
  conditionTitle: string,
  conditionDesc: string,
  task: string,
  prize: string,
}

export enum MASCOT_ID {
  FIRST = 'mascot_first',
  SECOND = 'mascot_second',
  THIRD = 'mascot_third',
}

export const FEATURE_NOTIFY_START_DATE = 1739412010451;
export const FEATURE_NOTIFY_END_DATE = new Date(FEATURE_NOTIFY_START_DATE).setDate(new Date(FEATURE_NOTIFY_START_DATE).getDate() + 45); // after 1.5 month from start notify date

@Injectable({
  providedIn: 'root',
})
export class MascotService {

  private _user: UserService = inject(UserService);
  private _groups: GroupsService = inject(GroupsService);
  private _modal: ModalService = inject(ModalService);
  private _abTest: AbTestNewService = inject(AbTestNewService);
  private _notification = inject(NotificationCenterService);

  public state = computed(() => this._state());

  private _state = signal<MascotState>(
    {
      isAbTestMascotV2: AB_TEST_LIST.DEVSS6741.resolvedValue ===
        AB_TEST_LIST.DEVSS6741.abTestGroupIds.V2,
      currentMascot: null,
      filteredMascots: [],
      mascots: [
        {
          isAvailable: false,
          isActive: false,
          image: '/assets/img/mascots/0.png',
          activeGroup: 'ID1000',
          availableGroup: 'ID993',
          name: 't.mascot-1-name',
          id: MASCOT_ID.FIRST,
          conditionTitle: 't.condition-mascot-1-title',
          conditionDesc: 't.condition-mascot-1-description',
          task: 't.mascot-1-task',
          prize: 't.mascot-1-prize',
        },
        {
          isAvailable: false,
          isActive: false,
          image: '/assets/img/mascots/1.png',
          activeGroup: 'ID1001',
          availableGroup: 'ID994',
          name: 't.mascot-2-name',
          id: MASCOT_ID.SECOND,
          conditionTitle: 't.condition-mascot-2-title',
          conditionDesc: 't.condition-mascot-2-description',
          task: 't.mascot-2-task',
          prize: 't.mascot-2-prize',
        },
        {
          isAvailable: false,
          isActive: false,
          image: '/assets/img/mascots/2.png',
          activeGroup: 'ID1002',
          availableGroup: 'ID995',
          name: 't.mascot-3-name',
          id: MASCOT_ID.THIRD,
          conditionTitle: 't.condition-mascot-3-title',
          conditionDesc: 't.condition-mascot-3-description',
          task: 't.mascot-3-task',
          prize: 't.mascot-3-prize',
        },
      ],
    },
  );

  public isDisplayUserMascots$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  public resolveUserMascots() {
    this._abTest.loadABTest$('DEVSS6741').pipe(
      filter((abTest) => abTest === AB_TEST_LIST.DEVSS6741.abTestGroupIds.V2),
      first(),
      map(abTest => abTest === AB_TEST_LIST.DEVSS6741.abTestGroupIds.V2),
      tap((res) => {
        this.isDisplayUserMascots$.next(res);
        const dateNow = new Date();
        const startDate = new Date(FEATURE_NOTIFY_START_DATE);
        const endDate = new Date(FEATURE_NOTIFY_END_DATE);
        if (this._user.auth && (dateNow >= startDate && dateNow <= endDate)) {
          this._notification.mascotChannel$.next(null);
        }
      }),
      finalize(() => {
        this._setAvailableMascot();
        this._setActiveMascot();
        this._setFilteredMascots();
      })
    ).subscribe();
  }

  private _setFilteredMascots() {
    this._state.update(state => {
      return {
        ...state,
        filteredMascots: state.mascots.filter(mascot =>
          [MASCOT_ID.FIRST, MASCOT_ID.THIRD].includes(mascot.id as any) &&
          mascot.isActive),
      };
    });
  }

  private _setAvailableMascot() {
    this._state.update(state => {
      return {
        ...state,
        mascots: state.mascots.map((mascot, i) =>
          this._user.info.statuses
            .some(group => group.id === mascot.availableGroup)
            ? { ...mascot, isAvailable: true }
            : mascot,
        ),
      };
    });
  }

  private _setActiveMascot() {
    this._state.update(state => {
      return {
        ...state,
        mascots: state.mascots.map(mascot =>
          this._user.info.statuses
            .some(group => group.id === mascot.activeGroup)
            ? { ...mascot, isActive: true }
            : mascot,
        ),
      };
    });
  }

  public setCurrentMascot(index: number) {
    this._state.update(state => {
      return {
        ...state,
        currentMascot: state.mascots[index],
      };
    });
  }

  public async showActivateModal(index: number) {
    const component =
      await import('../modal-v2/components/lazy/mascot-activate/mascot-activate.component');
    const modal =
      await this._modal.openLazy(component?.MascotActivateComponent, {
        template: 'CLEAR',
      });
    modal.onResult().pipe(
      filter((activated) => activated),
      switchMap(() =>
        this._groups.addToGroup(this._state().currentMascot?.activeGroup)),
      tap(() => {
        this._state.update(state => {
          return {
            ...state,
            mascots: state.mascots.map((mascot, i) =>
              i === index
                ? { ...mascot, isActive: true, isAvailable: false }
                : mascot,
            ),
          };
        });
      }),
    ).subscribe();
  }

  public async showInfoModal() {
    const component = await import('../modal-v2/components/lazy/mascot-info/mascot-info.component');
    await this._modal.openLazy(component?.MascotInfoComponent, {
      template: 'CLEAR',
    });
  }
}
