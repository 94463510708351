import { inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { environment } from 'src/environments/environment';
import { LanguageService } from './language/language.service';
import { SsApiService } from './api/ss-api.service';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { LocalstorageService } from './localstorage.service';
import { PlatformService } from './platform.service';
import { CmsApiService } from './api/cms-api.service';
import { UserService } from './user/user.service';

interface UbidexRequest {
  ua: string;
  ip: string;
  uid: string;
  cur: string;
  clickid: string;
  site: string;
  ln: string;
  affid: string;
  ts: any;
  event: string;
  s2s?: any;
  auth: string;
  amount?: string;
  tid?: string,
  prod?: string,
  cat?: string,
}

export const LOCAL_STORAGE_UBIDEX_KEY_DEPOSIT = 'LAST_DEP_DATA';

@Injectable({
  providedIn: 'root'
})
export class UbidexService {
  private _window = inject<Window>(WINDOW);
  private _apiSs = inject(SsApiService);
  private _api = inject(CmsApiService);
  private _storage = inject(LocalstorageService);
  private _lang = inject(LanguageService);
  private _platform = inject(PlatformService);
  private _user = inject(UserService);

  private readonly _trackingDomain = 'https://pixel-us.rwtks.com';

  public requestInProgress = false;

  public sendDepositEvents(isSkipFTD?: boolean) {
    this.requestInProgress = true;
    const data = JSON.parse(this._storage?.get(LOCAL_STORAGE_UBIDEX_KEY_DEPOSIT) || '{}');
    if (data.isFirst && !isSkipFTD) {
      this.onFTD(this._user.info, data);
    } else {
      this.onDeposit(this._user.info, data);
    }
  }
  /**
   * Sends a register event to Ubidex tracking when a user registers.
   * Gets the user's IP, prepares the tracking data,
   * sets the event to 'regfinished', and sends the tracking pixel.
   */
  public onRegister(user) {
    this._getUserIp$().pipe(
      first(),
      switchMap((ip: string) => {
        const data = {
          ...this._prepareData(),
          cur: user?.currency,
          uid: user?.id,
          event: 'regfinished',
          auth: '4rwvby',
          ip
        };
        return this._sendPixel$(data);
      })
    ).subscribe();
  }

  /**
   * Sends a login event to Ubidex tracking when a user logs in.
   * Gets the user's IP, prepares the tracking data,
   * sets the event to 'login', and sends the tracking pixel.
   */
  public onLogin(user) {
    this._getUserIp$().pipe(
      first(),
      switchMap((ip: string) => {
        const data = {
          ...this._prepareData(),
          cur: user?.currency,
          uid: user?.id,
          event: 'login',
          auth: 'dn27hy',
          ip
        };
        return this._sendPixel$(data);
      })
    ).subscribe();
  }

  /**
   * Sends a visit event to Ubidex tracking when a user visits.
   * Gets the user's IP, prepares the tracking data,
   * sets the event to 'visit', and sends the tracking pixel.
   */
  public onVisit(user = null) {
    if (this._platform.isBrowser) {
      if (user?.id) {
        if (this._window.sessionStorage.getItem('visited')) {
          return;
        }
        this._getUserIp$().pipe(
          first(),
          switchMap((ip: string) => {
            const data = {
              ...this._prepareData(),
              cur: user?.currency,
              uid: user?.id,
              event: 'visit',
              auth: 'dzy5b9',
              ip
            };
            return this._sendPixel$(data);
          })
        ).subscribe(() => {
          this._window.sessionStorage.setItem('visited', '1');
        });
      } else {
        if (this._window.sessionStorage.getItem('visited-non-auth')) {
          return;
        }
        const data = {
          ...this._prepareData(),
          cur: 'none',
          uid: 'none',
          event: 'visit',
          auth: 'dzy5b9',
          ip: '0.0.0.0'
        };

        this._sendPixel$(data).pipe(
          first()
        ).subscribe(() => {
          this._window.sessionStorage.setItem('visited-non-auth', '1');
        });
      }
    }
  }

  /**
   * Sends a deposit event to Ubidex tracking when a user makes a deposit.
   * Gets the user's IP, prepares the tracking data,
   * sets the event to 'deposit', and sends the tracking pixel.
   */
  public onDeposit(user, depData: any) {
    if (!depData.amount || !depData.currency) {
      return;
    }
    this._getUserIp$().pipe(
      first(),
      switchMap((ip: string) => {
        const data = {
          ...this._prepareData(),
          cur: depData?.currency,
          uid: user?.id,
          event: 'deposit',
          auth: 'dg19aj',
          amount: String((Number(depData?.amount) ?? 0)),
          ip
        };
        return this._sendPixel$(data).pipe(
          catchError((error) => {
            console.log(error);
            this._sendLog({...data, error: new Error(error).message});
            this.requestInProgress = false;
            return of(error);
          }),
          tap(e => {
            if (e?.status) {
              this._sendLog(data);
            }
          })
        );
      }),
      tap(() => {
        this._storage.clearItem(LOCAL_STORAGE_UBIDEX_KEY_DEPOSIT)
        this.requestInProgress = false;
      })
    ).subscribe();
  }

  /**
   * Sends a first-time deposit (FTD) event to Ubidex tracking when a user makes their first deposit.
   * Gets the user's IP, prepares the tracking data, sets the event to 'ftd', and sends the tracking pixel.
   *
   * @param user The user object.
   * @param depData An object containing the deposit details, including the amount, currency, and a flag indicating if it's the user's first deposit.
   */
  public onFTD(user, depData: any) {
    if (!depData.amount || !depData.currency || !depData.isFirst) {
      return;
    }
    this._getUserIp$().pipe(
      first(),
      switchMap((ip: string) => {
        const data = {
          ...this._prepareData(),
          cur: depData?.currency,
          uid: user?.id,
          event: 'ftd',
          auth: '9y3bmn',
          amount: String((Number(depData?.amount) ?? 0)),
          tid: null,
          prod: null,
          cat: null,
          ip
        };
        return this._sendPixel$(data).pipe(
          catchError((error) => {
            console.log(error);
            this._sendLog({...data, error: new Error(error).message});
            this.requestInProgress = false;
            return of(error);
          }),
          tap(e => {
            if (e?.status) {
              this._sendLog(data);
            }
          })
        );
      }),
      tap(() => {
        this._storage.clearItem(LOCAL_STORAGE_UBIDEX_KEY_DEPOSIT)
        this.requestInProgress = false;
      })
    ).subscribe();
  }

  private _prepareData() {
    return {
      ua: this._window.navigator.userAgent,
      site: this._window?.location?.origin?.includes('localhost') ? environment.app_host : this._window?.location?.origin,
      ts: new Date().getTime(),
      affid: this._storage?.get('id_id') || '',
      ln: this._lang.current,
      s2s: 1,
      clickid: null,
    };
  }

  private _getUserIp$() {
    return this._apiSs.userSessionData().pipe(
      map((e: any[]) => (e[0] || {})?.ip)
    );
  }

  private _sendPixel$(params: UbidexRequest) {
    const urlParams = new URLSearchParams(params as any);
    const url = `${this._trackingDomain}/pixel?${urlParams?.toString()}`;
    return from(fetch(url, {mode: 'no-cors'}));
  }

  /**
   * Sends an error log to the Ubidex service.
   * @param data The data to be logged.
   */
  private _sendLog(data) {
    this._api.postUbidexLog(data).pipe(
      first()
    ).subscribe();
  }

  public isStorageLength(dataType: string): boolean {
    return Boolean(this._storage.get(dataType) && this._storage.get(dataType).length);
  }

}
