import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { WindowService } from '../../../services/window.service';


@Component({
    selector: '.btn',
    templateUrl: './btn.component.html',
    styleUrls: ['./btn.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.loading]': `loading()`,
        '[class.apple]': `appleDevices`,
        '[class.disabled]': `disabled()`,
        '[class.gradient]': `gradient()`,
        '[attr.disabled]': `disabled() || loading() || null`,
    },
    imports: []
})
export class BtnComponent {
  private _window = inject(WindowService);


  readonly loading = input(false);

  readonly disabled = input(false);

  readonly gradient = input(false);

  get appleDevices() {
    return this._window.isSafari || this._window.isiOSDevices;
  }

}
