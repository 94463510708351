import { Injectable, inject } from '@angular/core';
import {GlobalEventsService} from '../global-events.service';
import {filter, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {PlatformService} from '../platform.service';
import {merge, Subject} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class GameIframeService {
  private _events = inject(GlobalEventsService);
  private _router = inject(Router);
  private _platform = inject(PlatformService);


  /**
   * Game source
   */
  public source: string | any;

  /**
   * Toggle for pip game (toggle class for change styles)
   */
  public isPip = false;

  /**
   * Height for game iframe
   */
  private _height: number;

  /**
   * Game data
   */
  public game: any;

  /**
   * Emit if close game iframe
   */
  public expandGame$: Subject<boolean> = new Subject();

  /**
   * If modal opened, not send request and don`t open modal again
   */
  public isDepositModalOpen: boolean;

  public isShowFrame: boolean;

  constructor() {
    merge(
      this._events.resize$,
      this._events.fullscreenChangeEvent$
    ).pipe(
      filter(() => Boolean(this.source)),
      tap(() => this.setIframeHeight())
    ).subscribe();
  }

  /**
   * Access to _height from outside
   */
  get iframeHeight() {
    return this._height;
  }

  /**
   * Toggle pip game (toggle class for styles)
   * @param isPip
   */
  public togglePipGame(isPip: boolean) {
    this.isPip = isPip;
    this._router.navigateByUrl(isPip ? '/' : `/play/${this.game.slug}`).then();
    if (!isPip) {
      this.expandGame$.next(true);
    }
  }

  /**
   * Set height for iframe height (init and resize)
   */
  public setIframeHeight() {
    if (this._platform.isBrowser && this.source) {
      const container = document.querySelector('.game-container > .container .game-screen .game');
      this._height = container && container.getBoundingClientRect().height;
    }
  }

  /**
   * Set data for working pip
   * @param game
   * @param source
   */
  public resolveGame(game: any, source: any) {
    this.closeGame();
    this.source = source;
    this.game = game;
    this.setIframeHeight();
  }

  /**
   * Close game and remove data
   */
  public closeGame() {
    this.source = null;
    this.game = null;
    this.isPip = false;
    this.expandGame$.next(true);
  }
}
