import { inject, Injectable } from '@angular/core';
import { EMPTY, Observable, of, ReplaySubject } from 'rxjs';
import { delay, map, share, shareReplay, switchMap, tap } from 'rxjs/operators';
import { SsApiService } from './api/ss-api.service';
import { UserService } from './user/user.service';
import { CommonDataService } from './common-data.service';
import { TimeService } from './time.service';
import { StaticContentService } from './static-content.service';
import { UserPaymentSsV2Service } from './user/user-payment-ss-v2.service';

interface TicketPrice {
  price: number;
  ticket: string;
  currencySymbol?: string;
}

export const DEP_AMOUNT_STR = 'depAmount';

@Injectable({
  providedIn: 'root',
})
export class LotteryService {
  private _api = inject(SsApiService);
  private _user = inject(UserService);
  private _commonData = inject(CommonDataService);
  private _userPayment = inject(UserPaymentSsV2Service);
  private _time = inject(TimeService);
  private _data = inject(CommonDataService);
  private _static = inject(StaticContentService);


  /**
   * Today current time
   * @private
   */
  private _todayTime = this._time.toLocalDate().setHours(0, 0, 0, 0);

  /**
   * Current lottery data
   * @private
   */
  private _currentLottery: any = null;

  /**
   * Current lottery data
   * @private
   */
  private _currentLottery$: ReplaySubject<any> = new ReplaySubject<any>();

  /**
   * Current lottery data by player
   * @private
   */
  private _playerLottery: any = null;

  /**
   * User player lottery tickets
   * @private
   */
  private _usedTickets: number = 0;

  /**
   * Player tickets amount
   * @private
   */
  private _playerTicketsAmount: number = 0;

  /**
   * Plaer ticket status
   */
  public updateLotteryStatus$: ReplaySubject<any> = new ReplaySubject(1);

  public countUserTickets$: Observable<number> = this.checkLotteryCountTickets$();

  private _ticketRates: TicketPrice[] = [];

  /**
   * Show lottery link
   */
  public showLotteryLink = false;

  public get ticketRates(): any[] {
    return this._ticketRates;
  }

  public get currentLottery() {
    return this._currentLottery;
  }

  public get currentLottery$() {
    return this._currentLottery$;
  }


  /**
   * Stags item lottery
   * @private
   */
  private _isLotteryAvailableForStag() {
    return this._static.item({ slug: 'lottery-stag' });
  }

  /**
   * Check current lottery by today time
   * @param lotteryList
   * @private
   */
  private _isCheckCurrentLottery$(lotteryList: any) {
    const lottery = lotteryList.find(lottery =>
      this._time.toLocalDate(lottery?.start_at).setHours(0, 0, 0, 0) === this._todayTime && Object.keys(lottery?.deposit_scale_rules).includes(this._user.info.currency)
    );
    this._generateTicketRates(this._getTicketPrice(lottery), lottery?.max_tickets_count_to_player, lottery);
    return of(lottery);
  }

  /**
   * Get current lottery by today date
   */
  public getCurrentLottery$(): Observable<any> {
    return this._api.getLotteries().pipe(
      delay(100),
      switchMap((lotteryList: any) => this._isCheckCurrentLottery$(lotteryList)),
      map(lottery => {
        if (lottery) {
          this._currentLottery = {
            ...lottery,
            end_at: this._time.toLocalDate(lottery?.end_at),
          };
          this._generateTicketRates(this._getTicketPrice(this._currentLottery), lottery?.max_tickets_count_to_player)
        }

        return this._currentLottery;
      }),
      shareReplay(1),
    );
  }

  /**
   * Set amount tickets by deposit amount value
   * @param amount
   * @param lottery
   */
  public setDepositAmount(amount: number, lottery?: any) {
    this.checkLotteryCountTickets$().pipe(
      tap(() => {
        if (!this._currentLottery && !lottery) return;
        const currencyName = this._user.info.currency;
        const lotteryCurrencyInfo = this.getLotteryCurrencyInfo(currencyName, lottery);
        const step = this._data.subunitsToUnits(lotteryCurrencyInfo[0].price_cents, currencyName);
        const sumTicket = this.usedTickets + Math.floor(amount / step);
        this._playerTicketsAmount = sumTicket > (lottery || this._currentLottery).max_tickets_count_to_player ? (lottery || this._currentLottery).max_tickets_count_to_player : sumTicket;
      })
    ).subscribe();
  }

  public getLotteryCurrencyInfo(currency, lottery?) {
    return (lottery || this._currentLottery)?.deposit_scale_rules?.[currency];
  }

  /**
   * Access to used tickets
   */
  public get usedTickets() {
    return this._usedTickets;
  }

  /**
   * Access to player tickets
   */
  public get playerTickets() {
    return this._playerTicketsAmount;
  }


  public get lotteryAvailableTickets() {
    return this._playerLottery?.max_tickets_count_to_player;
  }

  /**
   * Set used tickets
   * @param value
   */
  public set usedTickets(value: number) {
    this._usedTickets = value;
  }

  /**
   * Activate lottery
   */
  public selectLottery() {
    this._api.toggleSelectLottery(this._user.info.currency, { selected_lottery: this._currentLottery['id'], currency: this._user.info.currency }).subscribe(() => {
      this._user.info.lottery = this._currentLottery['id'];
      this.updateLotteryStatus$.next(this._currentLottery['id']);
    });
  }

  /**
   * Deactivate lottery
   */
  public unSelectLottery() {
    this._api.toggleSelectLottery(this._user.info.currency, { selected_lottery: null, currency: this._user.info.currency }).subscribe(() => {
      this._user.info.lottery = null;
      this.updateLotteryStatus$.next(null);
    });
  }

  /**
   * Check lottery count tickets
   */
  public checkLotteryCountTickets$(): Observable<any> {
    return this._isLotteryAvailableForStag().pipe(
      switchMap(response => response?.length ? this.getCurrentLottery$() : EMPTY),
      switchMap((currentLottery: any) => !!currentLottery ? this._api.getLotteriesIdStatus(currentLottery?.id) : EMPTY),
      map(lottery => {
        this.usedTickets = lottery?.tickets?.length;
        this.selectLottery();
        return this.usedTickets;
      }),
      map((usedTickets: number) => {
        const arr = [];
        for (let i = 0; i < usedTickets; i++) {
          arr.push(i);
        }
        return arr;
      }),
      share()
    );
  }

  /**
   * Check side menu by active lottery
   */
  public checkLotteryLinkVisibility$(): Observable<any> {
    return this._isLotteryAvailableForStag().pipe(
      switchMap(response => response?.length ? this._api.getLotteries() : EMPTY),
      switchMap((lotteryList: any) => this._isCheckCurrentLottery$(lotteryList)),
      tap(data => this._currentLottery$.next(data))
    );
  }

  public resolveShowLottery() {
    this.checkLotteryLinkVisibility$().pipe(
      tap(data => this.showLotteryLink = !!data),
    ).subscribe();
  }

  private _generateTicketRates(priceForOneTicket: number, countTickets: number, lottery?) {
    if (priceForOneTicket) {
      this._ticketRates = [];

      for (let i = 1; i <= countTickets; i++) {
        this._ticketRates.push({
          price: priceForOneTicket * i,
          ticket: i === 1 ? 't.ticket' : 't.tickets',
          currencySymbol: this._data.currencySymbol((lottery || this._currentLottery)?.currency)
        });
      }
    } else {
      this._ticketRates = [];
    }
  }

  private _getTicketPrice(lottery): number {
    const currencyName = this._user.info.currency;
    const lotteryCurrencyInfo = this.getLotteryCurrencyInfo(currencyName, lottery);
    return lotteryCurrencyInfo ? this._commonData.subunitsToUnits(lotteryCurrencyInfo[0].price_cents, currencyName) : null;
  }
}
