import { inject, Injectable } from '@angular/core';
import { WebSocketService } from '../../services/web-socket.service';
import { BehaviorSubject, from, merge, Observable, of, Subject } from 'rxjs';
import { delay, distinctUntilChanged, filter, first, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { TranslationService } from '../../shared/translation/translation.service';
import { CommonDataService } from '../../services/common-data.service';
import { SsWebSocketsPrivateChanel } from '../../vendor/ss-web-sockets/ss-web-sockets.types';
import { GamesService } from '../../services/games/games.service';
import {
  IGameLimitWS,
  IPaymentsWS,
  ISockets,
  ITournamentsStartWS,
  ITournamentsStatusItemWS,
  IUserGroupItemWS,
} from './notification-center.interface';
import { UserBonusesService } from '../../services/user/user-bonuses.service';
import { TournamentsService } from '../../../page/tournaments/tournaments.service';
import { GroupsService } from '../../services/groups.service';
import { LanguageService } from '../../services/language/language.service';
import additionalSocketsFields, {
  additionalLootboxFields,
  CustomNotificationChanel,
} from './additional-socket-fields';
import { LootboxService } from '../../services/lootbox/lootbox.service';
import { getRandomNumber } from '../../helpers/utils';
import { InstallAppService } from '../../services/install-app.service';
import { CookieService } from 'ngx-unificator/services';

export enum GameLimitsReason {
  FORBIDDEN_WITH_BONUS = 'game_forbidden_with_bonus',
  BONUS_BET_LIMIT_EXCEEDED = 'bonus_bet_limit_exceeded',
  BET_LIMIT_EXCEEDED = 'bet_limit_exceeded',
  SESSION_LIMIT_EXCEEDED = 'session_limit_exceeded',
}

@Injectable({
  providedIn: 'root'
})
export class NotificationCenterService {
  private _ws = inject(WebSocketService);
  private _translate = inject(TranslationService);
  private _commonData = inject(CommonDataService);
  private _games = inject(GamesService);
  private _bonuses = inject(UserBonusesService);
  private _data = inject(CommonDataService);
  private _tournaments = inject(TournamentsService);
  private _groups = inject(GroupsService);
  private _lang = inject(LanguageService);
  private _lootbox = inject(LootboxService);
  private _installApp = inject(InstallAppService);
  private _cookie = inject(CookieService);


  /**
   * User for hide messages with same description
   * @private
   */
  private _previousDescriptionMessage = new Set<string>();

  /**
   * If true animate bell
   * @private
   */
  private _animateBell: boolean;

  /**
   * Is dropdown menu opened
   */
  public dropdownOpen: boolean;

  /**
   * Dropdown html element
   */
  public dropdownElement;

  /**
   * Use for install app custom chanel
   * @private
   */
  private _installAppChannel$: Subject<any> = new Subject<any>();

  private _mascotChannel$: Subject<any> = new Subject<any>();

  private _vipChannel$: Subject<any> = new Subject<any>();

  /**
   * Tournaments statuses from WS
   * @private
   */
  private _tournaments$: Observable<ITournamentsStatusItemWS> = this._ws.tournaments$;

  private _tournamentsStarted$: Observable<ITournamentsStartWS> = this._ws.tournamentsStarted$;

  /**
   * Groups statuses from WS
   * @private
   */

  private _groups$: Observable<IUserGroupItemWS> = this._ws.groups$.pipe(
    filter(group => group && group.status && !this._groups.isExistGroup(group.id))
  );

  /**
   * Game limits info from WS
   * @private
   */
  private _gameLimits$: Observable<IGameLimitWS> = this._ws.limits$;

  /**
   * Payments info from Ws
   * @private
   */
  private _payments$: Observable<IPaymentsWS> = this._ws.payments$;

  /**
   * All bonueses abservable
   * @private
   */
  private _allBonuses$: Observable<ISockets> = merge(
    this._ws.bonuses$,
    this._ws.lootboxes$,
    this._ws.freespins$
  ).pipe(
    filter(this._filterBonusesNotification)
  );

  /**
   * Resolve fields for install app message
   * @private
   */
  private _resolvedInstallAppChannel$: Observable<any> = this._installAppChannel$.pipe(
    distinctUntilChanged(),
    map(() => ({
      notify_channel: CustomNotificationChanel.INSTALL_APP,
      uid: getRandomNumber(111, 111111111111111)
    }))
  );

  /**
   * Resolve fields for install app message
   * @private
   */
  private _resolvedMascothannel$: Observable<any> = this._mascotChannel$.pipe(
    distinctUntilChanged(),
    map(() => ({
      notify_channel: CustomNotificationChanel.MASCOT_NOTIFY,
      uid: getRandomNumber(111, 111111111111111)
    }))
  );

  private _resolvedVipChannel$: Observable<any> = this._vipChannel$.pipe(
    map(() => ({
      notify_channel: CustomNotificationChanel.VIP,
      uid: getRandomNumber(111, 111111111111111),
    })),
  );


  /**
   * Uses for display notification messages
   */
  public notifications$: BehaviorSubject<ISockets[]> = new BehaviorSubject<any>([]);

  /**
   * True if notification sidebar opened
   * @private
   */
  private  _isOpened: boolean = false;

  private _isNotUnique: boolean = false;

  constructor() {
    this.initNotify();
  }

  get animateBell() { return this._animateBell; }
  get installAppChannel$() {return this._installAppChannel$; }
  get mascotChannel$() {return this._mascotChannel$; }

  get vipChannel$() {
    return this._vipChannel$;
  }

  get isOpened() { return this._isOpened; }
  get isNotUnique() { return this._isNotUnique; }

  /**
   * Init notify
   */
  initNotify() {
    merge(
      this._allBonuses$, this._tournaments$, this._tournamentsStarted$,
      this._groups$, this._gameLimits$, this._payments$,
      this._resolvedInstallAppChannel$, this._resolvedMascothannel$, this._resolvedVipChannel$
    ).pipe(
      filter(notification => !!notification),
      map(notification => this._mapWSNotify(notification)),
      mergeMap(notification => notification instanceof Observable ? notification : of(notification)),
      filter(notification => !!notification),
      filter(notification => !this._previousDescriptionMessage.has(notification.description)),
      tap(notification => {
        this._animateBell = true;
        this._isNotUnique = false;
        this._previousDescriptionMessage.add(notification.description);
        this.notifications$.next([notification, ...this.notifications$.getValue()]);
      }),
      delay(500),
      tap(() => this._animateBell = false)
    ).subscribe();

    this._listenLangChangeAndRemappNotify();
  }

  /**
   * Resolve notify by channel
   * @param notification
   * @private
   */
  private _mapWSNotify(notification) {
    switch (notification.notify_channel) {
      case CustomNotificationChanel.INSTALL_APP:
        return this._resolveInstallAppChannel(notification);
      case CustomNotificationChanel.MASCOT_NOTIFY:
        return this._resolveMascotChannel(notification);
      case CustomNotificationChanel.VIP:
        return this._resolveVipChannel(notification);
      case CustomNotificationChanel.PROMO_CHANNEL:
        return this._resolvePromoChannel(notification);
      case SsWebSocketsPrivateChanel.FREESPINS_CHANGED:
        return this._resolveNotifyFreeSpins(notification);
      case SsWebSocketsPrivateChanel.GAME_LIMITS:
        return this._resolveNotifyGameLimits(notification);
      case SsWebSocketsPrivateChanel.BONUSES_CHANGED:
        return this._resolveNotifyBonuses(notification);
      case SsWebSocketsPrivateChanel.PAYMENTS_CHANGED:
        return this._resolveNotifyPayments(notification);
      case SsWebSocketsPrivateChanel.LOOTBOXES_CHANGED:
        return this._resolveNotifyLootboxes(notification);
      case SsWebSocketsPrivateChanel.TOURNAMENTS_STATUSES:
        return this._resolveNotifyTournaments(notification);
      case SsWebSocketsPrivateChanel.TOURNAMENTS_STARTED:
        return this._resolveNotifyTournamentsStarted(notification);
      case SsWebSocketsPrivateChanel.GROUPS_UPDATES:
        return this._resolveNotifyGroups(notification);
    }
  }

  /**
   * Open dropdown menu
   */
  open() {
    if (!this.dropdownOpen) {
      this.dropdownOpen = true;
      this._isOpened = true;
      this._isNotUnique = true;
      this.dropdownElement.classList.add('open');
    }
  }

  /**
   * Close dropdown menu
   */
  close() {
    if (this.dropdownOpen) {
      this.dropdownOpen = false;
      this.dropdownElement.classList.remove('open');
    }
  }

  /**
   * Toggle dropdown menu
   */
  toggle() {
    this.dropdownOpen ? this.close() : this.open();
  }

  /**
   * Remove notify
   * @param notify
   */
  public removeNotify(notify) {
    if (notify.notify_channel === CustomNotificationChanel.INSTALL_APP) {
      this._installApp.closeNotificationBlock();
    }
    if (notify.notify_channel === CustomNotificationChanel.PROMO_CHANNEL) {
      this._cookie.set('promo-dynamic', 'true', 1, '/');
    }
    this._previousDescriptionMessage.delete(notify.description);
    this.notifications$.next(this.notifications$.getValue().filter(oldNotify => notify.uid !== oldNotify.uid));
  }

  /**
   * Resolve notify install app custom channel
   * @param notify
   * @private
   */
  private _resolveInstallAppChannel(notify: any) {
    return {
      ...notify,
      link: '/mobile-app',
      description: this._translate.translate('t.all-games-bonuses-app'),
      ...additionalSocketsFields[notify.notify_channel]
    };
  }

  private _resolveMascotChannel(notify: any) {
    return {
      ...notify,
      link: '/profile/account',
      description: this._translate.translate('t.mascot-notify-desc'),
      title: 't.mascot-notify-title',
      icon: '/assets/img/notification/mascot.png',
      templateTitle: 't.mascot-notify-btn',
      ...additionalSocketsFields[notify.notify_channel]
    };
  }

  private _resolveVipChannel(notification: any) {
    this.notifications$.next([
      ...this.notifications$.getValue(),
      {
        ...notification,
        title: this._translate.translate('t.vip-notification-title'),
        link: '/vip-program',
      },
    ]);
  }


  /**
   * Resolve notify promo custom channel
   * @param notify
   * @private
   */
  private _resolvePromoChannel(notify: any) {
    return {
      ...notify,
      description: this._translate.translate('t.notify-promo-description'),
      ...additionalSocketsFields[notify.notify_channel]
    };
  }

  /**
   * Resolve notify FreeSpins
   * @param notify
   * @private
   */
  private _resolveNotifyFreeSpins(notify: any) {
    return {
      ...notify,
      description: this._translate.translate('t.notify-freespins-desc', {
        count: notify.freespins_total
      }),
      title: this._bonuses.mapBonusTitle(notify?.title),
      ...additionalSocketsFields[notify.notify_channel]
    };
  }

  /**
   * Resolve notify Bonuses
   * @param notify
   * @private
   */
  private _resolveNotifyBonuses(notify: any) {
    return {
      ...notify,
      description: this._translate.translate('t.notify-bonuses-desc', {
        bonus: this._bonuses.mapBonusTitle(notify.title)
      }),
      ...additionalSocketsFields[notify.notify_channel]
    };
  }

  /**
   * Resolve notify Lootboxes
   * @param notify
   * @private
   */
  private _resolveNotifyLootboxes(notify: any) {
    this._lootbox.lootboxesTranslates$.pipe(
      first(),
      map(() => {
        const translate = this._lootbox.resolveLootboxTranslates(notify);
        this.notifications$.next([
          ...this.notifications$.getValue(),
          {
            ...notify,
            description: this._translate.translate(
              (additionalLootboxFields[notify.title] && additionalLootboxFields[notify.title].description) || 't.notify-lootbox-desc',
              (additionalLootboxFields[notify.title] && additionalLootboxFields[notify.title].description) ? null : {bonus: this._translate.translate(translate.title)}
            ),
            title: translate?.title || notify?.title,
            link: (additionalLootboxFields[notify.title] && additionalLootboxFields[notify.title].link) || additionalSocketsFields[notify.notify_channel].link,
            ...additionalSocketsFields[notify.notify_channel],
          },
        ]);
      })
    ).subscribe();
  }

  /**
   * Resolve notify Payments
   * @param notify
   * @private
   */
  private _resolveNotifyPayments(notify: any) {
    return {
      ...notify,
      description: this._translate.translate(notify.success ? 't.notify-payments-desc-accepted' : 't.notify-payments-desc-discarded' , {
        currency: notify.currency,
        payment_system: notify.payment_system,
        action: notify.action,
        amount_cents: this._data.subunitsToUnits(notify.amount_cents, notify.currency)
      }),
      ...additionalSocketsFields[notify.notify_channel]
    };
  }

  /**
   * Resolve notify Groups
   * @param notify
   * @private
   */
  private _resolveNotifyGroups(notify: any) {
    const description = this._translate.translate('t.notify-groups-desc' , {
      name: this._translate.translate(notify.name)
    });
    if (!this._previousDescriptionMessage.has(description)) {
      this._previousDescriptionMessage.add(description);
      return {...notify, description, ...additionalSocketsFields[notify.notify_channel]};
    }

    return null;
  }

  private _resolveNotifyTournamentsStarted(notify: any) {
    return this._tournaments.list().pipe(
      filter(list => !!list),
      map(list => {
        const currentTournament = list.find(item => Number(item.identifier) === Number(notify.id));
        return currentTournament ?
          {
            ...notify,
            description: this._translate.translate('t.tournament-started', {
              title: currentTournament.Title
            }),
            link: `/${ this._lang.current }/tournaments/${ currentTournament.slug }`,
            ...additionalSocketsFields[notify.notify_channel]
          }
          : null;
      })
    );
  }

  /**
   * Resolve notify Tournaments
   * @param notify
   * @private
   */
  private _resolveNotifyTournaments(notify: any) {
    return this._tournaments.list().pipe(
      filter(list => !!list && list?.length),
      map(list => {
        const currentTournament = list.find(item => Number(item.identifier) === Number(notify.tournament_id));
        return currentTournament && Boolean(notify.award_place) ?
          {
            ...notify,
            description: this._translate.translate('t.notify-tournaments-desc' , {
              place: notify.award_place,
              points: notify.points,
              title: currentTournament.Title
            }),
            link: `/${this._lang.current}/tournaments/${currentTournament.slug}`,
            ...additionalSocketsFields[notify.notify_channel]
          }
          : null;
      })
    );
  }

  /**
   * Resolve notify GameLimits
   * @param notify
   * @private
   */
  private _resolveNotifyGameLimits(notify: any) {
    return this._games.getGameByExternalId(notify.game).pipe(
      first(),
      map(game => {
        return game ? {
          ...notify,
          description: this._translate.translate(this._getTranslateForGameReason(notify.reason), {
            game: game.name,
            limit: notify.limit_cents ? this._commonData.subunitsToUnits(notify.limit_cents, notify.currency) : '',
          }),
          ...additionalSocketsFields[notify.notify_channel]
        } : null;
      })
    );
  }

  /**
   * Get translate key for game reason
   * @param reason
   * @private
   */
  private _getTranslateForGameReason(reason: GameLimitsReason) {
    return reason === GameLimitsReason.FORBIDDEN_WITH_BONUS ? 't.game-forbidden-with-bonus' :
      reason === GameLimitsReason.BONUS_BET_LIMIT_EXCEEDED ? 't.bonus-bet-limit' :
      reason === GameLimitsReason.BET_LIMIT_EXCEEDED ? 't.blocked-by-bonus' :
      reason === GameLimitsReason.SESSION_LIMIT_EXCEEDED ? 't.session-limit' : 't.game-reason-limit';
  }

  /**
   * Filter bonuses notifications
   * @param notify
   * @private
   */
  private _filterBonusesNotification(notify) {
    return notify.stage && notify.stage === 'issued' && (!notify.strategy || notify.strategy !== 'freespins_result');
  }
  /**
   * Listen lang change and remapp nitifications
   * @private
   */
  private _listenLangChangeAndRemappNotify() {
    let mappedNotifications = [];
    this._translate.translationLoading$.pipe(
      filter(loading => !loading),
      tap(() => {
        mappedNotifications = [];
        const notifies = this.notifications$.getValue();
        of(notifies).pipe(
          map(notifications => {
            return notifications.map(n => {
              this._previousDescriptionMessage.delete(n.description);
              return this._mapWSNotify(n);
            });
          }),
          switchMap(notifications => from(notifications)),
          mergeMap(notification => notification instanceof Observable ? notification : of(notification)),
          filter(notifications => !!notifications),
          tap(notification => mappedNotifications.push(notification))
        ).subscribe();
      }),
      delay(100),
      tap(() => {
        this.notifications$.next([]);
        this.notifications$.next([...mappedNotifications]);
      })
    ).subscribe();
  }
}
