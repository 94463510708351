import { Component, OnChanges, OnInit, SimpleChanges, input } from '@angular/core';
import { BaseTimerComponent } from '../../../helpers/base-timer.component';


import { TranslatePipe } from '../../translation/translate.pipe';

@Component({
    selector: 'app-timer',
    templateUrl: './timer.component.html',
    styleUrls: ['./timer.component.scss'],
    imports: [
        TranslatePipe
    ]
})
export class TimerComponent extends BaseTimerComponent implements OnInit, OnChanges {

  readonly tournament = input<any>(undefined);

  public progress: string = '';

  constructor(
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.tournament?.firstChange) {
      this._resolveProgress(changes?.tournament?.currentValue);
    }
  }

  ngOnInit(): void {
    this.resolveTimer();
  }

  private _resolveProgress(tournament: any) {
    const startAt = this._getStartAt(tournament);
    const endAt = this._getEndAt(tournament);
    const now = new Date().getTime();
    const percentage = (now - startAt) / (endAt - startAt) * 100;
    this.progress = Math.round(percentage) + '%';
  }

  private _getStartAt(item: any) {
    if (item?.activeMultiTournament?.start_at) { return new Date(item?.activeMultiTournament?.start_at).getTime(); }
    else if (item?.publishAt?.date) { return new Date(item?.publishAt?.date).getTime(); }
    return new Date(item?.publishAt).getTime();
  }

  private _getEndAt(item: any) {
    if (item?.activeMultiTournament?.end_at) { return new Date(item?.activeMultiTournament?.end_at).getTime(); }
    else if (item?.unpublishAt?.date) { return new Date(item?.unpublishAt?.date).getTime(); }
    return new Date(item?.unpublishAt).getTime();
  }
}
