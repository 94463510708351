import { Injectable } from '@angular/core';
import { PlatformService } from 'ngx-unificator/services';
import { catchError, first, fromEvent, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckWebpService {

  public isWebpSupported;

  constructor(
    private _platform: PlatformService
  ) { }

  public checkIfWebpSupported() {
    if (this._platform.isBrowser) {
      const image = new Image();
      image.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';

      fromEvent(image, 'load').pipe(
        first(),
        catchError(error => {
          return of(error)
        }),
        tap(() => {
          this.isWebpSupported = true;
        })
      ).subscribe();
    }
  }

}
