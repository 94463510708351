/**
 * Bonus stage list
 */
export enum BonusStage {
  HANDLE_BETS = 'handle_bets',
  LOST = 'lost',
  CANCELED = 'canceled',
  PLAYED = 'played',
  ISSUED = 'issued',
  EXPIRED = 'expired',
  WAGER_DONE = 'wager_done',
  ACTIVATED = 'activated',
  FINISHED = 'finished',
  VIP = 'vip',
  VALENTINE_FS = 'valentine_fs',
}

export const BonusStageLabelKeys = {
  [BonusStage.HANDLE_BETS]: 'limit.act',
  [BonusStage.CANCELED]: 'labl.cancel',
  [BonusStage.ISSUED]: 'labl.available',
  [BonusStage.EXPIRED]: 'labl.expired',
  [BonusStage.FINISHED]: 'labl.finish',
  [BonusStage.ACTIVATED]: 'limit.act',
  AVAILABLE: 'labl.available',
  ACTIVE: 'limit.act'
}

export enum BonusType {
  MONEY = 'money',
  LOOTBOX = 'lootbox',
  DEPOSIT = 'deposit_bonus',
  EXTRA_SPINS = 'extra_bonus',
  FREE_SPINS = 'freespins',
  DAILY = 'daily'
}

export enum BonusCodeStatus {
  FAILED_TO_ACTIVATE = 'failed_to_activate',
  ALREADY_ACTIVATED = 'already_activated',
  SUCCESSFULLY_ACTIVATED = 'successfully_activated'
}

/**
 * Text labels for bonus stages
 */
export const BonusStageLabel = {
  [BonusStage.HANDLE_BETS]: 'Handle bets',
  [BonusStage.LOST]: 'Lost',
  [BonusStage.CANCELED]: 'Canceled',
  [BonusStage.PLAYED]: 'Played',
  [BonusStage.ISSUED]: 'Issued',
  [BonusStage.EXPIRED]: 'Expired',
  [BonusStage.WAGER_DONE]: 'Wager done',
  [BonusStage.ACTIVATED]: 'Activated',
  [BonusStage.FINISHED]: 'Finished',
};
