import { Component, inject, input } from '@angular/core';
import { EasterService } from '../easter.service';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { SafePipe } from 'ngx-unificator/pipes';
import { TranslatePipe } from '../../../../core/shared/translation/translate.pipe';
import { SliderComponent } from '../../../../core/modules/slider/slider.component';

@Component({
  selector: 'app-easter-bonuses',
  imports: [
    NgClass,
    SafePipe,
    TranslatePipe,
    SliderComponent,
    NgTemplateOutlet
  ],
  templateUrl: './easter-bonuses.component.html',
  styleUrl: './easter-bonuses.component.scss',
})
export class EasterBonusesComponent {

  public easter: EasterService = inject(EasterService);

  place = input<'deposit' | 'promo'>('promo');

  sliderConfig = {
    loop: true,
    breakpoints: {
      '(max-width: 720px)': {
        slides: {
          perView: 1,
          spacing: 12,
          origin: 'center',
        },
      },
      '(min-width: 721px)': {
        slides: {
          perView: 1,
          spacing: 20,
          origin: 'center',
        },
      },
    }
  };
}
