import { Injectable, inject } from '@angular/core';
import { CmsApiService } from './api/cms-api.service';
import { CmsContentMapperService } from './cms-content-mapper.service';
import { ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { UpdateOnLangChange } from '../shared/decorators/update-on-lang-change';

@Injectable({
  providedIn: 'root'
})
export class BannersService {
  private _api = inject(CmsApiService);
  private _mapper = inject(CmsContentMapperService);


  /**
   * Returns banner item from CMS and prepare for using in Frontend
   *
   * @param params
   */
  @UpdateOnLangChange('banner/item')
  item(params: object = {}): ReplaySubject<any> {
    return  this._api.bannerItem(params).pipe(
      map(response => this._mapper.mapCmsData(response.data))
    ) as ReplaySubject<any>;
  }

  /**
   * Returns banner list from CMS and prepare for using in Frontend
   *
   * @param params
   */
  @UpdateOnLangChange('banner/list')
  list(params: object = {}): ReplaySubject<any> {
    return this._api.bannerList(params).pipe(
      map(response => this._mapper.mapCmsData(response.data))
    ) as ReplaySubject<any>;
  }
}
